<script setup lang="ts">
import {IonButton, IonCard, IonCardContent, IonContent, IonCardTitle, IonItem, IonLabel, IonList, IonCardHeader, IonFooter } from "@ionic/vue";

import {computed, onMounted, ref} from "vue";
import {getSummary,} from "@/helper/timesheet";
import TimesheetSignatureSummaryModal from "../components/TimesheetSignatureSummaryModal.vue";
import TimesheetSignatureConfirmation from "../components/TimesheetSignatureConfirmation.vue";
import {pluralize} from "@/helper/amountFormatter";
import {useTimesheetStore} from "@/store/timesheet";
import {VueSignaturePad} from "vue-signature-pad";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import { getImageForJobsite } from '@/helper/jobsite'

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const timesheetToDosInSignatureProcess = computed(() =>
    store.timesheetToDosInSignatureProcess.map((tstd) =>
        getSummary(tstd, store.timesheetToDos),
    ),
);

const signateurName = computed(() => {
  if(store.timesheetToDosInSignatureProcess.length>0){
    const firstTstd = store.timesheetToDosInSignatureProcess[0]
    if(firstTstd.timesheet){
      return firstTstd.timesheet.signateur;
    }
  }
  return ""

})

const confirmationOpen = ref(false);
const confirmationNumerator = ref(0);
const confirmationDenominator = ref(0);

const signaturePad = ref()
const hasSignature = ref(false)
const isSummaryModelOpen = ref(false);
const signatureData = ref("");

onMounted(() => {
    // Bug workaround
    // https://github.com/neighborhood999/vue-signature-pad/issues/389
    setTimeout(() => {
        signaturePad.value.resizeCanvas()
    }, 250)
})

const onTouchStart = (e) => {
    e.stopPropagation()
    e.preventDefault()
}

const onTouchMove = (e) => {
    e.stopPropagation()
    e.preventDefault()
}

const onTouchEnd = () => {
    hasSignature.value = !signaturePad.value.isEmpty()
}

const valid = computed(() =>
    store.timesheetToDosInSignatureProcess.length >= 0 &&
    hasSignature.value === true
)

const jobsite = computed(() => {
    return store.timesheetToDosInSignatureProcess
        ? getSummary(
                store
                    .timesheetToDosInSignatureProcess[0],
                store.timesheetToDos,
            ).jobsite
        : undefined;
});

const submitTimesheets = async () => {
    // Get image from signature pad
    signatureData.value = signaturePad.value.saveSignature().data

    if (valid.value) {
        store.setSignature(signatureData.value);
        await store.finishSignatureProcess();
        props.timesheetNavigator.next()
    }
};

// const onConfirmSignatureSaveDialog = ()=> {
//   confirmationOpen.value = false;
//   props.timesheetNavigator.done()
// }

const clear = () => {
    signaturePad.value.clearSignature();
    signatureData.value = "";
    hasSignature.value = false
};
</script>

<template>
    <ion-content>
        <ion-card class="no-margin-top">
            <ion-card-header>
                <ion-card-title>Einsätze</ion-card-title>
            </ion-card-header>
            <ion-card-content
                class="no-padding-top"
                v-if="store.timesheetToDosInSignatureProcess.length > 0"
            >
                <RichResponseListItem
                    iconType="image"
                    :icon="getImageForJobsite(jobsite)"
                    :title="jobsite.house?.name?.toString()"
                >
                    {{ jobsite?.name?.toString() }}<br>
                    {{
                        pluralize(
                            store.timesheetToDosInSignatureProcess.length,
                            "Dienst",
                            "Dienste"
                        )
                    }}
                </RichResponseListItem>
                <ion-button color="light" expand="block" v-on:click="isSummaryModelOpen = true">Dienstzeiten anzeigen</ion-button>
            </ion-card-content>
        </ion-card>

        <ion-card class="signature-container">
            <ion-card-header>
                <ion-card-title>Unterschrift</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <div class="signer-name">Unterschrift von {{ signateurName }}</div>
                <VueSignaturePad
                    id="signaturePad"
                    ref="signaturePad"
                    @touchstart.native="onTouchStart"
                    @touchmove.native="onTouchMove"
                    @touchend.native="onTouchEnd"
                />
            </ion-card-content>
        </ion-card>
        <TimesheetSignatureSummaryModal
            v-model="isSummaryModelOpen"
            :timesheet-to-do-summaries="timesheetToDosInSignatureProcess"
        />
    </ion-content>
    <ion-footer>
        <ion-button
            :disabled="!valid"
            v-on:click="clear()"
            color="transparent"
            expand="block"
            >Feld leeren</ion-button
        >
        <ion-button
            :disabled="!valid"
            v-on:click="submitTimesheets()"
            expand="block"
            color="primary"
        >
            Dienst bestätigen
        </ion-button>
    </ion-footer>
</template>


<style scoped>

.signature-container ion-card-content {
  margin-top: 1%;
  position: relative;
  width: 100%;
  height: 30vh;

  > #signaturePad {
    position: relative;
    z-index: 1;
  }
}

.signer-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #cccccc;
  font-size: 25px;
  text-align: center;
}

/* canvas {
  position: relative;
  background-color: transparent;
  z-index: 2;
} */
</style>
