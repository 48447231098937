/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleHeight extends ModuleBase {
  prepareElementForEnter (el) {
    const height = el.clientHeight

    if (!height) {
      return
    }

    this.targetState.height = height
    el.style.height = 0
    el.style.overflowY = 'hidden'
  }
  
  prepareEnter (el) {
    this.prepareElementForEnter(el)
  }

  prepareLeave (el) {
    this.initialState.height = el.clientHeight
    el.style.height = el.clientHeight
    el.style.overflowY = 'hidden'
  }

  applyEnter (el, progress) {
    if (!this.targetState.height) {
      this.prepareElementForEnter(el)
    }

    el.style.height = (progress * this.targetState.height) + 'px'
  }

  applyLeave (el, progress) {
    el.style.height = ((1 - progress) * this.initialState.height) + 'px'
  }
}
