<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonFooter
} from "@ionic/vue";
import {cmpTimesheetBookingBegin, getSummary} from "@/helper/timesheet";
import {computed, ref} from "vue";
import TimesheetEditorModal from "../components/TimesheetEditorModal.vue"
import {TimesheetToDo} from "@/graphql/generated/graphql";
import {useTimesheetStore} from "@/store/timesheet";
import RichResponseHeadline from "@/views/Chat/RichResponses/components/RichResponseHeadline.vue";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import { getImageForJobsite } from '@/helper/jobsite'
import {generateCalendarDate} from '@/helper/calendar/calendarDate';
import {pluralize} from "../../../helper/amountFormatter";

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const isTimesheetEditorModalOpen = ref(false)
const editTimesheet = ref<TimesheetToDo|undefined>(undefined)


const timesheetToDosInSignatureProcess = computed(()=>
    store.timesheetToDosInSignatureProcess
        .toSorted(cmpTimesheetBookingBegin)
        .map((tstd)=>getSummary(tstd, store.timesheetToDos))
)

const jobsite = computed(() => timesheetToDosInSignatureProcess.value ? timesheetToDosInSignatureProcess.value[0].jobsite : undefined)

const headline = computed(() => {
    const dates = timesheetToDosInSignatureProcess.value?.map(tstd => tstd.kapitelDate)

    if (!dates || !dates.length) {
        return ""
    }

    const from = dates[0]
    const to = dates[dates.length - 1]
    
    const calendarDateFrom = generateCalendarDate(from)
    const calendarDateTo = generateCalendarDate(to)

    const monthDiffers = calendarDateFrom.month !== calendarDateTo.month
    const yearDiffers = calendarDateFrom.year !== calendarDateTo.year
    let yearAndMonthStr
    if (monthDiffers && yearDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year} / ${calendarDateTo.labelMonth} ${calendarDateTo.year}`
    }
    else if (monthDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} / ${calendarDateTo.labelMonth} ${calendarDateFrom.year}`
    }
    else {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year}`
    }

    const weekDiffers = calendarDateFrom.week !== calendarDateTo.week
    return weekDiffers
        ? yearAndMonthStr
        : `KW ${calendarDateFrom.week} - ${yearAndMonthStr}`
})

const onEditTimesheetTodo = (timesheetToDo: TimesheetToDo) => {
  editTimesheet.value = timesheetToDo
  isTimesheetEditorModalOpen.value = true
}

const onUpdateTimesheetToDo = (timesheetToDo: TimesheetToDo) => {
  store.updateTimesheet(timesheetToDo)
}

const onDeselectTimesheetToDo = () => {
  if(editTimesheet.value){
    store.removeTimesheetToDoFromSignatureProcess(editTimesheet.value)
  }
}


</script>

<template>
    <ion-content>
        <ion-card class="no-margin-top">
            <ion-card-header>
                <ion-card-title>
                    Einsatzort
                </ion-card-title>
            </ion-card-header>
            <ion-card-content
                class="no-padding-top no-padding-bottom"
                v-if="timesheetToDosInSignatureProcess.length>0"
            >
                <RichResponseListItem
                    iconType="image"
                    :icon="getImageForJobsite(jobsite)"
                    :title="jobsite.house?.name?.toString()"
                    :text="jobsite?.name?.toString()"
                />
            </ion-card-content>
        </ion-card>
        <ion-card>
        <ion-card-header>
            <ion-card-title>
                {{ pluralize(timesheetToDosInSignatureProcess.length, 'Dienst', 'Dienste') }}
            </ion-card-title>
        </ion-card-header>
        <ion-card-content class="no-padding-top">
            <RichResponseHeadline
                :title="headline"
            />
            <RichResponseListItem
                v-for="timesheetToDo in timesheetToDosInSignatureProcess"
                :title="`${timesheetToDo.begin} - ${timesheetToDo.until} Uhr`"
                :text="timesheetToDo.breakBegin.length === 0 ? 'Keine Pause' : 'Pause: ' + timesheetToDo.breakBegin + ' - ' + timesheetToDo.breakUntil + ' Uhr'"
                :icon="timesheetToDo.kapitelDate"
                icon-type="date"
                :button="true"
                @click="timesheetToDo.ref ? onEditTimesheetTodo(timesheetToDo.ref):()=>{}"
            />
        </ion-card-content>
        </ion-card>
        <TimesheetEditorModal                
            v-model="isTimesheetEditorModalOpen"
            :timesheet-to-do="editTimesheet"
            v-on:update:timesheet="onUpdateTimesheetToDo"
            v-on:toggle:deselect:timesheet="onDeselectTimesheetToDo"
        />
    </ion-content>
    <ion-footer>
        <ion-button
                @click="timesheetNavigator.next()"
                expand="block"
                color="primary"
                :disabled="timesheetToDosInSignatureProcess.length === 0"
            >Nächster Schritt</ion-button>
    </ion-footer>
</template>
