import type {
    Availability,
    AvailabilityShift,
    ShiftType,
    AvailabilityShiftDefault,
    AvailabilityTimeConstraint
} from '@/graphql/generated/graphql';

import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import useEmitter, {useGlobalEmitter} from "@/helper/emitter";
import datetime from "@/helper/datetime/datetime"
import { kapitelDateString, kapitelDateTimeString } from '@/graphql/kapitelTypes';
import { clientFragment, houseFragment, jobsiteFragment } from "./jobsite";

const emitter = useGlobalEmitter()

export const availabilityFragment = gql`
    ${jobsiteFragment}
    ${clientFragment}
    ${houseFragment}
    fragment AvailabilityFragment on Availability {
        id
        date
        shifts {
            begin
            until
            type
        }
        constraints {
            begin
            until
            comment
        }
        excludedJobsites {
            ...JobsiteFragment
            client {
                ...ClientFragment
            }
            house {
                ...HouseFragment
            }
        }        
    }
`

const shiftTypeComparator = (typeNeedle: any): { (shift: any): boolean } => (
    (shift: any ) => typeNeedle == shift.type
)

export const isShift = (
    shifts: Array<object>,
    typeNeedle: ShiftType
): boolean => shifts.some(shiftTypeComparator(typeNeedle))

export const getShift = (
    availability: Availability,
    typeNeedle: ShiftType
): AvailabilityShift | undefined => availability.shifts?.find(shiftTypeComparator(typeNeedle))

export function generateAvailability(dateString: kapitelDateString): Availability {
    return <Availability>{
        date: dateString,
        shifts: [],
        constraints: []
    }
}


export function generatePropertiesFromDefault(dateString: kapitelDateString, shiftDefault: AvailabilityShiftDefault): AvailabilityShift {

    const beginDateTime = datetime.convertDateAndTime2DateTime(
      dateString, shiftDefault.begin
    )

    let untilDateTime = datetime.convertDateAndTime2DateTime(
      dateString, shiftDefault.until
    )

    if(datetime.isAfter(beginDateTime, untilDateTime)){
        untilDateTime = datetime.addDays(untilDateTime, 1)
    }

    return {
        type: shiftDefault.type,
        begin: beginDateTime,
        until: untilDateTime
    }
}

export function generateAvailabilityShift(date: Date, shiftType: ShiftType, begin: kapitelDateTimeString, until: kapitelDateTimeString): AvailabilityShift {
    return <AvailabilityShift>{
        type: shiftType,
        begin: begin,
        until: until
    }
}

export function generateAvailabilityTimeConstraint(begin: kapitelDateTimeString, until: kapitelDateTimeString, comment?: string): AvailabilityTimeConstraint {
    return <AvailabilityTimeConstraint>{
        begin: begin,
        until: until,
        comment
    }
}

export async function fetchAvailabilityForDate(dateString: kapitelDateString) {
    const result = await query(
        gql`${availabilityFragment}
            query GetAvailability ($date: KapitelDateImmutable!) {
            availability(
                date: $date
            ) {
                ...AvailabilityFragment
            }
        }`,
        {
            date: dateString
        }
    )

    return result?.data?.availability;
}

export async function fetchAvailabilitiesForDateRange(beginString: kapitelDateString, untilString: kapitelDateString): Promise<any>
{
    const result = await query(
        gql`${availabilityFragment}
            query GetAvailabilities (
            $min: KapitelDateImmutable!
            $max: KapitelDateImmutable!
        ) {
            availabilities(betweenDates: {max: $max, min: $min}) {
                ...AvailabilityFragment
            }
        }`, {
            min: beginString,
            max: untilString
        }
    )

    const availabilities = result?.data?.availabilities;
    if (!availabilities) {
        throw new Error("no availabilities in availabilities response")
    }

    return availabilities
}

export async function setAvailability(availability: Availability) {
    useGlobalEmitter().emit('day:mutated', availability.date)

    const MUTATION = gql`
            mutation setAvailability(
                $dateDings: KapitelDateImmutable!,
                $shiftsDings: [AvailabilityShiftInput!]!,
                $constraintsDings: [AvailabilityTimeConstraintInput!]!
                $excludedJobsiteIdsDings: [Int!]
            ) {
                setAvailability(
                    date: $dateDings,
                    shifts: $shiftsDings,
                    constraints: $constraintsDings,
                    excludedJobsiteIds: $excludedJobsiteIdsDings
                ) {
                    date
                }
            }`

    const result = await mutation(
        MUTATION, {
            dateDings: availability.date,
            shiftsDings: availability.shifts,
            constraintsDings: availability.constraints,
            excludedJobsiteIdsDings: availability.excludedJobsites ? availability.excludedJobsites.map(j => j.id): undefined
        }
    )

    useGlobalEmitter().emit('day:mutated', availability.date)

    const success = result?.data

    if (!success) {
        throw new Error("error while storing availability")
    }

    return success
}

export function addShift(availability: Availability, shift: AvailabilityShift) {
    availability.shifts.push(shift)
}
export function removeShift(availability: Availability, shift: AvailabilityShift) {
    availability.shifts.splice(availability.shifts.indexOf(shift), 1)
}

export function addConstraint(availability: Availability, constraint: AvailabilityTimeConstraint) {
    availability.constraints.push(constraint)
}
export function removeConstraint(availability: Availability, constraint: AvailabilityTimeConstraint) {
    availability.constraints.splice(availability.constraints.indexOf(constraint), 1)
}


export function availabilityShiftsAndTimeConstraintsToIntervals(shifts: Array<AvailabilityShift>, constraints: Array<AvailabilityTimeConstraint>): Array<{begin: kapitelDateTimeString, until: kapitelDateTimeString}> {
    return datetime.mergeAndDiffIntervals(
      shifts || [],
      constraints || []
    )
}

export function getAvailabilitySummary(availabilities:Availability[]) {

    const shiftTypesList:Array<ShiftType> = []
    availabilities.forEach(availability => {
        availability.shifts.forEach(availabilityShift =>{
            if (!shiftTypesList.includes(availabilityShift.type)) {
                shiftTypesList.push(availabilityShift.type)
            }
        })
    })

    const shifts = availabilities.reduce((acc: number, availability: Availability) => { return acc += availability.shifts.length }, 0)

    return {
        count: availabilities.filter(a => a.shifts.length > 0).length,
        shifts,
        shiftTypesList
    }
}

export function isEmpty(availability: Availability): boolean {
    return (
        availability.shifts.length == 0
        && availability.constraints.length == 0
        && (
            !availability.excludedJobsites
            || availability.excludedJobsites?.length == 0
        )
    )
}

export function hasShifts(availability: Availability): boolean {
    return (
        availability.shifts.length > 0
    )
}


export function availabilityEffectiveTimesHumanReadable(availability: Availability) {
    if (!availability) {
        return
    }

    const timeIntervals = availabilityShiftsAndTimeConstraintsToIntervals(availability.shifts, availability.constraints)
    const timespans = timeIntervals.map((interval) =>  datetime.formatKapitelDateTime(interval.begin, datetime.kapitelDateTimeFormatTime)+' - '+datetime.formatKapitelDateTime(interval.until, datetime.kapitelDateTimeFormatTime) + ' Uhr')

    return timespans.join(', ')
}
