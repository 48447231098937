<template>
    <div>
        <CalendarListItem
            :date="props.initData"
            :day-data="day"
            v-model="model"
            :selectable="selectable"
            v-on:click="onClick"
        />
        <CalendarDetail
            v-model="modalValue"
            :expected-content="expectedDetailContent"
        ></CalendarDetail>
    </div>
</template>

<script setup lang="ts">
import CalendarListItem from "./components/CalendarListItem.vue";
import {ref, Ref, watch} from "vue";
import {Day} from "@/graphql/generated/graphql";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {fetchDayForDate} from "@/helper/day";
import {DataLoader} from "@/helper/dataLoader";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useGlobalEmitter} from "@/helper/emitter";

const model = defineModel()

const props = defineProps<{
    initData: kapitelDateString // day,
    selectable?: boolean
}>()

const day: Ref<Day|undefined> = ref(undefined)

const modalValue: Ref<kapitelDateString|undefined> = ref(undefined)
const expectedDetailContent: Ref<"booking"|"availability"|undefined> = ref(undefined)
watch(() => modalValue.value, () => {
    if(!modalValue.value){
        loader.load()
    }
})

const onClick = () => {
    expectedDetailContent.value = day.value?.booking ? "booking" : "availability"
    modalValue.value = props.initData
}

const loader = new DataLoader(
    async () => day.value = await fetchDayForDate(props.initData)
)

const init = () => {
    loader.load()
}

// refresh content
useGlobalEmitter().on('day:mutated', (date) => {
    if (props.initData === date) {
        loader.load()
    }
})

// re-init on prop change
watch(() => props.initData, init)
init()

</script>
