<script setup lang="ts">
import { IonActionSheet } from "@ionic/vue";
import { ref, watch, computed } from "vue";
import { Booking } from "@/graphql/generated/graphql";
import BookingSummary from "@/components/BookingSummary.vue";
import { getBookingSummaryString } from "@/helper/booking";

const props = defineProps<{
    booking: Booking | undefined;
    modelValue: boolean;
}>();

const emit = defineEmits([
    "update:modelValue",
    "remove:booking",
    "start:updateShiftTimes",
]);
const isOpen = ref(false);

watch(
    () => props.modelValue,
    (value) => {
        isOpen.value = value;
    },
);
watch(
    () => isOpen.value,
    (value) => {
        emit("update:modelValue", value);
    },
);

const header = computed(() => getBookingSummaryString(props.booking))

const actionButtons = [
    {
        text: 'Zeiten anpassen',
        data: {
            action: 'edit',
        },
    },
    {
        text: 'Einsatz entfernen',
        role: 'destructive',
        data: {
            action: 'delete',
        },
    },
    {
        text: 'Abbrechen',
        role: 'cancel',
        data: {
            action: 'cancel',
        },
    },
]

const onSheetDismiss = (event: any) => {
    const detail = event.detail

    if (!detail.data || detail.data.action === 'cancel') {
        cancel()
    }

    else if (detail.data.action === 'edit') {
        onUpdateShiftTimes()
    }

    else if (detail.data.action === 'delete') {
        onRemoveBooking()
    }
}

const onUpdateShiftTimes = () => {
    emit("start:updateShiftTimes", props.booking);
    isOpen.value = false;
};

const onRemoveBooking = () => {
    emit("remove:booking", props.booking);
    isOpen.value = false;
};

const cancel = () => {
    isOpen.value = false;
};
</script>

<template>
    <ion-action-sheet :is-open="isOpen"
        :header="header"
        :buttons="actionButtons"
        @willDismiss="onSheetDismiss"
    />
</template>
