import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {AvailabilityShift, AvailabilityShiftDefault, ShiftType} from "@/graphql/generated/graphql";



 export async function fetchAvailabilityShiftDefaults(): Promise<AvailabilityShiftDefault[]> {
    const result = await query(
        gql`query GetAvailabilityShiftDefaults {
            availabilityShiftDefaults{
                id
                begin
                until
                type
            }
        }`
    )

    return result?.data?.availabilityShiftDefaults
}

export async function saveAvailabilityShiftDefault(shiftDefault: AvailabilityShiftDefault): Promise<any> {
    const MUTATION = gql`
            mutation saveAvailabilityShiftDefault (
                $beginDings: KapitelTimeImmutable!,
                $untilDings: KapitelTimeImmutable!,
                $typeDings: ShiftType!
            ) {
                createAvailabilityShiftDefault(
                    shiftDefault: {
                        type: $typeDings
                        begin: $beginDings
                        until: $untilDings
                    }
                )
                {
                    id
                }
            }`

    const result = await mutation(
        MUTATION, {
            beginDings: shiftDefault.begin,
            untilDings: shiftDefault.until,
            typeDings: shiftDefault.type
        }
    )

    const success = result?.data

    if (!success) {
        throw new Error("error while storing shift default")
    }

    return success
}



export function getForType(entities: Array<AvailabilityShiftDefault>, shiftType: ShiftType): AvailabilityShiftDefault | undefined {
    return entities.find((entity) =>
        entity.type == shiftType
    )
}
