/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleTranslate extends ModuleBase {
  init () {
    this.options = Object.assign({
      vertical: true,
      horizontal: false,
      fromBottom: false,
      fromRight: false
    }, this.options)
  }

  prepareEnter (el) {
    this.applyTransform(el, 'translate', this.prepareExpression(0))
  }

  prepareLeave (el) {
    this.applyTransform(el, 'translate', this.prepareExpression(0, true))
  }

  applyEnter (el, progress) {
    this.applyTransform(el, 'translate', this.prepareExpression(progress))
  }

  applyLeave (el, progress) {
    this.applyTransform(el, 'translate', this.prepareExpression(progress, true))
  }

  prepareExpression (progress, invert) {
    const verticalDirection = this.options.fromBottom
      ? 1
      : -1

    const horizontalDirection = this.options.fromRight
      ? 1
      : -1

    progress = invert
      ? progress
      : 1 - progress

    return [
      'translate(',
      this.options.horizontal
        ? (progress * 100 * horizontalDirection) + '%'
        : '0%',
      ',',
      this.options.vertical
        ? (progress * 100 * verticalDirection) + '%'
        : '0%',
      ')'
    ].join('')
  }
}
