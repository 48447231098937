

export const pluralize = (amount: number, singular: string, plural: string, zeroAmountOverride : string|undefined = undefined): string =>{
  if(amount===1){
    return amount+" "+singular
  }

  if (amount===0 && zeroAmountOverride !== undefined) {
    return zeroAmountOverride+" "+plural
  }

  return amount+" "+plural
}

export const pluralizeUnitOnly = (amount: number, singular: string, plural: string): string =>{
  if(amount===1){
    return singular
  }
  return plural
}
