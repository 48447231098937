<script setup lang="ts">
import { kapitelDateString } from '@/graphql/kapitelTypes'
import { IonChip } from '@ionic/vue'
import date from '@/helper/datetime/date'
import { computed, watch } from 'vue'
import { MonthMetaData } from './types';
import { pluralize } from '@/helper/amountFormatter';
import Skeleton from '@/components/Skeleton.vue';

const props = defineProps<{
    month: kapitelDateString,
    data: MonthMetaData | undefined,
}>()

const phaseLabel = computed(() => {
    switch (true) {
        case props.data?.isPlanning:
            return 'Verfügbarkeitsplanung'
        case props.data?.isBooking:
            return 'Dienstplanung'
        case props.data?.isTimeTracking:
            return 'Zeiterfassung'
        case props.data?.isPast:
            return 'Abgeschlossen'
    }
    return ''
})

const numbersLabel = computed(() => {
    
    const showAvailability = props.data?.isPlanning && props.data.numAvailabilities > 0 
    const showBooking = props.data?.isBooking
    const showTimesheet = props.data?.isTimeTracking && props.data.numUntrackedPastBookings > 0

    if (showAvailability) {
        return `${pluralize(props.data.numAvailabilities, 'Tag', 'Tage')} geplant`
    } else if (showBooking && showTimesheet) {
        return `${pluralize(props.data.numBookings, 'Dienst', 'Dienste')}, ${props.data.numUntrackedPastBookings} ohne Unterschrift`
    } else if (showBooking) {
        return `${pluralize(props.data.numBookings, 'Dienst', 'Dienste', 'noch keine')}`
    } else if (showTimesheet) {
        return `${pluralize(props.data.numUntrackedPastBookings, 'Dienst', 'Dienste')} ohne Unterschrift`
    }

    return ''
})

const monthLabel = computed(() => date.formatKapitelDate(props.month, 'MMMM'))
</script>

<template>
<div
    class="calendar-month"
>
    <h1 class="custom-ricarda no-margin-left">
        {{ monthLabel }}
    </h1>
    <Skeleton v-if="!props.data" :items="['chip']" />
    <template v-else>
        <ion-chip color="primary" v-if="props.data?.isContractStart">
            Vertragsanfang
        </ion-chip>
        <ion-chip color="primary" v-if="phaseLabel">
            {{ phaseLabel }}
        </ion-chip>
        <ion-chip color="secondary" v-if="numbersLabel">
            {{ numbersLabel }}
        </ion-chip>
    </template>
</div>
</template>

<style lang="scss" scoped>
.calendar-month {
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-top: var(--custom-spacing-app-content-padding-vertical);
    padding-bottom: var(--custom-spacing-app-content-padding-vertical);

    ion-chip {
        margin-left: 0;
    }

    white-space: nowrap; // to prevent chips from getting 2 lined
}
</style>
