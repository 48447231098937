import {AvailabilityShift, AvailabilityShiftDefault, ShiftType} from "@/graphql/generated/graphql";


export const orderedShiftTypes: Array<string> = ["FD", "SD", "ND"];

export const getLabelForShift = (shift: string) : string => {
    switch(shift) {
        case 'FD': return 'Frühdienst'
        case 'SD': return 'Spätdienst'
        case 'ND': return 'Nachtdienst'
    }
    return ''
}

export const sortShifts = (a: AvailabilityShiftDefault | AvailabilityShift, b: AvailabilityShiftDefault | AvailabilityShift) => orderedShiftTypes.indexOf(a.type) - orderedShiftTypes.indexOf(b.type)
export const sortShiftTypes = (a:ShiftType, b:ShiftType) => orderedShiftTypes.indexOf(a) - orderedShiftTypes.indexOf(b)


export const uniqueShifts = (availabilityShifts: Array<AvailabilityShift>) => availabilityShifts.filter((j, i) => availabilityShifts.findIndex(j2 => j2 === j) === i)
