<script lang="ts" setup>
import Transitionator from '@/views/components/transitions/Transitionator.vue';
import { IonIcon } from '@ionic/vue';
import { checkmarkCircle, ellipseOutline } from "ionicons/icons";

const model = defineModel()
</script>

<template>
<div class="checkbox">
    <Transitionator :types="['scale']" easing="easeOutBounce" :duration-leave="20">
        <ion-icon aria-hidden="true" :icon="checkmarkCircle" color="primary" slot="end" v-if="model" />
    </Transitionator>
    <Transitionator :types="['scale']" easing="easeOutBounce">
        <ion-icon aria-hidden="true" :icon="ellipseOutline" color="medium" slot="end" v-if="!model" />
    </Transitionator>
</div>
</template>

<style lang="scss" scoped>
.checkbox {
    font-size: 1.5rem;
    position: relative;

    width: 1em;
    height: 1em;

    > * {
        position: absolute;
        top: 0;
        left: 0;
    }

    &:before {
        $SIZE: 0.7em;
        content: '';
        position: absolute;
        top: 0.15em;
        left: 0.15em;
        width: $SIZE;
        height: $SIZE;
        border-radius: 50%;
        background-color: var(--ion-color-white);
    }
}
</style>