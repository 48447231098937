import { PlanningMode, PlanningStatus } from "@/graphql/generated/graphql"

export const getLabelForPlanningMode = (mode: PlanningMode, monthIsSentencable = false) : string => {
    switch(mode) {
        case PlanningMode.AvailabilityPlanning: return (monthIsSentencable ? 'in ' : '') + 'Verfügbarkeitsplanung'
        case PlanningMode.BookingPlanning: return (monthIsSentencable ? 'in ' : '') + 'Dienstplanung'
        case PlanningMode.Uncontracted: return 'ohne Vertrag'
    }
}

export const getLabelForPlanningStatus = (status: PlanningStatus, monthIsSentencable = false) : string => {
    const modeLabel = getLabelForPlanningMode(status.planningMode, monthIsSentencable)
    return status.bookingCompleted ? (monthIsSentencable ? 'fertig geplant' : 'Dienstplanung abgeschlossen') : modeLabel
}
