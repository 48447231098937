<template>
<div>
    <RichResponseHeadline
        v-if="!!headline"
        :title="headline"
    />
    
    <div
        class="rich-month-calendar"
    >
        <MonthCalendar
            :date="initData"
            v-on:date-click="onClick"
        >
            <template v-slot="{ date, adjacent }">
                <div
                    v-if="date && !adjacent"
                    class="calendar-date-content"
                    :class="{booked: !!days[date.id]?.booking, available: (!!days[date.id]?.availability && hasShifts(days[date.id].availability || {shifts: [], date: date.id, constraints: []} as Availability)), absence: !!days[date.id]?.absence}"
                />
            </template>
        </MonthCalendar>
        <MonthCalendarLegend/>
        <CalendarDetail
            v-model="modalValue"
            :expected-content="expectedDetailContent"
        ></CalendarDetail>
    </div>
</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import MonthCalendar from '@/components/MonthCalendar.vue';
import {Availability, Day} from "@/graphql/generated/graphql";
import {computed, PropType, Ref, ref, watch} from "vue";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { DataLoader } from "@/helper/dataLoader";
import { startOfMonth, endOfMonth, formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import { fetchDaysForDateRange } from "@/helper/day";
import {hasShifts} from "@/helper/availability";
import Modal from "@/components/Modal.vue";
import date from "@/helper/datetime/date";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import MonthCalendarLegend from "@/components/MonthCalendarLegend.vue";

const props = defineProps({
    // month start
    initData: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    headline: {
        type: [String, null] as PropType<string|null>,
        required: false,
        default: (props:{initData:kapitelDateString}) => `${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`
    }
})

const modalValue: Ref<kapitelDateString|undefined> = ref(undefined)
const expectedDetailContent: Ref<"booking"|"availability"|undefined> = ref(undefined)

const onClick = (data: {date: kapitelDateString}) => {
    if(!days.value[data.date]){
        return
    }
    expectedDetailContent.value = days.value[data.date].booking ? "booking" : "availability"
    modalValue.value = data.date
}


type DayMap = {
  [date: kapitelDateString]: Day;
};
const days = ref<DayMap>({})


const loader = new DataLoader(
    async () => {
        const from = startOfMonth(props.initData)
        const to = endOfMonth(from)

        const data = await fetchDaysForDateRange(from, to)
        
        data.forEach(day => {
            days.value[day.date] = day
        })
    }
)

const init = () => {
    days.value = {}
    loader.load()
}

// refresh content
useGlobalEmitter().on('day:mutated', (d) => {
    if (Object.keys(days.value).includes(d)) {
        loader.load()
    }
})


// re-init on prop change
watch(() => props.initData, init)
init()
</script>

<style scoped  lang="scss">

.rich-month-calendar {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    padding: 0.5em 1em 1em 1em;
    width: 100%;
    margin-top: 0.5em;
}

.calendar-date-content {
    $SIZE: 0.5em;
    width: $SIZE;
    height: $SIZE;
    border-radius: 50%;
    transform: translateY(1.1em);
    
    &.booked {
        background-color: var(--ion-color-black-tint);
    }

    &.available {
        background-color: var(--ion-color-green);
    }

    &.absence {
        background-color: var(--ion-color-red);
    }
}
</style>
