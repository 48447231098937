import {defineStore} from "pinia";
import {getArrayElementsExcluding, getRandomArrayElements} from "@/helper/array";
import Fuse from 'fuse.js'
import {AInesFeatureSet, AInesRichResponseType, Jobsite, ShiftType} from "@/graphql/generated/graphql";
import date, {formatKapitelDate} from "@/helper/datetime/date";
import {
    addCircleOutline,
    calendarNumberOutline,
    calendarOutline,
    listOutline,
    medkitOutline,
    timerOutline
} from "ionicons/icons";
import {shiftDate} from "@/helper/registerBooking";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {RichContent} from "@/helper/chat/richResponses";

interface QuickChat {
    id: string,
    label: string,
    prompt?: string,
    icon?: string,
    featureSet: AInesFeatureSet,
    richContentsPreview?: RichContent[]
};

export interface ExploreQuickChat extends QuickChat {};

export const exploreQuickChats: ExploreQuickChat[] = [
    {
        id: "sickness_add",
        label: "Krank melden",
        prompt: "Ich melde mich krank.",
        featureSet: AInesFeatureSet.Availability,
        icon: medkitOutline
    },
    {
        id: "booking_add",
        label: "Neuen Dienst eintragen",
        prompt: "Ich möchte einen Dienst eintragen.",
        featureSet: AInesFeatureSet.RegisterBooking,
        icon: addCircleOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.RegisterBookingSummary,
            initData: {
                jobsiteSearchString: undefined,
                dates: undefined,
                shift: undefined
            }
        }]
    },
    {
        id: "next_booking_show",
        label: "Nächster Dienst",
        prompt: "Was ist mein nächste Dienst?",
        featureSet: AInesFeatureSet.Roster,
        icon: calendarNumberOutline
    },
    {
        id: "next_week_show",
        label: "Kommende Woche",
        prompt: "Wie sieht mein Dienstplan für kommende Woche aus?",
        featureSet: AInesFeatureSet.Roster,
        icon: listOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.WeekCalendar,
            initData: date.startOfWeek(date.addWeeks(date.getToday(), 1))
        }]
    },
    {
        id: "next_seven_days_show",
        label: "Nächste 7 Tage",
        prompt: "Wie sieht mein Dienstplan für die nächsten 7 Tage aus?",
        featureSet: AInesFeatureSet.Roster,
        icon: listOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.WeekCalendar,
            initData: date.getToday()
        }]
    },
    {
        id: "next_months_show",
        label: "Dienstplan " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'MMMM'),
        prompt: "Wie sieht mein Dienstplan für den nächsten Monat aus?",
        featureSet: AInesFeatureSet.Roster,
        icon: calendarOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.MonthCalendar,
            initData: date.startOfMonth(date.addMonths(date.getToday(), 1))
        }]
    },
    {
        id: "next_months_show",
        label: "Dienstplan " + formatKapitelDate(date.addMonths(date.getToday(), 2) , 'MMMM'),
        prompt: "Wie sieht mein Dienstplan für den "+ formatKapitelDate(date.addMonths(date.getToday(), 2) , 'MMMM')+" aus?",
        featureSet: AInesFeatureSet.Roster,
        icon: calendarOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.MonthCalendar,
            initData: date.startOfMonth(date.addMonths(date.getToday(), 2))
        }]

    },
    {
        id: "timesheets_show",
        label: "Zeiterfassung",
        prompt: "Für welche Dienste muss ich noch Zeiten erfassen?",
        featureSet: AInesFeatureSet.Timesheet,
        icon: timerOutline,
        richContentsPreview: [{
            type: AInesRichResponseType.TimesheetJobsiteSummary,
            initData: undefined
        }]
    },
    {
        id: "planning_next_month_show",
        label: "Planungsstatus " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'MMMM'),
        prompt: "Wie steht es um die Planung für " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'MMMM') + "?",
        featureSet: AInesFeatureSet.PlanningMonth,
        icon: '/icons/strategy.svg',
        richContentsPreview: [{
            type: AInesRichResponseType.AutopilotPlanningStatusSummary,
            initData: date.startOfMonth(date.addMonths(date.getToday(), 1))
        }]
    },
    {
        id: "planning_this_month_show",
        label: "Planungsstatus " + formatKapitelDate(date.getToday(), 'MMMM'),
        prompt: "Wie steht es um die Planung für " + formatKapitelDate(date.getToday() , 'MMMM') + "?",
        featureSet: AInesFeatureSet.PlanningMonth,
        icon: '/icons/strategy.svg',
        richContentsPreview: [{
            type: AInesRichResponseType.AutopilotPlanningStatusSummary,
            initData: date.startOfMonth(date.getToday())
        }]
    },
    {
        id: "planning_months_show",
        label: "Welche Monate sind aktuell in Dienstplanung?",
        featureSet: AInesFeatureSet.PlanningMonth,
        icon: '/icons/strategy.svg'
    },
    // {
    //     id: "recruiting_start",
    //     label: "Ich kenne jemanden, der zur IBFG will!",
    //     featureSet: AInesFeatureSet.Recruiting,
    // },
    // {
    //     id: "azk_show",
    //     label: "Wie stehts um mein AZK?",
    //     featureSet: AInesFeatureSet.PlanningMonth,
    // },
    {
        id: "jobsite_template_show",
        label: "Stationsauswahl anzeigen",
        prompt: "Wie sieht meine aktuelle Stationsauswahl für " + formatKapitelDate(date.getToday() , 'MMMM') + " aus?",
        featureSet: AInesFeatureSet.PlanningMonth,
        icon: '/icons/strategy.svg',
        richContentsPreview: [{
            type: AInesRichResponseType.AutopilotJobsitePreferences,
            initData: date.startOfMonth(date.getToday())
        }]
    },
]

const getExploreQuickChatById = (id: string) : ExploreQuickChat | undefined => {
    return exploreQuickChats.find(qc => qc.id === id)
}
const getExploreQuickChatByIds = (ids: string[]) : ExploreQuickChat[] => {
    const result : ExploreQuickChat[] = [];
    ids.forEach(id => {
        const qc = getExploreQuickChatById(id)
        if (qc) {
            result.push(qc)
        }
    })
    return result
}

export const useExploreQuickChatStore = defineStore("exploreQuickChatHistory", {
    state: (): {
        usages: { [key:string]: number },
    } => ({
        usages: {},
    }),
    getters: {
        used: (state) => getExploreQuickChatByIds(Object.keys(state.usages))
    },
    actions: {
        trackUsage(qc: ExploreQuickChat) {
            this.usages[qc.id] = (this.usages[qc.id] ?? 0) + 1
        },
    },
});

let fuse : Fuse<ExploreQuickChat> | undefined = undefined
export const getExploreQuickChatsMatching = (s: string, limitN: number) : ExploreQuickChat[] => {
    if (!fuse) {
        fuse = new Fuse(exploreQuickChats, {
            keys: ['label', 'prompt']
        })
    }

    return fuse.search(s)
        .slice(0, limitN)
        .map(result => result.item)
}

// always return at least one random pick together with most used
export const getExploreQuickChatsTopN = (n: number) : ExploreQuickChat[] => {
    // get already used
    const store = useExploreQuickChatStore()
    const usedQCs = store.used

    // pick at most n-1 already used
    const pickedUsedQCs = usedQCs.length > n - 1
        ? getRandomArrayElements(usedQCs, n - 1)
        : usedQCs

    // collect at least 1 random
    const remainingQCs = n - pickedUsedQCs.length
    const resultRandomQCs = getRandomArrayElements(getArrayElementsExcluding(exploreQuickChats, usedQCs), remainingQCs)

    // return between n-1 & n quick chats
    return pickedUsedQCs.concat(...resultRandomQCs)
}
