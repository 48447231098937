/* helper function for init functions */
import {fetchRoles, isLoggedIn, resetRouterBasedOnAuth} from "@/helper/auth";

import { useCyclicRefresh } from "@/helper/cyclicRefresh"

import {registerToPushNotifications} from "@/helper/pushNotifications";
import {deviceProfileRefresh} from "@/helper/deviceProfile";

import { init as initNotifications } from '@/helper/notification';
import { useAuthStore } from '@/store/auth';
import { Employee } from "@/graphql/generated/graphql";
import useEmitter, {GlobalEvents, useGlobalEmitter} from "./emitter";
import {consoleLogApp} from "@/helper/console";

export type AppState = 'loggedout' | 'token' |  'employee'

interface State {
    index: number,
    key: AppState,
    // label: string,
    next?: (...args : any[]) => void,
    prev?: () => void,
    onUp?: () => Promise<void>,
    onDown?:() => Promise<void>
}

const states = [
    { 
        index: 0,
        key: 'loggedout',
        // label: "1: not logged in",
        // next: (token) => {},
        // prev: () => {},
        onUp: async () => {}
    } as State,
    { 
        index: 1,
        key: 'token',
        // label: "2: logged in (as employee or consultant)",
        // next: (employee) => {},
        prev: () => {},
        // onUp: async () => {      
            
        // },
        // onDown: async () => {
        // }
    } as State,
    { 
        index: 2,
        key: 'employee',
        // label: "3: employee defined (as employee or consultant)",
        // next: () => {},
        prev: () => {},
        onUp: async () => {
            /* setup cyclic refresh */      
            await useCyclicRefresh().start()            
            consoleLogApp("started cyclic refresh")

            /* in app notification (from push notification) handling */
            if(useAuthStore().isEmployee()) {
                initNotifications()
                consoleLogApp("initialized in app notifications")
            }

            /* fcm token, device profile & push notifications */
            if(useAuthStore().isEmployee()){
                consoleLogApp("registering push notifications & device profile for push notifications")
                registerToPushNotifications().then(() => {
                    const silent: boolean = true
                    deviceProfileRefresh(silent).then(() => {})             
                })
            }
        },
        onDown: async () => {
            useCyclicRefresh().stop()
            consoleLogApp("stopped cyclic refresh")

            if(useAuthStore().isEmployee()) {
                // unInitNotifications()
            }
        }
    } as State,
]

const findState = (key: AppState) : State => {
    const s = states.find(state => key === state.key) 
    if (!s) {
        throw new Error('state not found')
    }
    return s
}

let state : State;
const setState = async (key:AppState, impersonationUserSwitch = false) => {
    if (state && state.key === key) {
        throw new Error('state already set')
    }

    const newState = findState(key)
    const oldState = state || undefined

    const direction = !oldState || newState.index > oldState.index ? 'up' : 'down'

    if (direction === 'down' && oldState?.onDown) {
        await oldState.onDown()
    }
    
    if (direction === 'up' && newState?.onUp) {
        await newState.onUp()
    }

    state = newState

    consoleLogApp('app state ' + direction + ' ' + (oldState ? (oldState.key + ' > ') : '') + newState.key)

    useGlobalEmitter().emit("AppStateChanged", {state: state.key, impersonationUserSwitch})
}

export function useAppState() {

    const onAuthLogin = async () => {
        await setState('token')
        if (useAuthStore().isImpersonating() || useAuthStore().isEmployee()) {
            await setState('employee', false) // initial promotion after login - no user switch
        }
    }

    const goToLogout = async () => {
        // dont skip a stepp
        if (state.key === 'employee') {
            await setState('token')
        }
        await setState('loggedout')
    }

    const onImpersionationStart = async () => {
        await setState('employee', true)
    }

    const onImpersionationStop = async () => {
        await setState('token', true)
    }

    const init = async () => {
        
        // initial state
        await setState('loggedout')

        if (isLoggedIn()) {
            await onAuthLogin()
        }   
    }
    return {
        init,
        // state: () => state,
        logout: () => {
            useAuthStore().purgeAuth()

            goToLogout()

            resetRouterBasedOnAuth()
        },
        login: async (token: string) => {
            const authStore = useAuthStore();
            authStore.setToken(token);

            await fetchRoles();

            onAuthLogin()

            // after successful login & role fetch: re-route            
            resetRouterBasedOnAuth()            
        },
        impersonate: async (employee: Employee) => {
            const authStore = useAuthStore()

            if (authStore.isImpersonating()) {                
                authStore.setImpersonation("")
                onImpersionationStop()
            }

            const name = employee?.user?.userName

            if (!name) {
                return
            }
            authStore.setImpersonation(name)
            
            await onImpersionationStart()
        },
        stopImpersonation: async () => {
            const authStore = useAuthStore()
            if (!authStore.isImpersonating()) {
                return
            }
            authStore.setImpersonation("")
            
            await onImpersionationStop()
        }
    };
}
