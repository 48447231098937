/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleScale extends ModuleBase {
  init () {
    this.options = Object.assign({
      base: 0
    }, this.options)
  }

  prepareEnter (el) {
    this.applyTransform(el, 'scale', 'scale(' + this.options.base + ')')
  }

  prepareLeave (el) {
    this.applyTransform(el, 'scale', 'scale(1)')
  }

  applyEnter (el, progress) {
    this.applyTransform(el, 'scale', 'scale(' + ((1 - this.options.base) * progress + this.options.base) + ')')
  }

  applyLeave (el, progress) {
    this.applyTransform(el, 'scale', 'scale(' + (1 - (progress * (1 - this.options.base))) + ')')
  }
}
