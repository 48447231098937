<template>
  <div class="vmnstr">
		<div ref="scrollNode" class="mnstr" style="overflow-y: auto; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; position: relative; will-change: transform;" @scroll="onScroll">
			<ul ref="listNode" class="mnstr-list" style="position: relative; overflow: hidden; list-style-type: none; margin: 0; padding: 0;">
        <!-- eslint-disable -->
				<li
          ref="listItems"
					class="mnstr-cell"
					style="position: absolute; width: 100%; top: 0; left: 0; border: 0; box-sizing: border-box;"
					v-for="element in displayedElements"
          :class="{expanded : element.isExpanded}"
          :data-level="element.level"
          >
          <div
            class="mnstr-content"
            style="width: 100%; margin: 0; border: 0;">
            <slot :element="element.element"></slot>
          </div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
  import MNSTR from './mnstr.local.js'

  export default {
    name: 'Vmnstr',

    props: {
      /**
       * MNSTR properties and options.
       */

      elements: {
        type: Array,
        default: []
      },
      elementChildren: {
        type: Function,
        default: void 0
      },
      thresholdRatio: {
        type: Number,
        default: 0.5
      },
      preventWheelBubbling: false,
      useTransform: {
        type: Boolean,
        default: true
      },
      initialScrollToElement: undefined,
      initialScrollToOptions: undefined,
      observeCellBounds: {
        type: Boolean,
        default: false
      },
      rememberChildrenExpands: true,
      restoreData: {
        type: Object,
        default: void 0
      },
      keepScrollStateOnUpdate: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        displayedElements: [],
        restorageData: this.restoreData,
        initialRenderComplete: false
      }
    },

    mounted () {
      // Temp: Due to some unkown reasons there are rendering issues on iOS (only when running as an app)
      // Initializing mnstr 2 frames AFTER activated is the only thing that helps by now.
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.mnstr
            ? void 0
            : this.initMNSTR()
        })
      })
    },

    updated () {
      this.didUpdateDOM()
    },

    computed: {
      willObserveCellBounds () {
        return this.observeCellBounds === true
      }
    },

    watch: {
      elements (newValue, oldValue) {
        this.mnstr
          ? this.mnstr.update(false, this.keepScrollStateOnUpdate)
          : void 0
      },

      displayedElements () {
        if (!this.initialRenderComplete) {
          return
        }

        this.onMNSTRListElementsChange()
      }
    },

    methods: {
      /**
       * Instantiate MNSTR.
      */

      initMNSTR () {
        this.mnstr = new MNSTR({
          context: this,
          parentNode: this.$el,
          getData: function () {
            return this.elements
          }.bind(this),
          getElementChildren: this.elementChildren
            ? function (element) {
              return this.elementChildren(element)
            }.bind(this)
            : void 0,
          thresholdRatio: this.thresholdRatio,
          preventWheelBubbling: this.preventWheelBubbling,
          useTransform: this.useTransform,
          initialScrollToElement: this.initialScrollToElement,
          initialScrollToOptions: this.initialScrollToOptions,
          observeCellBounds: this.observeCellBounds,
          rememberChildrenExpands: this.rememberChildrenExpands,
          didInitialRenderComplete: this.onMNSTRInitialRenderComplete,
          didRenderFirstElement: this.onMNSTRRenderFirstElement,
          didRenderLastElement: this.onMNSTRRenderLastElement,
          firstInBoundsElementChanged: this.onMNSTRFirstInBoundsElementChanged,
          lastInBoundsElementChanged: this.onMNSTRLastInBoundsElementChanged,
          renderOnInitialize: false
        })

        this.mnstr.needUpdate()
        this.mnstr._scrollNode = this.$refs.scrollNode
        this.mnstr._listNode = this.$refs.listNode
        this.mnstr.virtualEnvironment = true
        this.mnstr.needUpdateVirtualDOM = this.needUpdateDOM
        this.mnstr.initScrollListener()

        this.mnstr.rAF(async () => {
          this.restoreData
            ? await this.mnstr.restoreState(this.restoreData)
            : await this.mnstr.updateCells(true)
          this.mnstr.renderListResizeObserver()
          this.onMNSTRInitialRenderComplete(this.mnstr)

          this.restorageData = void 0
        }, 'render')

        window.mnstr = this.mnstr
      },

      needUpdateDOM (elements, success) {
        this.displayedElements = elements
        this.didUpdateDOMCllbck = success
      },

      didUpdateDOM () {
        this.didUpdateDOMCllbck
          ? this.didUpdateDOMCllbck.call(this.mnstr)
          : void 0

        this.didUpdateDOMCllbck = undefined
      },

      onScroll (e) {
        this.$emit('scroll', e)
      },

      getRenderedElements () {
        return this.displayedElements
      },

      isInitialized () {
        return this.initialRenderComplete === true
      },

      /**
       * Wrapper methods to expand / collapse / toggle expand list elements.
       */

      expandElement (element) {
        this.mnstr.expandElement(element)
      },

      collapseElement (element) {
        this.mnstr.collapseElement(element)
      },

      toggleExpandElement (element) {
        this.mnstr.toggleExpandElement(element)
      },

      scrollToElement (element, options) {
        this.mnstr.scrollToElement(element, options)
      },

      exportRestoreData () {
        return this.mnstr.exportRestoreState()
      },

      /**
       * Bubble MNSTR events to parent components.
       */

      onMNSTRListElementsChange () {
        this.$emit('renderedelementschange', this.displayedElements, this)
      },

      onMNSTRInitialRenderComplete (mnstr) {
        this.initialRenderComplete = true
        this.$emit('initialrendercomplete', this)
        this.onMNSTRListElementsChange()
      },

      onMNSTRRenderFirstElement (element, mnstr) {
        this.$emit('renderfirstelement', element, this)
      },

      onMNSTRRenderLastElement (element, mnstr) {
        this.$emit('renderlastelement', element, this)
      },

      onMNSTRFirstInBoundsElementChanged (element, mnstr) {
        if (!this.initialRenderComplete) {
          return
        }

        this.$emit('firstinboundselementchanged', element, this)
      },

      onMNSTRLastInBoundsElementChanged (element, mnstr) {
        if (!this.initialRenderComplete) {
          return
        }

        this.$emit('lastinboundselementchanged', element, this)
      },

			/**
			 * Pass iFrame events to list
			 */

			onCellResize () {
				console.log('resize')
			}
    }
  }
</script>

<style>
  .vmnstr .mnstr {
    width: 100%;
    height: 100%;
  }
</style>
