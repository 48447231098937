<script setup lang="ts">
import {  computed } from 'vue'
import { IonTextarea } from '@ionic/vue'
import { getAbsenceTypes, generateAbsence, getLabelForAbsenceType, cmpAbsenceTypes } from '@/helper/absence'
import RadioGroup from '@/components/RadioGroup.vue'
import { Absence } from '@/graphql/generated/graphql';

const props = defineProps<{
    date: string,
    absence: Absence
}>()

const emit = defineEmits(["update:absence"]);

const propsType = computed(()=>props.absence?props.absence.type:getAbsenceTypes()[0])
const propsComment = computed(()=>props.absence?props.absence.comment:"")

const changedProps = {
    comment: undefined,
    type: undefined
};

const onChangeType =({target})=> {
    changedProps["type"] = target.value
    saveAbsence()
}
const onChangeComment =({target})=> {
    changedProps["comment"] = target.value
    saveAbsence()
}


const absenceTypes = computed(() => getAbsenceTypes().toSorted(cmpAbsenceTypes))

const saveAbsence = async () => {
    const absence = props.absence

    if(changedProps.type){
        absence.type=changedProps.type
    }
    
    if(changedProps.comment!==undefined){
        absence.comment = changedProps.comment
    }

    emit("update:absence", absence)
}


</script>

<template>

    <RadioGroup
        label="Grund"
        :items="absenceTypes"
        :model-value="propsType"
        @ionChange="onChangeType"
        v-slot="{item}"
    >
        {{ getLabelForAbsenceType(item) }}
    </RadioGroup>

    <ion-textarea
        label="Kommentar"
        label-placement="stacked"
        :value="propsComment"
        @ionChange="onChangeComment"
    />

</template>
