import {Capacitor} from "@capacitor/core";
import {App as CapacitorApp, AppInfo} from '@capacitor/app';

let info : AppInfo | undefined = undefined

export const getAppInfo = async ()  : Promise<AppInfo | undefined> => {
    if (!Capacitor.isNativePlatform()) {
        return undefined
    }

    if (!info) {
        info = await CapacitorApp.getInfo()
    }

    return info
}

export const getVersion = async ()  : Promise<string | undefined> => {
    return (await getAppInfo())?.version
}

export const getBuild = async ()  : Promise<string | undefined> => {
    return (await getAppInfo())?.build
}

