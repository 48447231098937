import {useChatStore} from "@/helper/chat/chatStore";
import {AInesAssistantType} from "@/graphql/generated/graphql";

import {
    createAssistant,
    createThread,
    getApiKey,
    MessagePersistParameters,
    persistMessage,
    sendMessage
} from "@/helper/chat/assistantAPI";
import {AssistantRunExpert} from "@/helper/chat/assistantRun/assistantRunExpert";
import {AssistantRunChooser} from "@/helper/chat/assistantRun/assistantRunChooser";
import {warmUpAssistantFeatureMapping} from "@/helper/chat/assistantFeatureMapping";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";
import {kapitelErrorHandler} from "@/helper/error";
import {ThreadMessage} from "@/helper/chat/threadMessage";

import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import useEmitter, {useGlobalEmitter} from "@/helper/emitter";

import {RichContent} from "@/helper/chat/richResponses";
import {kapitelDateTimeString} from "@/graphql/kapitelTypes";
import datetime from "@/helper/datetime/datetime";


const allowConcurrencyLockoutOverride = true


export const appendUserMessage = async (
    message: string,
    messageExpertAssistant: AInesAssistantType
):  Promise<void> => {
    const chatStore = useChatStore()

    const expertRunConfig = await getExpertAssistantRunConfig(messageExpertAssistant);

    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        'user'
    ))

    const messageResponse: boolean = await sendMessage(message, expertRunConfig.threadId, 'user')
    if (messageResponse) {
        makeMessagePersistParams({
            config: expertRunConfig,
            role: "user",
            content: message
        }).then(persistMessage)
    }
}

window.addEventListener("unhandledrejection", (event) => {
    const chatStore = useChatStore()

    // if (event.reason instanceof OpenAIError) {
    if (event.reason.constructor.name.includes('APIError') && event.reason.message.includes('You can retry your request')) {
        if (chatStore.currentExpertRun) {
            chatStore.currentExpertRun.onUnhandledStreamError(event.reason)
        }
        if (chatStore.currentChooserRun) {
            chatStore.currentChooserRun.onUnhandledStreamError(event.reason)
        }
    } else {
        // unhandled promise rejection
        console.warn('unhandledrejection %o', event)
    }
});


export const sendUserMessage = async (
    message: string,
    {
        messageExpertAssistant = undefined,
        prependedAssistantMessage = undefined,
        prependExpertAssistant = undefined,
        richContentsPreview = undefined,
        isScriptedContent= undefined
    }: {
    messageExpertAssistant?: AInesAssistantType,
    prependedAssistantMessage?: string,
    prependExpertAssistant?: AInesAssistantType,
    richContentsPreview?: RichContent[],
    isScriptedContent?:boolean
} = {}
): Promise<void> => {
    const chatStore = useChatStore()

    // concurrency lockout
    if (chatStore.pingPongProcessing) {
        if (allowConcurrencyLockoutOverride) {
            consoleErrorChat("hard resetting chatState to initial state despite currently pending run %o %o", chatStore.chatStatus, chatStore.currentExpertRun)
            resetConversation()
        } else {
            consoleErrorChat('concurrency lockout active')
            kapitelErrorHandler('concurrency lockout active')
            return
        }
    }
    chatStore.setPingPongProcessing(true)


    // reset to init
    chatStore.setChatStatus(ChatStatus.READY)
    // if this failed
    if (ChatStatus.READY !== chatStore.chatStatus) {
        consoleErrorChat("hard resetting chatState to initial state")
        resetConversation()
    }
    // preserve previousExpertRun before resetting
    const previousExpertRun = chatStore.currentExpertRun as AssistantRunExpert || undefined
    chatStore.resetCurrentExpertRun()

    chatStore.newPingPong()

    // set state to "expecting"
    chatStore.setChatStatus(ChatStatus.EXPECTING_NEXT_RESPONSE)


    // preview handling
    if (richContentsPreview) {
        chatStore.setCurrentExpertRunRichContentsPreview(richContentsPreview)
    }


    // prepend role=assistant messages to thread
    if(prependedAssistantMessage){
        if (!prependExpertAssistant) {
            prependExpertAssistant = messageExpertAssistant
        }
        if(!prependExpertAssistant){
            chatStore.setPingPongProcessing(false)
            throw Error("target assistant needed.")
        }

        const expertRunConfig = await getExpertAssistantRunConfig(prependExpertAssistant);

        chatStore.setChatStatus(ChatStatus.PREPENDING_ASSISTANT_MESSAGE)

        chatStore.addToMessageHistory(new ThreadMessage(
            message,
            'assistant'
        ))

        const messageResponse: boolean = await sendMessage(message, expertRunConfig.threadId, 'assistant')
        if (messageResponse) {

            makeMessagePersistParams({
                config: expertRunConfig,
                role: "assistant",
                content: message,
            }).then(persistMessage)
        }
    }


    consoleLogChat('sending "%s" to %o', message, messageExpertAssistant ? AInesAssistantType[messageExpertAssistant] : 'unknown expert')

    // collect user message
    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        'user'
    ))

    // determine next expert run
    let nextExpertRun: AssistantRunExpert | undefined;

    try {
        // no target assistant given? ask chooser.
        if (!messageExpertAssistant) {
            chatStore.setChatStatus(ChatStatus.CHOOSER_PENDING);
            [messageExpertAssistant, nextExpertRun] = await _sendUserMessageToChooser(message, isScriptedContent??false, previousExpertRun)
        } else {
            // at least inform chooser assistant about the message for subsequent chooser questions
            const chooserConfig = await getChooserRunConfig()

            sendMessage(message, chooserConfig.threadId, 'user').then((response: boolean) => {
                if (response) {
                    makeMessagePersistParams({
                        config: chooserConfig,
                        role: "assistant",
                        content: message,
                    }).then(persistMessage)
                }
            })

        }

        // target assistant known? send to expert
        if (!nextExpertRun && messageExpertAssistant) {
            nextExpertRun = await _sendUserMessageToTargetAssistant(message, isScriptedContent??false, messageExpertAssistant, previousExpertRun)
        }
    } catch (e: unknown) {
        kapitelErrorHandler(e as Error)
    } finally {
        // release concurrency lockout
        chatStore.setPingPongProcessing(false)
    }

    if (!nextExpertRun) {
        chatStore.setChatStatus(ChatStatus.FAILED)
        return
    }

    chatStore.setCurrentExpertRun(nextExpertRun)
}
// message sender requires choosing of target assistant by chooserAssistant
const _sendUserMessageToChooser = async (
    message: string,
    isScriptedContent: boolean,
    previousExpertRun: AssistantRunExpert | undefined
): Promise<[AInesAssistantType | undefined, AssistantRunExpert | undefined]> => {

    // kick off targetExpertAssistant chooser
    consoleLogChat('choosing expertAssistant')
    const chooserPromise = chooseExpertAssistant(message, isScriptedContent)

    // potential shortcut: check for existing expert and optimistically send message there as well
    if (previousExpertRun) {
        consoleLogChat('send message to previous expertAssistant as well')

        const previousExpertReuseConfirmedPromise = chooserPromise.then((targetExpertAssistant): boolean => previousExpertRun.runConfig.assistantType === targetExpertAssistant)

        const expertRunWithReusedExpert = await _sendUserMessageToTargetAssistant(
            message,
            isScriptedContent,
            previousExpertRun.runConfig.assistantType,
            previousExpertRun,
            previousExpertReuseConfirmedPromise
        )

        // wait for confirmation of previous expert and return (already running) run
        // otherwise continue with chosen expert and a new run like that shortcut never happened
        if (await previousExpertReuseConfirmedPromise) {
            return [previousExpertRun.runConfig.assistantType, expertRunWithReusedExpert]
        }
    }

    // wait for target assistant choice
    const targetExpertAssistant = await chooserPromise
    if (!targetExpertAssistant) {
        return [undefined, undefined]
    }

    return [targetExpertAssistant, undefined]
}
// message sender defines target assistant: no chooser needed
const _sendUserMessageToTargetAssistant = async (
    message: string,
    isScriptedContent: boolean,
    targetExpertAssistant: AInesAssistantType,
    previousExpertRun: AssistantRunExpert | undefined,
    optimisticExpertConfirmedPromise: Promise<boolean> | undefined = undefined
): Promise<AssistantRunExpert> => {

    const expertRunConfig = await getExpertAssistantRunConfig(targetExpertAssistant);

    if (previousExpertRun && previousExpertRun.runConfig.assistantType !== targetExpertAssistant) {
        consoleLogChat('switched expertAssistant runConfig from previous config %O to %O', previousExpertRun.runConfig, expertRunConfig)
    }

    return new AssistantRunExpert(expertRunConfig, message, isScriptedContent, optimisticExpertConfirmedPromise)
}


////// Experts //////

const existingExperts = new Map<AInesAssistantType, Promise<AssistantRunConfig>>

let warmUpExpertsPromise: Promise<AssistantRunConfig[]> | undefined = undefined;
const resetExperts = () => {
    warmUpExpertsPromise = undefined
    existingExperts.clear()
}
const warmUpExperts = () => {
    if (!warmUpExpertsPromise) {
        const experts = Object.values(AInesAssistantType);
        const logString = "warming up " + experts.length + " expert assistants & threads";

        consoleLogChat(logString)
        console.time(logString)
        console.groupCollapsed()

        const expertConfigPromises = experts.map(
            (assistantType: AInesAssistantType) => {
                consoleLogChat("creating new expert assistant & thread for " + assistantType)
                const configPromise = Promise
                    .all([
                        createAssistant(assistantType),
                        createThread()
                    ])
                    .then((v) => {
                        const [assistantId, threadId] = v
                        return {
                            assistantType: assistantType,
                            assistantId: assistantId,
                            threadId: threadId
                        }
                    })
                existingExperts.set(assistantType, configPromise)
                return configPromise
            }
        )
        console.groupEnd();

        warmUpExpertsPromise = Promise.all(expertConfigPromises)
        warmUpExpertsPromise.then(() => console.timeEnd(logString))
    }

    return warmUpExpertsPromise;
}

const getExpertAssistantRunConfig = async (targetExpertAssistant: AInesAssistantType): Promise<AssistantRunConfig> => {
    if (!warmUpExpertsPromise) {
        warmUpExperts()
    }

    return await existingExperts.get(targetExpertAssistant) as AssistantRunConfig
}


/////// Chooser ///////

let warmUpChooserPromise: Promise<AssistantRunConfig> | undefined = undefined;
interface ChooserTTL {
    pingTS: kapitelDateTimeString|undefined;
    inactiveTS: kapitelDateTimeString|undefined;
    initTS: kapitelDateTimeString|undefined;
    resetInactivityMinutes: number;
}

const chooserTTL: ChooserTTL = {
    pingTS: undefined,
    inactiveTS: undefined,
    initTS: undefined,
    resetInactivityMinutes: 5
}

const resetChoser = () => {
    warmUpChooserPromise = undefined
}
const warmUpChooser = () => {
    if (!warmUpChooserPromise) {
        consoleLogChat("warming up chooser assistant & thread")

        warmUpChooserPromise = Promise
            .all([
                createAssistant(AInesAssistantType.Meta),
                createThread(),
            ])
            .then((v) => {
                const [assistantId, threadId,] = v

                chooserTTL.initTS = datetime.getNow();

                return {
                    assistantType: AInesAssistantType.Meta,
                    assistantId: assistantId,
                    threadId: threadId,
                }
            })
    }

    return warmUpChooserPromise
}

export const makeMessagePersistParams = async ({
   config,
   runId = undefined,
   role = "assistant",
   content = "",
   toolCalls = [],
   toolCallId = undefined,
   richResponses = [],
   isScriptedContent = false
}: {
    config: AssistantRunConfig,
    runId?: string | undefined,
    role?: "assistant" | "user" | "tool",
    content?: string,
    toolCalls?: any,
    toolCallId?: string | undefined,
    richResponses?: any,
    isScriptedContent?: boolean,
}): Promise<MessagePersistParameters> => {
    if(content.length == 0 && toolCalls.length == 0){
        console.warn("Either content or toolCalls should probably be set.")
    }
    return {
        assistant: config.assistantId,
        assistantType: config.assistantType,
        sessionId: useChatStore().sessionId,
        threadId: config.threadId,
        pingPongId: useChatStore().pingPongId,
        runId: runId,
        role: role,
        content: content,
        toolCalls: toolCalls,
        toolCallId: toolCallId,
        richResponses: richResponses,
        isScriptedContent: isScriptedContent
    };
};

const getChooserRunConfig = async (): Promise<AssistantRunConfig> => await (warmUpChooserPromise || warmUpChooser())

const chooseExpertAssistant = async (message: string, isScriptedContent: boolean): Promise<AInesAssistantType | undefined> => {
    const metaAssistantRunConfig = await getChooserRunConfig()

    const metaRun = new AssistantRunChooser(metaAssistantRunConfig, message, isScriptedContent)

    useChatStore().setCurrentChooserRun(metaRun)

    const assistant = await metaRun.assistantChoicePromise

    useChatStore().setCurrentChooserRun(undefined)

    if (!assistant) {
        consoleErrorChat('chooser failed to choose expertAssistant: %O', metaRun.response)
        return undefined
        // throw new Error('chooser failed to choose expertAssistant')
    }

    consoleLogChat('choose expertAssistant: ' + assistant)
    return assistant
}

////// API KEY /////

let apiKeyPromise : Promise<string> | undefined = undefined

const warmUpApiKey = async() => {
  if (!apiKeyPromise) {
      apiKeyPromise = getApiKey()
  }
  return apiKeyPromise
}

export const getOpenAIApiKey = async() => await warmUpApiKey()

////// Warmup ////

let initialized = false

export const setUpAssistant = async () => {
    initialized = true

    // trigger "update" event for sentry
    useChatStore().resetSession()

    return Promise.all([
        warmUpExperts(),
        warmUpChooser(),
        warmUpAssistantFeatureMapping(),
        warmUpApiKey()
    ])
}


useGlobalEmitter().on("AppStateChanged", ({state, impersonationUserSwitch}) => {
    if (!initialized) {
        return;
    }

    if (state === 'employee' && impersonationUserSwitch) {
        resetConversation()
    }
})

const shouldResetAssistantByInactivity  = (lastActivityTs: kapitelDateTimeString):  boolean =>  {
    if(!chooserTTL.initTS){
        return false
    }

    const now = datetime.getNow()

    const resetPIT = datetime.addMinutes(
        lastActivityTs,
        chooserTTL.resetInactivityMinutes
    )

    return datetime.isAfter(now,  resetPIT)

}


const shouldResetAssistant = () => {
    if(chooserTTL.initTS){
        if(chooserTTL.inactiveTS){
            // we have been inactve -> look at time since inactivity
            if(shouldResetAssistantByInactivity(chooserTTL.inactiveTS)){
                consoleLogChat("reset assistant because of long inactivity")
                chooserTTL.inactiveTS = undefined
                return true
            }
        }else if(chooserTTL.pingTS){
            // we have not been inactive -> look at time since last ping
            if(shouldResetAssistantByInactivity(chooserTTL.pingTS)){
                consoleLogChat("reset assistant because of long inactivity")

                return true
            }
        }
    }
    consoleLogChat("no need  to reset:", chooserTTL)
    return false
}

export const resetConversation = ()=> {
    const chatStore = useChatStore()

    chatStore.resetTtsObjects()
    chatStore.setTtsStatus('silent')
    chatStore.setPingPongProcessing(false)
    chatStore.setChatStatus(ChatStatus.READY, true)
    chatStore.resetCurrentExpertRun()
    chatStore.setCurrentChooserRun(undefined)
    chatStore.resetMessageHistory()
    chatStore.setTranscribeStatus('ready')
    chatStore.resetSession()

    resetChoser()
    resetExperts()

    setUpAssistant()
}

const emitter = useEmitter()

emitter.on('CapacitorAppStateChange:active', ()=>{
    consoleLogChat("active")
    if(shouldResetAssistant()){
        resetConversation()
    }
})

emitter.on('CapacitorAppStateChange:inactive', ()=>{
    consoleLogChat("inactive")
    if(chooserTTL.initTS){
        chooserTTL.inactiveTS = datetime.getNow()
    }
})

emitter.on("SendPing",  ()=>{
    if(chooserTTL.initTS){
        chooserTTL.pingTS = datetime.getNow()
    }
})

