<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>Mitarbeiter</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">
            <Skeleton v-if="employeeLoader.pending.value" :items="['line', 'line', 'line']"></Skeleton>
            <ion-list v-else>
                <ion-item
                    v-for="employee in employees"
                    @click="onClickEmployee(employee)"
                    :key="employee.id"
                >
                    <IonLabel>
                        <h3>
                            {{ employee.name }}
                        </h3>
                        <span v-if="employeeIsImpersonated(employee)">
                            aktuell ausgewählt
                        </span>
                        <span
                            v-for="calendarMonth in employeeMonths(employee)"
                        >
                            <AutopilotStatusMonthSummaryChip
                                :autopilot-status="autopilotStatus.find(autopilotStatus => autopilotStatus.month === calendarMonth.getId() && autopilotStatus.employee.id === employee.id)"
                                :employee="employee"
                                :calendar-month="calendarMonth"
                                :month-label-format="'MMM'"
                            ></AutopilotStatusMonthSummaryChip>
                        </span>
                    </IonLabel>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonContent,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    onIonViewWillEnter,
    loadingController,
    IonChip
} from '@ionic/vue'
import { fetchEmployees } from '@/helper/employee'
import { useAuthStore } from '@/store/auth'
import {Ref, ref} from 'vue'
import { useRouter } from 'vue-router'
import Skeleton from "@/components/Skeleton.vue";
import {DataLoader} from "@/helper/dataLoader.js";
import {AutopilotStatus, Employee} from "@/graphql/generated/graphql";
import { useAppState } from '@/helper/appState'
import { fetchCurrentAutopilotStatus, getAutopilotStatusSummary } from '@/helper/autopilotStatus'
import {CalendarMonth, generateCalendarMonth, generateCalendarMonths, sorter} from '@/helper/calendar/calendarMonth'
import date from '@/helper/datetime/date'
import AutopilotStatusMonthSummaryChip from "@/views/components/Planning/AutopilotStatusMonthSummaryChip.vue";
import useOfflineHelper from "@/helper/offline";

const authStore = useAuthStore()

const router = useRouter()

/** 
 * employees
 */
const employees : Ref<Employee[]> = ref([])
const employeeLoader = new DataLoader(async () => {
    await useAppState().stopImpersonation()
    employees.value = await fetchEmployees()    
})

onIonViewWillEnter(employeeLoader.load)
employeeLoader.load()

const onClickEmployee = async (employee: Employee) => {
    if(offlineHelper.isOffline.value){
        return;
    }
    const loading = await loadingController.create({
        message: 'Mitarbeiterwechsel...'          
    });

    loading.present();

    await useAppState().impersonate(employee)

    loading.dismiss()

    router.push('/')
}

const employeeIsImpersonated = (employee: Employee) => {
    return employee.user.userName === authStore.getImpersonationUsername()
}

/** autopilot Status */
const autopilotStatus : Ref<AutopilotStatus[]> = ref([])
const autopilotLoader = new DataLoader(async () => {
    autopilotStatus.value = await fetchCurrentAutopilotStatus()    
})

const employeeMonths = (employee: Employee) : CalendarMonth[] => {
    const employeeStatusMonths = autopilotStatus.value
        .filter(aps => aps.employee.id === employee.id)
        .map(aps => generateCalendarMonth(aps.month))
        .sort(sorter);

    let from = date.startOfMonth(date.getToday())
    if (employeeStatusMonths[0] && employeeStatusMonths[0].begin < from) {
        from = employeeStatusMonths[0].begin
    }

    let to = date.addMonths(from, 1)
    const lastStatusMonth = employeeStatusMonths[employeeStatusMonths.length-1]
    if (lastStatusMonth && lastStatusMonth.begin > to) {
        to = lastStatusMonth.begin
    }

    return generateCalendarMonths(from, undefined, to)
}

const offlineHelper = useOfflineHelper()

onIonViewWillEnter(autopilotLoader.load)
autopilotLoader.load()

</script>
