import {PendingPromiseHelper} from "@/helper/pendingPromise";

import {Ref, ref} from "vue";

export class RequestQueueHelper {
    pendingMutations:PendingPromiseHelper

    constructor() {
        this.pendingMutations = new PendingPromiseHelper()
    }

    public query(f: () => Promise<any>) {
        return this.pendingMutations.allSettled().then(f)
    }

    public mutation(f: () => Promise<any>) : Promise<any> {
        const p = f()
        this.pendingMutations.addPending(p)

        this.updatePendingMutationsState()
        p.finally(() => {this.updatePendingMutationsState()})

        return p
    }

    public pendingMutationsState: Ref<boolean> = ref(false)

    private updatePendingMutationsState() {
        this.pendingMutationsState.value = this.pendingMutations.hasPending()
    }
}

let singleton:RequestQueueHelper;
export function useRequestQueueHelper():RequestQueueHelper {
    if (!singleton) {
        singleton = new RequestQueueHelper();
    }
    return singleton;
}
