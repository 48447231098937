<template>
<div>
    <Skeleton v-if="loader.pending.value" :items="['title','block--height-12rem','block--height-4rem','block--height-4rem']"></Skeleton>
    <template v-else>

        <RichResponseHeadline
            :title="renderSet.headline"
        />

        <template v-if="renderSet.status === PlanningMode.AvailabilityPlanning">

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.booking.booked, 'Dienst', 'Dienste')} geplant`"
                :icon="renderSet.booking.booked.toString()"
                icon-type="text"
                :text="'Planung noch nicht gestartet'"
                @click="() => showModalFlags.startPlanningWizard = true"
            />

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.availability.amount, 'verfügbarer Tag', 'verfügbare Tage')}`"
                :icon="renderSet.availability.amount.toString()"
                icon-type="text"
                icon-color="green"
                :text=" renderSet.availability.amount > 0 ? `im ${renderSet.availability.shifts.toSorted(sortShiftTypes).join(', ')}` : ''"
                @click="() => showModalFlags.startPlanningWizard = true"
            />

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.vacations, 'Tag', 'Tage')} Urlaub`"
                :icon="renderSet.vacations.toString()"
                icon-type="text"
                icon-color="red"
                text=""
                @click="() => showModalFlags.startPlanningWizard = true"
            />

            <RichResponseButton
                title="Dienstplanung starten >"
                @click="() => showModalFlags.startPlanningWizard = true"
            />

        </template>
        <template v-else-if="renderSet.status === PlanningMode.BookingPlanning">
            <MonthCalendar
                :headline="null"
                :init-data="initData"
                style="flex-grow: 1; margin-bottom: 0.5em"
            />

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.booking.booked, 'Dienst', 'Dienste')} geplant`"
                :icon="renderSet.booking.booked.toString()"
                icon-type="text"
                :text="`Planungsziel sind ${renderSet.planning.target} Dienste`"
                @click="() => showModalFlags.worktimePreferences = true"
                :button="true"
            >
                <template v-slot:end>
                    <ProgressCircle
                        :stroke-width="15"
                        track-color="grey-tint-1"
                        stroke-color="black"
                        :value="renderSet.planning.target > 0 ? (renderSet.booking.booked / renderSet.planning.target) : 0"
                        :glow="false"
                    />
                </template>
            </RichResponseListItem>

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.availability.amount, 'verfügbarer Tag', 'verfügbare Tage')}`"
                :icon="renderSet.availability.amount.toString()"
                icon-type="text"
                icon-color="green"
                :text=" renderSet.availability.amount > 0 ? `im ${renderSet.availability.shifts.toSorted(sortShiftTypes).join(', ')}` : ''"
            />

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.vacations, 'Tag', 'Tage')} Urlaub`"
                :icon="renderSet.vacations.toString()"
                icon-type="text"
                icon-color="red"
                text=""
            />

            <RichResponseListItem
                :title="`${pluralizeUnitOnly(renderSet.planning?.jobsites?.length, 'Station', 'Stationen')} ausgewählt`"
                :icon="renderSet.planning?.jobsites?.length.toString()"
                icon-type="text"
                icon-color="grey"
                :text="`${jobsiteSummaryLabel(renderSet.planning.jobsites)} (${clientListLabel(renderSet.planning.jobsites)})`"
                @click="() => showModalFlags.jobsitePreferences = true"
                :button="true"
            />

            <CalendarModal v-model="showModalFlags.listCalendar" :initial-date="calendarMonth.begin"></CalendarModal>
            <AutopilotJobsitePreference v-model="showModalFlags.jobsitePreferences" :month-id="props.initData" />
            <AutopilotWorktimePreferences v-model="showModalFlags.worktimePreferences" :month="props.initData" />
        </template>
        <template v-else-if="renderSet.status === PlanningMode.Uncontracted">
            <span>Fehlender Arbeitsvertrag</span>
        </template>

        <StartPlanningMonth
            v-model="showModalFlags.startPlanningWizard"
            :month="props.initData"
        ></StartPlanningMonth>
    </template>
</div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import RichResponseHeadline from "./components/RichResponseHeadline.vue"
import ProgressCircle from '@/components/ProgressCircle.vue'
import date from "@/helper/datetime/date"
import {Ref, computed, ref, PropType} from "vue"
import { Jobsite, PlanningMode, PlanningStatus, ShiftType } from "@/graphql/generated/graphql";
import { clientListLabel, jobsiteSummaryLabel } from '@/helper/jobsite';
import { kapitelDateString } from '@/graphql/kapitelTypes';
import { fetchPlanningStatus } from '@/helper/autopilotStatus';
import { generateCalendarMonth } from '@/helper/calendar/calendarMonth';
import { getLabelForPlanningStatus } from '@/helper/planningMode';
import { formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import {pluralize, pluralizeUnitOnly} from '@/helper/amountFormatter';
import { sortShiftTypes } from '@/helper/shiftTypes';
import {IonButton} from "@ionic/vue";
import {DataLoader, InstantDataLoader} from "@/helper/dataLoader";
import Skeleton from "@/components/Skeleton.vue";
import Modal from "@/components/Modal.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import AutopilotJobsitePreference from "@/views/components/Planning/AutopilotJobsitePreference.vue";
import AutopilotWorktimePreferences from "@/views/components/Planning/AutopilotWorktimePreferences.vue";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";

const props = defineProps({
    initData: {
        type: String as PropType<kapitelDateString>,
        required: true
    }
})
const calendarMonth = computed(() => generateCalendarMonth(props.initData))

const emit = defineEmits(['details', 'startProcess'])

const showModalFlags = ref({
    startPlanningWizard: false,
    jobsitePreferences: false,
    worktimePreferences: false,
    listCalendar: false
})

const status : Ref<PlanningStatus|undefined> = ref(undefined);

const renderSet = computed(() => {
    const s = status.value
    const renderSet = {
        headline: `Planungsstatus ${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`,
        month: date.formatMonthFromKapitelDate(props.initData),
        status: PlanningMode.Uncontracted as PlanningMode,
        monthIsStatusLabel: '',        
        availability: {
            shifts: [] as ShiftType[],
            amount: 0
        },
        vacations: 0,
        booking: {
            booked: 0,
            jobsites: [] as Jobsite[],
            completed: false
        },
        planning: {            
            missing: 0,
            target: 0,
            targetReached: false,
            jobsites: [] as Jobsite[],
            favoriteJobsites: [] as Jobsite[],
            nonFavoriteJobsites: [] as Jobsite[],
        }
    }

    if (!s) {
        return renderSet
    }

    renderSet.status = s.planningMode
    renderSet.monthIsStatusLabel = getLabelForPlanningStatus(s, true)
    renderSet.vacations = s.vacations

    if (s.planningMode !== PlanningMode.Uncontracted) {
        renderSet.availability.shifts = s.availabilityShifts
        renderSet.availability.amount = s.availabilities

        renderSet.booking.booked = s.bookings
        renderSet.booking.jobsites = s.bookedJobsites
        renderSet.booking.completed = s.bookingCompleted
    }
        
    if (s.planningMode === PlanningMode.BookingPlanning && s.autopilotStatus) {
        const jobsites = s.autopilotStatus.autopilotPreferences.autopilotJobsites.map(ajs => ajs.jobsite)
        renderSet.planning = {            
            missing: s.autopilotStatus.missingBookings,
            target: s.autopilotStatus.bookingTarget,
            targetReached: s.autopilotStatus.bookingHoursTargetReached,
            jobsites,
            favoriteJobsites: jobsites.filter(j => j.isFavorite),
            nonFavoriteJobsites: jobsites.filter(j => !j.isFavorite),
        }
    }

    return renderSet
}) 

const loader = InstantDataLoader(
    async () => status.value = await fetchPlanningStatus(calendarMonth.value)
)

useGlobalEmitter().on('day:mutated', (date) => {
    if (calendarMonth.value.containsDate(date)) {
        loader.load()
    }
})
useGlobalEmitter().on('autopilotPreferences:mutated', (month) => {
    if (month === props.initData) {
        loader.load()
    }
})
useGlobalEmitter().on('availabilityPlanningMonth:mutated', (month) => {
    if (month === props.initData) {
        loader.load()
    }
})

</script>

<style lang="scss" scoped>
.progress-circle {
    width: 2.5em;
}

ion-button {
   margin-top: 0.5em;
}
</style>
