export class PendingPromiseHelper {
    pending : Array<Promise<any>> = []

    private removePending(p:Promise<any>) {
        this.pending.splice(
            this.pending.indexOf(p),
            1
        )
    }

    public addPending(p:Promise<any>) {
        p.finally(() => this.removePending(p))
        this.pending.push(p)
    }

    public allSettled() : Promise<Array<any>> {
        return Promise.allSettled(this.pending)
    }

    public hasPending() : boolean {
        return this.pending.length > 0;
    }
}
