/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleMaxHeight extends ModuleBase {
  prepareEnter (el) {
    this.targetState.maxHeight = el.clientHeight
    el.style.maxHeight = 0
    el.style.overflowY = 'hidden'
  }

  prepareLeave (el) {
    this.initialState.maxHeight = el.clientHeight
    el.style.maxHeight = el.clientHeight
    el.style.overflowY = 'hidden'
  }

  applyEnter (el, progress) {
    el.style.maxHeight = (progress * this.targetState.maxHeight) + 'px'
  }

  applyLeave (el, progress) {
    el.style.maxHeight = ((1 - progress) * this.initialState.maxHeight) + 'px'
  }
}
