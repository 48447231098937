<template>
    <div class="voice-response-status">
        <div
            class="voice-response-message">
            <InlineSVG
                class="icon-processing"
                v-if="isThinking"
                src="/icons/brain.svg"
            />
            <FrequencyAnimation
                v-else-if="isSpeaking"
            />
            <InlineSVG
                class="icon-standby"
                v-else
                src="/icons/chats-teardrop.svg"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, Ref, ref, watch} from "vue";
import {useChatStore} from "@/helper/chat/chatStore";
import {ChatStatus} from "@/helper/chat/chatStatus";
import InlineSVG from "@/components/InlineSVG.vue";
import FrequencyAnimation from "@/components/FrequencyAnimation.vue";
import Transitionator from "../components/transitions/Transitionator.vue";
import {TextToSpeech} from "@/helper/chat/textToSpeech";
import {useGlobalEmitter} from "@/helper/emitter";
import {audioContextPlay} from "@/helper/audioContext";
import {consoleLogChat} from "@/helper/console";
import {useSpeakingPlayer} from "@/helper/chat/voiceSpeakBL";

const chatStore = useChatStore()

const isThinking = computed(() => {
    return (
        (
            chatStore.ttsStatus === "silent" &&
            chatStore.chatStatus >= ChatStatus.CHOOSER_PENDING &&
            chatStore.chatStatus <= ChatStatus.EXPERT_RESPONDING
        ) ||
        chatStore.transcribeStatus === "transcribing"
    )
})

const isSpeaking = computed(() =>
    chatStore.ttsStatus === "speaking"
)

useSpeakingPlayer()

</script>


<style scoped lang="scss">
.voice-response-status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--custom-spacing-app-content-padding-vertical);

    .voice-response-message {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        width: 4.5rem;
        height: 4.5rem;
        background: var(--ion-color-light);
        position: relative;

        .icon-processing,
        .icon-standby {
            width: 2.25em;
            color: var(--ion-color-grey);
        }

        .icon-processing {
            animation: shake-processing 0.2s infinite linear both;
        }

        &:after {
            content: '';
            position: absolute;
            top: -0.5em;
            left: -0.5em;
            width: 1.5em;
            height: 1.5em;
            background-color: var(--ion-color-primary);
            background-image: url('/icons/logo/icon-96.webp');
            background-size: cover;
            border-radius: 50%;
        }
    }

    @keyframes shake-processing {
      10%, 100% {
        transform: translate3d(-1px, 0, 0);
      }

      20%, 80% {
        transform: translate3d(1px, 1px, 0);
      }

      30%, 50%, 70% {
        transform: translate3d(-1px, 0, 0);
      }

      40%, 60% {
        transform: translate3d(1px, -1px, 0);
      }
    }
}
</style>
