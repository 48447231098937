let maxModuleLength = 0
const padModule = (module: string) : string => {
    if (module.length > maxModuleLength) {
        maxModuleLength = module.length
    }
    return module.padEnd(maxModuleLength)
}

export function consoleLog(module: string, text: string, ...params:any[]) : void {
    console.log('%c ' + padModule(module) + '%c | ' + text, 'text-shadow:0.1em .1em grey', 'text-shadow:none', ...params);
}

export function consoleError(module: string, text: string, ...params:any[]) : void {
    console.error('%c ' + padModule(module) + '%c | ' + text, 'text-shadow:0.1em .1em grey', 'text-shadow:none', ...params);
}

export const consoleLogChat = (text:string, ...params:any[]) => consoleLog('CHAT', text, ...params);
export const consoleErrorChat = (text:string, ...params:any[]) => consoleError('CHAT', text, ...params);

export const consoleLogApp = (text:string, ...params:any[]) => consoleLog('APP', text, ...params);
export const consoleErrorApp = (text:string, ...params:any[]) => consoleError('APP', text, ...params);

