<script setup>
import { onBeforeMount, ref, watch } from 'vue'

const props = defineProps({
    src: {
        type: String,
        default: undefined,
    }
})

const svg = ref(undefined)

const fetchSVG = () => {
    if (!props.src) {
        return
    }

    fetch(props.src)
        .then(response => response.text())
        .then(result => {
            svg.value = result
        })
        .catch(() => {})
}

onBeforeMount(fetchSVG)
watch(() => props.src, fetchSVG)

</script>

<template>
    <div class="inline-svg" v-html="svg" />
</template>

<style lang="scss">
.inline-svg {
    svg {
        width: 100%;
    }
}
</style>