export function timerPromise(milliseconds: number) : Promise<void> {
    const {promise} = timerPromiseWithCancel(milliseconds)
    return promise
}

export type TimerPromiseWithCancelReturn = { promise: Promise<void>, cancel : () => void }

export function timerPromiseWithCancel(milliseconds: number) : TimerPromiseWithCancelReturn {
    let res : () => void
    let rej : () => void
    const promise : Promise<void> = new Promise((resolve, reject) => {
        res = resolve
        rej = reject
    });

    const timeoutId = setTimeout(() => {
        res()
    }, milliseconds)

    const cancel = () => {
        clearTimeout(timeoutId)
        // TODO: promise reject currently not executed
        // rej()
    }
    return {promise, cancel}
}

