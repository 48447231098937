<script setup lang="ts">
import { IonButton, IonGrid, IonCol, IonRow } from "@ionic/vue";
import InputTime from '@/components/InputTime.vue'
import { ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import { BookingInput } from "@/graphql/generated/graphql";
import BookingSummary from "@/components/BookingSummary.vue";
import datetime from "@/helper/datetime/datetime";
import { kapitelDateTimeString } from "@/graphql/kapitelTypes";

const props = defineProps<{
    booking: BookingInput | undefined;
    modelValue: boolean;
}>();

const emit = defineEmits(["update:modelValue", "update:bookingTimes"]);
const isOpen = ref(false);

const newBegin = ref<kapitelDateTimeString | undefined>(undefined);
const newUntil = ref<kapitelDateTimeString | undefined>(undefined);

watch(
    () => props.modelValue,
    (value) => {
        isOpen.value = value;
    },
);
watch(
    () => isOpen.value,
    (value) => {
        emit("update:modelValue", value);
    },
);

watch(
    () => props.booking,
    (booking) => {
        if(booking){
            newBegin.value = datetime.convertDateTime2Time(booking.begin);
            newUntil.value = datetime.convertDateTime2Time(booking.until);
        }
    },
);

const submit = () => {

    const newBooking: BookingInput = {
        begin: datetime.convertDateAndTime2DateTime(
            datetime.convertDateTime2Date(props.booking.begin),
            newBegin.value
        ),
        until: datetime.convertDateAndTime2DateTime(
            datetime.convertDateTime2Date(props.booking.until),
            newUntil.value
        ),
        jobsite: props.booking.jobsite,
        qualification: props.booking.qualification
    }
    emit("update:bookingTimes", newBooking, props.booking);

    isOpen.value = false;

};
</script>

<template>
    <Modal v-model="isOpen" title="Einsatz ändern">
        <br />
        <br />
        <ion-grid>
            <ion-row>
                <ion-col>
                    <InputTime
                        label="Anfang"
                        v-model="newBegin"
                        :nullable="false"
                    />
                </ion-col>
                <ion-col>
                    <InputTime
                        label="Ende"
                        v-model="newUntil"
                        :nullable="false"
                    />
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-button @click="submit" expand="block" color="secondary">
            Zeiten übernehmen
        </ion-button>
    </Modal>
</template>
