<template>
<div class="frequency-animation">
  <span />
  <span />
  <span />
  <span />
</div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.frequency-animation {
    width: 1.5em;
    height: 2.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
        display: inline-block;
        background: var(--ion-color-grey);
        width: 0.2em;
        height: 100%;
        animation: frequency-animation .8s ease infinite alternate;
        border-radius: 0.1em;

        &:nth-child(1) { height: 60%; animation-delay: 0s; }
        &:nth-child(2) { height: 30%; animation-delay: -2.2s; }
        &:nth-child(3) { height: 75%; animation-delay: -3.7s; }
        &:nth-child(4) { height: 45%; animation-delay: -5.0s; }
    }

    @keyframes frequency-animation {
        10% { height: 30%; }
        30% { height: 100%; }
        60% { height: 50%; }
        80% { height: 75%; }
        100% { height: 60%; }
    }
}
</style>