export type kapitelDateString = string;
export type kapitelTimeString = string;
export type kapitelDateTimeString = string;

// temporary until used by BE and auto-imported by GQLite
export enum JobsiteCategory {
    Alternative = 'ALTERNATIVE',
    Booked = 'BOOKED',
    Favorite = 'FAVORITE',
    Other = 'OTHER'
}




