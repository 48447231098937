<template>
    <ion-page class="no-padding-top">
        <ion-header :translucent="true">
            <ion-toolbar class="no-border">
<!--                <ion-button-->
<!--                    color="secondary"-->
<!--                    fill="clear"-->
<!--                    v-on:click="router.go(-1)"-->
<!--                    size="small"-->
<!--                >-->
<!--                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />-->
<!--                </ion-button>-->
<!--                <ion-title>Assistent</ion-title>-->
                <ion-buttons slot="start">
                    <ion-button
                        v-if="!chatStore.nothingHappenedYet"
                        color="secondary"
                        fill="clear"
                        @click="resetConversation()"
                    >
                        <ion-icon slot="icon-only" :src="createOutline" />
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button
                        color="secondary"
                        fill="clear"
                        v-on:click="aInesRequestStore.toggleUIState"
                        class="image-icon has-badge"
                    >
                        <img slot="icon-only" src="/logo.png" />
                        <ion-badge v-if="RS_show.ainesRequestBadge && aInesRequestStore.unhandledRequests.length">{{ aInesRequestStore.unhandledRequests.length }}</ion-badge>
                    </ion-button>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        v-on:click="calendarModalOpen = true"
                    >
                        <ion-icon slot="icon-only" src="/icons/calendar-blank.svg" />
                    </ion-button>
                    <ion-button
                        v-if="authStore.isConsultant()"
                        color="secondary"
                        fill="clear"
                        v-on:click="router.push('/employees')"
                        class="impersonation"
                    >
                        <ion-icon slot="icon-only" src="/icons/detective.svg" />
                        <ion-label>{{ employeeName }}</ion-label>
                    </ion-button>
                </ion-buttons>

            </ion-toolbar>
        </ion-header>
        <ion-content>
            <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="150">
                <AInesRequestAssistant
                    v-if="RS_show.ainesRequestSummary"
                    v-on:avatar:click="onAInesRequestAvatarClick"
                    v-on:request:click="onAInesRequestSelect"
                />
            </Transitionator>
            <div class="chat-container">
                <div class="response-container">

                    <!-- VOICE OR TEXT MODE -->
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce">
                        <AInesVoiceAvatar
                            v-if="RS_show.voiceMode"
                        />
                    </Transitionator>
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="150">
                        <div
                            v-if = "RS_show.textMode && (RS_show.messageText || RS_show.messageLoaderMessage)"
                            class="message with-avatar"
                        >
                            <p>{{ RS_assistantMessage?.textContent }}</p>
                        </div>
                    </Transitionator>

                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="10">
                        <div
                            v-if="RS_show.messageLoaderDots || RS_show.richResponseLoaderDots"
                            class="message pending"
                        >
                            <IonSpinner name="dots" />
                        </div>
                    </Transitionator>
                    <div
                        v-if="RS_show.messageRichResponses && RS_assistantMessage?.richContents"
                        v-for="richContent in RS_assistantMessage.richContents"
                        class='rich-response-container'
                    >
                        <Transitionator v-if="!!getRichComponentFor(richContent)" :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="20">
                            <component
                                :is="getRichComponentFor(richContent)"
                                :init-data="richContent.initData"
                                v-on:details="onRichResponseDetails"
                            />
<!--                                @blockprompt="onRichComponentBlockPrompt"-->
<!--                                @unblockprompt="onRichComponentUnblockPrompt"-->
<!--                                @sendmessage="onRichComponentSendMessage"-->

                        </Transitionator>
                    </div>
                </div>
            </div>
            <CalendarDetail v-if="dayDetailModalOpen" v-model="clickedDate" :date-id="clickedDate"></CalendarDetail>
            <CalendarModal
                v-model="calendarModalOpen"
                :initial-date="today"
            />
        </ion-content>
        <ion-footer class="footer-input">
            <div
                class="chat-input-container">
                <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="50">
                    <ChatInput
                        ref="chatInputRef"
                        v-if="chatStore.isTextMode"
                        v-on:text:submit="(message) => chatSendsNewUserMessage(message, undefined)"
                        v-on:text:input="onChatInputTextInput"
                    />
                </Transitionator>
                <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="50">
                    <VoiceInput
                        v-if="chatStore.isVoiceMode"
                        v-on:text:submit="(message) => chatSendsNewUserMessage(message, undefined, undefined, undefined, undefined, false)"
                    />
                </Transitionator>
                <ion-button
                    v-on:click="onToggleVoiceTextMode"
                    color="white"
                    class="floating-big-pseudo"
                >
                    <ion-icon
                        slot="icon-only"
                        color="primary"
                        :src="chatStore.isVoiceMode ? '/icons/x.svg' : '/icons/microphone.svg'"
                    />
                </ion-button>
            </div>

            <!-- only show explore as long as no message was send -->
            <div
                class="quick-access-buttons"
                :class="{'centered': chatStore.isVoiceMode}"
                v-if="RS_show.exploreQuickChats">
                <div
                    v-for="exploreQuickChat in exploreQuickChats"
                    :key="exploreQuickChat.label"
                    class="quick-access-item">
                    <div
                        class="floating-big-pseudo"
                        @click="clickExploreQuickChat(exploreQuickChat)"
                        >
                            <IonIcon :icon="exploreQuickChat.icon"></IonIcon>
                            {{ exploreQuickChat.label }}
                    </div>
                </div>
            </div>
            <div
                class="quick-access-buttons"
                :class="{'centered': chatStore.isVoiceMode}"
                v-else-if="RS_show.responseQuickChats && aInesRequestStore.selection">
                <div
                    v-for="responseQuickChat in requestResponseQuickChats"
                    :key="responseQuickChat.label"
                    class="quick-access-item">
                    <div
                        class="floating-big-pseudo"
                        @click="clickRequestResponseQuickChat(aInesRequestStore.selection, responseQuickChat)"
                        >
                            {{ responseQuickChat.label }}
                    </div>
                </div>
            </div>
        </ion-footer>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonToolbar,
    IonLabel,
} from "@ionic/vue";
import ChatInput from '@/views/Chat/ChatInput.vue'
import VoiceInput from '@/views/Chat/VoiceInput.vue'
import {computed, ComputedRef, Ref, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {
    ExploreQuickChat,
    getExploreQuickChatsMatching,
    getExploreQuickChatsTopN,
    useExploreQuickChatStore
} from "@/helper/chat/exploreQuickChats";

import {PreprocessedAssistantMessage, useChatStore} from "@/helper/chat/chatStore";
import {resetConversation, sendUserMessage, setUpAssistant} from "@/helper/chat/chatBL";
import AInesRequestAssistant from "@/views/Chat/AInesRequestAssistant.vue";
import {
    AInesRequestAssistantUIState, getRequestResponseQuickChats,
    ResponseQuickChat,
    snoozeQuickChatHandler,
    useAInesRequestStore
} from "@/helper/chat/ainesRequests";
import {AInesAssistantType, AInesRequest, AInesRichResponseType, Employee} from "@/graphql/generated/graphql";
import {getRichComponentFor, RichContent} from "@/helper/chat/richResponses";
import Transitionator from "../components/transitions/Transitionator.vue";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {requireAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useAuthStore} from "@/store/auth";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {useGlobalEmitter} from "@/helper/emitter";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";

import AInesVoiceAvatar from "@/views/Chat/AInesVoiceAvatar.vue";
import date from "@/helper/datetime/date";
import {arrowBackCircleOutline, createOutline} from "ionicons/icons";
import {useEmployeeStore} from "@/store/employee";


/*
init
 */

const chatInputRef : Ref<typeof ChatInput | undefined> = ref(undefined)

const router = useRouter();
const chatStore = useChatStore();
const authStore = useAuthStore();

const aInesRequestStore = useAInesRequestStore();
const blockButtonLabel = ref()

const onToggleVoiceTextMode = () => {
    if(chatStore.voiceOrTextMode == "text"){
        chatStore.setVoiceOrTextMode("voice")
    }else{
        chatStore.setVoiceOrTextMode("text")
    }
}


const employeeName : ComputedRef<string> = computed(() => {
    const employee = useEmployeeStore().storedEmployee

    const parts = employee?.name?.split(' ')

    if (parts && parts[0]) {
        return parts[0]
    }

    if (employee?.name) {
        return employee?.name
    }

    return '?'
})

const calendarModalOpen = ref(false)

const dayDetailModalOpen = ref(false)
const clickedDate: Ref<kapitelDateString|undefined> = ref(undefined)

useGlobalEmitter().on('notification:clicked', (dateId: kapitelDateString) => {
    console.log("clicked on notification with date: " + dateId)
    clickedDate.value = dateId
    dayDetailModalOpen.value = true
});

const chatSendsNewUserMessage = async (
    message: string,
    messageExpertAssistant: AInesAssistantType | undefined = undefined,
    prependedAssistantMessage: string|undefined = undefined,
    prependExpertAssistant: AInesAssistantType | undefined = undefined,
    richContentsPreview: RichContent[] | undefined = undefined,
    isScriptedContent: boolean = false
) : Promise<void> => {

    // TODO: centralize prepend 1
    if (!prependedAssistantMessage && aInesRequestStore.selection) {
        const request = aInesRequestStore.selection
        prependedAssistantMessage = request.message.text || undefined
        prependExpertAssistant = await requireAssistentForFeatureSet(request.featureSet)
    }

    aInesRequestReset()

    return await sendUserMessage(
        message,
        {
            messageExpertAssistant,
            prependedAssistantMessage,
            prependExpertAssistant,
            richContentsPreview,
            isScriptedContent
        }
    )
}

/*
TopSheet Handling
 */

const processes: AInesRichResponseType[] = [
    AInesRichResponseType.RegisterBookingSummary,
    AInesRichResponseType.AutopilotStartWizard,
    AInesRichResponseType.JobsiteSearch
]

const currentModalSelection : Ref<RichContent|undefined> = ref(undefined);
const processModalOpen = ref(false)
const onClickRichResponse = (content: RichContent)=>{
    if(processes.indexOf(content.type as AInesRichResponseType)>-1){
        currentModalSelection.value = content
        processModalOpen.value = true
    }
}
// --
const detailModalOpen = ref(false)
const currentDetailModalType : Ref<string|undefined> = ref(undefined);
const currentDetailModalInitData : Ref<object> = ref({});

const onRichResponseDetails = (data: object|string)  => {
    currentDetailModalType.value = data.type
    currentDetailModalInitData.value = data.initData
    detailModalOpen.value = true
}
///


/*
rendersets
 */

const today = date.getToday()

const RS_show = computed(() => {
    const s = chatStore.chatStatus

    const show = {
        voiceMode: chatStore.voiceOrTextMode == "voice",
        textMode: chatStore.voiceOrTextMode == "text",

        // default: no loader shown
        messageLoaderDots: false,
        messageLoaderMessage: false,
        richResponseLoaderDots: false,

        // default: no message content shown
        messageText: false,
        messageRichResponses: false,

        // other modules
        ainesRequestSummary: false,
        ainesRequestBadge: false,

        exploreQuickChats: false,

        responseQuickChats: false
    }

    if (aInesRequestStore.uiState === AInesRequestAssistantUIState.COMPACT) {
        show.ainesRequestSummary = true
    } else {
        show.ainesRequestBadge = true;
    }

    if (!show.ainesRequestSummary) {
        // message, loader & rich response - only if request summary not open
        if (aInesRequestStore.selection) {
            // selection always trumps regular message
            show.messageText = true
            show.messageRichResponses = true
            show.responseQuickChats = true
        } else {
            if (chatStore.currentExpertRunMessageRichContentsPreview) {
                show.messageRichResponses = true
            }

            switch (true) {
                case (s === ChatStatus.READY):
                case (s === ChatStatus.FAILED):
                    break
                case (s === ChatStatus.EXPECTING_NEXT_RESPONSE):
                case (s === ChatStatus.PREPENDING_ASSISTANT_MESSAGE):
                case (ChatStatus.isChoosing(s)):
                case (s === ChatStatus.EXPERT_SETUP):
                case (ChatStatus.isExperting(s) && s !== ChatStatus.EXPERT_RESPONDING):
                    // run in preparation, choosing, or pending -> loader dots
                    show.messageLoaderDots = chatStore.voiceOrTextMode=="text";
                    break;
                case (s === ChatStatus.EXPERT_RESPONDING):
                    // expert run pending - differentiate in reverse order: waiting, toolcalls, text streaming, tool call streaming, done
                    const recentToolCall = chatStore.currentExpertRunMostRecentToolCall
                    const recentMessageStatus = chatStore.currentExpertRunMessageStatus

                    if (recentMessageStatus === 'messageDone') { // done
                        show.messageText = true
                        show.messageRichResponses = true
                    } else if (recentMessageStatus === 'textDone') { // tool call streaming
                        show.messageText = true
                        if (!show.messageRichResponses) {
                            show.richResponseLoaderDots = true // text is done streaming, but message not - we may be waiting for RRs
                        }
                    } else if (recentMessageStatus === 'streaming') { // text streaming
                        show.messageText = true
                    } else if (recentToolCall) { // tool calls
                        show.messageLoaderMessage = true
                    } else { // waiting
                        show.messageLoaderDots = chatStore.voiceOrTextMode=="text"
                    }
                    break;
                case (s === ChatStatus.EXPERT_COMPLETE):
                    show.messageText = true
                    show.messageRichResponses = true
                    break;
                default:
                    consoleErrorChat('unhandled Chat.vue status for %o', s)
                    break;
            }
        }

        if (
            chatStore.nothingHappenedYet &&
            !aInesRequestStore.selection &&
            !show.ainesRequestSummary &&
            !chatStore.isBlocked
        ) {
            show.exploreQuickChats = true
        }
    }

    return show
})

const RS_assistantMessage: Ref<PreprocessedAssistantMessage | undefined> = computed(() => {
    // selection always trumps regular message
    if (aInesRequestStore.selection) {
        return aInesRequestStore.selectedPreprocessedAssistantMessage
    }

    // if (chatStore.currentExpertRunMessageTextStreaming) {
    if (chatStore.currentPreprocessedAssistantMessageStreaming) {
        return chatStore.currentPreprocessedAssistantMessageStreaming
    }

    if (chatStore.currentExpertRunMostRecentToolCallLoaderMessage) {
        return {
            textContent: chatStore.currentExpertRunMostRecentToolCallLoaderMessage,
            richContents: undefined
        }
    }

    return undefined
})


/**
 * chat input
 */

const chatInputText = ref('')
const onChatInputTextInput = (text: string) => {
    chatInputText.value = text
}

/*
ExploreQuickActions
 */

const exploreQuickChats = computed(() => {
    const result = chatInputText.value.trim() !== ''
        // show top 3 matches for search string
        ? getExploreQuickChatsMatching(chatInputText.value.trim(), 3)
        // show top 3 quick explores
        : getExploreQuickChatsTopN(3)

    // always reverse, showing top result at the bottom
    return result.reverse()
})

const clickExploreQuickChat = async (qc:ExploreQuickChat) => {

    const expertAssistant = await requireAssistentForFeatureSet(qc.featureSet)

    chatSendsNewUserMessage(
        qc.prompt ?? qc.label,
        expertAssistant,
        undefined,
        undefined,
        qc.richContentsPreview || undefined,
        true
    )

    useExploreQuickChatStore().trackUsage(qc)

    chatInputRef.value?.clear()
}



/*
AInesRequests & responseQuickActions
 */

// init AInesRequests
aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)

// trigger fetch for new requests
let aInesRequestInitialAutoOpened = false
const aInesRequestInit = () => {
    aInesRequestStore
        .refetch()
        .then(() => {
            // unfold into compact automatically - only if nothing happened yet
            if (
                aInesRequestStore.requests.length > 0 &&
                chatStore.nothingHappenedYet
            ) {
                aInesRequestInitialAutoOpened = true
                aInesRequestStore.setUIState(AInesRequestAssistantUIState.COMPACT)
            }
        });
}
aInesRequestInit()
useGlobalEmitter().on("AppStateChanged", ({state, impersonationUserSwitch}) => {
    if (state === 'employee' && impersonationUserSwitch) {
        aInesRequestInit()
    }
})

// close again if automatically opened
watch(() => chatStore.nothingHappenedYet, () => {
    if (aInesRequestInitialAutoOpened) {
        aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    }
})

const aInesRequestReset = () => {
    aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    aInesRequestStore.selectRequest(undefined)
}

// when user starts typing or a system message arrives
watch(() => chatStore.chatStatus, () => {
    if (!ChatStatus.isPending(chatStore.chatStatus)) {
        aInesRequestReset()
    }
})

const onAInesRequestAvatarClick = () => aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
const onAInesRequestSelect = (request: AInesRequest) => {
    aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    aInesRequestStore.selectRequest(request)
}

const requestResponseQuickChats : ComputedRef<ResponseQuickChat[]> = computed(() =>
    aInesRequestStore.selection ? getRequestResponseQuickChats(aInesRequestStore.selection) : []
)

const clickRequestResponseQuickChat = async (request: AInesRequest, response:ResponseQuickChat) => {
    if (chatStore.isBlocked) {
        return;
    }

    switch (response.type) {
        case 'suggestedReaction':
            if (response.action) {
                aInesRequestStore.respondToSelectedRequest(response.action.reactionType)
                if (response.action.prompt) {
                    // TODO: centralize prepend 2
                    const expert = await requireAssistentForFeatureSet(request.featureSet)
                    chatSendsNewUserMessage(
                        response.action.prompt,
                        expert,
                        request.message.text || undefined,
                        expert,
                        undefined,
                        true
                    )
                }
            }
            break

        case 'cancel':
            aInesRequestStore.selectRequest(undefined)
            break

        case 'snoozeButtons':
            // show "snooze" action sheet
            await snoozeQuickChatHandler()
            break
    }
}

setUpAssistant()
</script>

<style scoped lang="scss">

.chat-container{
    margin-bottom: 5em;
    width: 100%;
    position: relative;
}
.response-container {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: flex-start;
}

.chat-input-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    position: relative;

    > * {
        min-height: 3.6rem;
    }

    > .chat-text-input {
        flex: 1 1 auto;
        margin-right: 0.5rem;
        order: 1;
    }

    > ion-button {
        background-color: var(--ion-color-white);
        flex: 0 0 3.6em;
        width: 3.6rem;
        border-radius: 1em;
        margin-left: 0.5rem;
        margin-top: 0;
        margin-bottom: 0;
        order: 2;
    }
}

.rich-response-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;

    > *:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

.message {
    background-color: var(--ion-color-light);
    color: var(--ion-text-color);
    font-size: var(--custom-font-size-regular);
    line-height: 1.36em;
    margin: 0.5em 0 2em 0;
    padding: 1em;
    border-radius: 1rem;
    width: 100%;
    min-height: 3.36em;
    position: relative;
    transition-property: width;
    transition-duration: 0.25s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    white-space: pre-wrap; // respect the line breaks coming from the model

    p {
        margin: 0;
        width: 100%;
    }

    ion-spinner {
        height: 1em;
        line-height: 1em;
    }

    &.with-avatar {
        &:after {
            content: '';
            position: absolute;
            top: -0.5em;
            left: -0.5em;
            width: 1.5em;
            height: 1.5em;
            background-color: var(--ion-color-primary);
            background-image: url('/icons/logo/icon-96.webp');
            background-size: cover;
            border-radius: 50%;
        }
    }

    &.pending {
        width: 4em;
        display: inline-block;

        &:not(:first-child) {
            margin-top: -1.5em;
        }
    }


}

.system-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
}

.footer-input {
    width: 100%;
    background-color: var(--ion-color-white);
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-bottom: calc(var(--ion-safe-area-bottom) + var(--custom-spacing-app-content-padding-vertical));
    padding-top: var(--custom-spacing-app-content-padding-vertical);

    display: flex;
    flex-direction: column;

    > ion-input {
        z-index: 1;
    }
}

.quick-access-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 0.5em;
    right: var(--custom-spacing-app-content-padding-horizontal);
    left: var(--custom-spacing-app-content-padding-horizontal);
    transform: translateY(-100%);
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    > .quick-access-item {
        display: flex;
        justify-content: center;
        width: 100%;

        :deep(ion-icon) {
            font-size: 1.2em;
            display: inline-block;
            vertical-align: text-bottom;
            margin-right: 0.5rem;
        }

        &:not(:last-child) {
            margin-bottom: 0.5em;
        }

        > div {
            flex: 0 0 auto;
            display: inline-block;
            font-size: var(--custom-font-size-small);
            color: var(--ion-color-black);
            padding: 0.9em 1.5em;
            border-radius: 1rem;
            background-color: var(--ion-color-white);
        }

        &:after {
            content: '';
            flex: 1;
            transition: flex 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &:nth-child(1):after { transition-delay: 0.02s; }
        &:nth-child(2):after { transition-delay: 0.04s; }
        &:nth-child(3):after { transition-delay: 0.06s; }
        &:nth-child(4):after { transition-delay: 0.08s; }
    }

    &.centered {
        top: -0.5em;
        align-items: center;

        > .quick-access-item {
            &:after {
                flex: 0;
            }
        }
    }
}

.impersonation ion-label {
    margin-left: 0.25em;
}

</style>
