import { query, mutation } from "@/graphql/client";
import { gql } from "@urql/vue";
import { jobsiteFragment  } from "./jobsite";
import {Booking, Jobsite, RegisterBookingInput, ShiftType} from "@/graphql/generated/graphql";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import {bookingFragment} from "@/helper/booking";

export async function fetchRegisterBookingDefaults(): Promise<any> {

    const result = await query(
        gql`
            ${jobsiteFragment}
            query RegisterBookingDefaults {
                registerBookingDefaults {
                    jobsite {
                        ...JobsiteFragment
                    }
                    qualification {
                        id
                        label
                    }
                    shiftTimes {
                        type
                        begin
                        until
                    }
                }
            }
        `
    );

    return result?.data?.registerBookingDefaults;
}


export const setRegisterBookings = async (newRegisterBookings: Booking[]):Promise<{ errors:string[], successfulBookings:Booking[] }> => {
    const MUTATION = gql`
        ${bookingFragment}
        mutation SetRegisterBookings(
            $registerBookingInputs: [RegisterBookingInput!]!
        ) {
            setRegisterBookings(
                bookingInputs: $registerBookingInputs
            )
            {
                ...BookingFragment
            }
        }
    `;

    const registerBookingInputs: RegisterBookingInput[] = newRegisterBookings.map(b => {
        if(!b.jobsite.id){
            throw new Error("Jobsite id not defined")
        }

        if(!b.qualification.id){
            throw new Error("qualification id not defined")
        }

        return {
            begin: b.begin,
            until: b.until,
            jobsiteId: b.jobsite.id,
            qualificationId: b.qualification.id
        }
    })


    const result = await mutation(MUTATION, {
        registerBookingInputs: registerBookingInputs,
    }, false);

    const errors = result?.error?.graphQLErrors?.map((gqle:any) => gqle.message) || []

    const successfulBookings = result?.data?.setRegisterBookings || []

    return {successfulBookings,errors}
};


export interface shiftDate  {
    type: ShiftType,
    date: kapitelDateString
}

export const resolveJobsite = async (qString: string): Promise<Jobsite|null> => {

    const result = await query(
        gql`${jobsiteFragment}
            query ResolveJobsites (
                $q: String!
            ){
              resolvedJobsites (
                jobsiteQuery: $q
              ){
                  ...JobsiteFragment
              }
        }`,
        {
            q: qString
        }
    )

    return result?.data?.resolvedJobsites[0] ?? null
}
