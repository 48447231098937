import { JobsiteCategory } from "@/graphql/kapitelTypes"

export const sortedJobsiteCategories = [
    JobsiteCategory.Favorite,
    JobsiteCategory.Alternative,
    JobsiteCategory.Booked,
    JobsiteCategory.Other
]

export const getPrefixForJobsiteCategory = (jobsiteCategory: JobsiteCategory): string => {
    switch (jobsiteCategory) {
        case JobsiteCategory.Favorite: return'Lieblings'
        case JobsiteCategory.Alternative: return 'Alternativ'
        case JobsiteCategory.Booked: return 'Bekannte '
        case JobsiteCategory.Other: return 'Weitere '
    }
}

export const getLabelForJobsiteCategory = (jobsiteCategory: JobsiteCategory, plural = true): string => {
    const prefix = getPrefixForJobsiteCategory(jobsiteCategory)
    const jobsite = (prefix.substring(-1) === ' ') ? 'Station' : 'station'
    
    return prefix + jobsite + (plural ? 'en' : '')
}

export const getIconForJobsiteCategory = (jobsiteCategory: JobsiteCategory): string | null => {
    switch (jobsiteCategory) {
        case JobsiteCategory.Favorite: return'/icons/heart.svg'
        case JobsiteCategory.Alternative: return '/icons/plus.svg'
        case JobsiteCategory.Booked: return null
        case JobsiteCategory.Other: return null
    }
}

export enum SimplifiedJobsiteCategory {
    Favorite = 'FAVORITE',
    Additional = 'ADDITIONAL'
}
