<script setup lang="ts">
import {
    IonContent,
    IonList,
    IonItem,
} from "@ionic/vue";
import {computed} from "vue";
import TimesheetHeader from "@/views/Timesheet/components/TimesheetHeader.vue";
import {useTimesheetStore} from "@/store/timesheet";
import { getImageForJobsite } from '@/helper/jobsite'
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const timesheetStore = useTimesheetStore()

const timesheetToDoJobsites = computed(()=> store.getToDoJobsites)

const onJobsiteSelection = (jstd:any) => {
    timesheetStore.startSignatureProcess(parseInt(jstd.jobsite.id))
    props.timesheetNavigator.next()
}

</script>

<template>
    <ion-content class="no-padding-top">
        <RichResponseListItem
            v-for="jstd in timesheetToDoJobsites"
            iconType="image"
            :icon="getImageForJobsite(jstd.jobsite)"
            :title="jstd.jobsite.house?.name?.toString()"
            :text="jstd.jobsite?.name?.toString()"
            :button="true"
            @click="onJobsiteSelection(jstd)"
        />
    </ion-content>
</template>
