/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';



/* Theme variables */
import '@/theme/theme.scss';

/* store*/
import { createPinia } from 'pinia';
const pinia = createPinia()

import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
pinia.use(piniaPluginPersistedState);

/* app with store */
import { createApp } from 'vue'
import KapitelApp from './App.vue'
const app = createApp(KapitelApp)
app.use(pinia)

/* sentry */
import { useSentry } from './helper/sentry';
useSentry(app)

import { VueSignaturePad } from 'vue-signature-pad'
app.component('VueSignaturePad', VueSignaturePad)

/* ionic */
import { IonicVue } from '@ionic/vue';
app.use(IonicVue, {
    mode: 'ios',
    experimentalCloseWatcher: true, // hw back button in PWA
    swipeBackEnabled: true
})

/* router */
import router from './router';
app.use(router)

/* auth subsystem */
import {initAuth} from "@/helper/auth";
initAuth();

/* finally: mount app */
router.isReady().then(() => {
  app.mount('#app');
});

/* hardware back button */
import { useBackButton } from '@ionic/vue';
import { App as CapacitorApp } from '@capacitor/app';
import { useAppState } from './helper/appState';
const backButtonHandler = () => {
    const currentRoute = router.currentRoute.value
    if (window.history.length === 0 || currentRoute.meta.exitOnHardwareBack ) {
        CapacitorApp.exitApp();
    }
}
useBackButton(-1, backButtonHandler);

/* app active state */
import useEmitter from "@/helper/emitter";
import {consoleLogApp} from "@/helper/console";
const emitter = useEmitter()
CapacitorApp.addListener('appStateChange', ({ isActive }) => {
    consoleLogApp('CapacitorApp appStateChange, active: ', isActive)
    if (isActive) {
        emitter.emit('CapacitorAppStateChange:active')
    }else{
        emitter.emit('CapacitorAppStateChange:inactive')
    }
})

/* hand over to appState helper */
const appState = useAppState()
appState.init()
