<template>
    <RichResponseListItem
        iconType="text"
        icon="?"
        icon-blurred
        :title="initData ? 'Suche nach &quot;' + initData + '&quot;' :'Tippe hier, um deine Stationen zu verwalten.'"
        :selectable="false"
        v-on:click="onClick"
        :button="true"
    />
    <JobsiteSelectorModal ref='modal' v-model="modalValue" :search-string="initData"  v-on:select="onSelect"/>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {computed, ref, Ref} from "vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue";
import {sendUserMessage} from "@/helper/chat/chatBL";
import {Jobsite} from "@/graphql/generated/graphql";

const props = defineProps<{
    initData: string
}>()

const modal = ref()
const modalValue: Ref<Boolean> = ref(false)
const onClick = () => {
    modalValue.value = true
    modal.value.searchByNewQuery(props.initData)
}

const onSelect = (jobsite: Jobsite) => {
    sendUserMessage("Ich habe dir die jobsiteId und den Namen rausgesucht. jobsiteId: " + jobsite.id +" Name: "+ jobsite.name, {isScriptedContent:true})
}


</script>
