declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
    }
}

const init = () => {
    //@ts-nocheck
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Ensure the AudioContext is resumed on user interaction (required on iOS)
    const resumeAudioContext = () => {
        if (audioContext.state === 'suspended') {
            audioContext.resume();
        }
    }
    window.addEventListener('touchend', resumeAudioContext, false);
    window.addEventListener('click', resumeAudioContext, false);

    return audioContext
}

const audioContext = init()

export const audioContextPlay = async (buffer: ArrayBuffer, cb: () => void) => {
    try {
        const decodedData = await audioContext.decodeAudioData(buffer);
        const source = audioContext.createBufferSource();
        source.buffer = decodedData;
        source.connect(audioContext.destination);

        source.onended = cb;
        source.start();
    } catch (error) {
        console.error("Error decoding audio data", error);
        cb()
    }
}

