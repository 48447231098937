import {AInesAssistantType} from "@/graphql/generated/graphql";
import {AssistantStreamEvent} from "openai/resources/beta/assistants";
import {KapitelToolCall} from "@/helper/chat/toolCalls";
import {Run} from "openai/resources/beta/threads";
import {AssistantStream} from "openai/lib/AssistantStream";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";
import mitt, {Emitter} from 'mitt';
import {persistMessage, sendMessageAndRun, submitToolCallResponse} from "@/helper/chat/assistantAPI";
import {kapitelErrorHandler} from "@/helper/error";
import {makeMessagePersistParams} from "@/helper/chat/chatBL";
import {AssistantRunStatus} from "@/helper/chat/assistantRun/assistantRunStatus";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";

export type AssistantRunEvents = {
    stateChanged: AssistantRunStatus,
    runRequiresToolCall: KapitelToolCall
    runRequiresToolCalls: KapitelToolCall[]
    runResponseStarted: void,
    runComplete: void,
    runFailed: void
};

export abstract class AssistantRun {
    public mitt : Emitter<AssistantRunEvents>;

    protected constructor(
        public runConfig: AssistantRunConfig,
        protected userMessage: string,
        protected isScriptedContent: boolean
    ) {
        // init event bus
        this.mitt = mitt<AssistantRunEvents>();

        this.submitMessageAndStream(userMessage, isScriptedContent)
            .then((stream: AssistantStream) => {
                this.setState(AssistantRunStatus.REQUESTED);
                // start with first stream
                this.setCurrentStream(stream)
            })

        // error handling
        this.mitt.on('runFailed', () => {
            const error = 'Run ' + this.getRunId() + ' failed: ' + this.getLastError()?.code + ' - ' + this.getLastError()?.message
            // log to chat console
            consoleErrorChat(error, this)

            // handle error (toast, sentry)
            kapitelErrorHandler(error)
        })
    }

    public onUnhandledStreamError(error: Error) {
        if (!AssistantRunStatus.isTerminal(this.state)) {
            debugger
            this.setState(AssistantRunStatus.FAILED);
        }
    }


    /**
     * run handling
     */

    protected run: Run | undefined;

    public getLastError() : Run.LastError | null | undefined {
        return this.run?.last_error
    }

    public getRunId() : string | undefined {
        return this.run?.id
    }


    /*
    state handling
     */

    private state : AssistantRunStatus = AssistantRunStatus.INIT;
    public getState() {
        return this.state;
    }
    protected setState(state: AssistantRunStatus) {
        const nextState = AssistantRunStatus.transitionTo(state, this.state)
        if (nextState === this.state) {
            return
        }

        this.state = nextState
        this.mitt.emit('stateChanged', nextState)
    }


    /*
    multi stream handling
     */

    private streams : AssistantStream[] = []
    private currentStream: AssistantStream | undefined = undefined
    protected resetCurrentStream() {
        if (this.currentStream) {
            this.toggleEventListeners(this.currentStream, 'off')
            this.currentStream = undefined
        }
    }
    protected setCurrentStream(stream: AssistantStream) {
        if (this.currentStream) {
            throw new Error('already currentStream attached - reset first')
        }

        consoleLogChat('%s: receiving response stream for userMessage "' + this.userMessage + '" on %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)

        this.toggleEventListeners(stream, 'on')
        this.currentStream = stream

        // collect stream
        this.streams.push(stream)
    }


    /*
    stream sources
     */

    protected submitMessageAndStream(message: string, isScriptedContent: boolean) : Promise<AssistantStream> {
        consoleLogChat('%s: sending user message "' + message + '" to %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)
        return sendMessageAndRun(
            this.runConfig,
            message,
            isScriptedContent
        )
    }

    protected submitToolCallOutputsAndStream(toolCallOutputs: Array<{output: string, tool_call_id: string}>) : Promise<AssistantStream> {
        if (!this.run) {
            throw new Error('run not set yet - should have happened at run beginning')
        }
        consoleLogChat('%s: sending ' + toolCallOutputs.length + ' tool call output(s) to %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)
        toolCallOutputs.forEach((output) => {
            makeMessagePersistParams({
                config: this.runConfig,
                role: "tool",
                content: output.output,
                toolCallId: output.tool_call_id,
                runId: this.run?.id,
            }).then(persistMessage)
        })

        return submitToolCallResponse(
            toolCallOutputs,
            this.runConfig,
            this.run.id
        )
    }


    /*
    stream event management: attaches & detaches local stream event listeners

    private event handler bound to this by using =>
    referenced by on & off event management
     */

    protected toggleEventListeners(stream: AssistantStream, toggle: 'on'|'off') {
        stream[toggle]('event', this.onGenericRunEvent)
    }

    private onGenericRunEvent = async (event: AssistantStreamEvent) => {
        // first: update internal run-state by run parameter
        switch (event.event) {
            case "thread.run.created":
            case "thread.run.queued":
            case "thread.run.in_progress":
            case "thread.run.cancelling":
            case "thread.run.requires_action":
            case "thread.run.completed":
            case "thread.run.incomplete":
            case "thread.run.failed":
            case "thread.run.cancelled":
            case "thread.run.expired":
                // update internal run state
                this.run = event.data as Run
                break;
        }

        // second: adjust internal status & handle required_action (tool call)
        switch (event.event){
            case "thread.run.created":
                // response stream started: emit event
                this.mitt.emit('runResponseStarted')
                //state: progress state from INIT > RUNNING
                this.setState(AssistantRunStatus.RUNNING)
                break;
            // unhandled thread.run events
            // case "thread.run.queued":
            // case "thread.run.in_progress":
            // case "thread.run.cancelling":
            // case "thread.run.requires_action":
            //     break;
            case "thread.run.completed":
                // state: on content complete progress state from streaming > completed
                this.setState(AssistantRunStatus.COMPLETED)
                // streamComplete: event
                this.mitt.emit('runComplete')
                break;
            case "thread.run.incomplete":
            case "thread.run.failed":
            case "thread.run.cancelled":
            case "thread.run.expired":
                // state: on content complete progress state from streaming > failed
                this.setState(AssistantRunStatus.FAILED)
                // streamComplete: event
                this.mitt.emit('runFailed')
                break;
        }
    }
}
