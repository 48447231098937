<script setup lang="ts">
import { IonButton, IonCard, IonCardContent } from '@ionic/vue';
import {ref, computed, watch, PropType} from 'vue';
import { fetchDaysForDateRange, yieldEffectiveAbsences, yieldEffectiveAvailabilities, yieldEffectiveBookings } from "@/helper/day";
import Modal from '@/components/Modal.vue'
import date from "@/helper/datetime/date";
import {sendUserMessage} from "@/helper/chat/chatBL.js";
import Day from "@/views/Calendar/Day.vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";

const showModal = defineModel({type: Boolean})
const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    days: {
        type: Array as PropType<Day[] | undefined>,
        required: false,
        default: undefined
    }
})

const modal = ref()

const emit = defineEmits(['confirm'])

const bookings = computed(() => yieldEffectiveBookings(props.days));
const absences = computed(() => yieldEffectiveAbsences(props.days));
const availabilities = computed(() => yieldEffectiveAvailabilities(props.days));
const monthFormatted = computed(() => date.formatKapitelDate(date.endOfMonth(props.month), 'MMMM'))

const summary = computed(() => {
    return [
        {
            label: 'Tage verfügbar',
            value: availabilities.value.length || 0,
        },
        /* {
            label: 'Tage sind abweichend von der Vorlage',
            value: availabilities.value.filter(av => av.differsFromTemplate).length || 0,
        }, */
        {
            label: 'Tage abwesend',
            value: absences.value.length || 0,
        },
        {
            label: 'Tage bereits geplant',
            value: bookings.value.length || 0,
        }
    ]
})

// const fetchDays = () => {
//     fetchDaysForDateRange(props.beginDate, props.endDate).then(res => {
//         bookings.value = yieldEffectiveBookings(res)
//         absences.value = yieldEffectiveAbsences(res)
//         availabilities.value = yieldEffectiveAvailabilities(res)
//     })
// }
if (!props.days) {
    console.error('not yet implemented')
}

const onPressCancel = () => {
    showModal.value = false
}

const onPressConfirm = () => {
    modal.value.dismiss().then(() => {
        emit('confirm')
    })
}
</script>

<template>
<Modal
    v-model="showModal"
    title="Dienstplanung starten"
    ref="modal"
>

    <span>
        Hiermit werden die Verfügbarkeiten für den Monat {{ monthFormatted }} verbindlich kommuniziert und die Dienstplanung gestartet.
    </span>

    <ion-card
        class="custom-ion-card-light planning-summary-card"
    >
        <ion-card-content>
            <div
                class="summary"
            >
                <div
                    v-for="item in summary"
                >
                    <div
                        class="value text-extralarge text-extrabold"
                    >
                        {{ item.value }}
                    </div>
                    <div
                        class="label"
                    >
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </ion-card-content>
    </ion-card>
    <template v-slot:actions>
        <ion-button
            @click="onPressConfirm"
            expand="block"
            color="primary"
        >
            Dienstplanung für {{ monthFormatted }} starten
        </ion-button>
        <ion-button
            @click="onPressCancel"
            fill="clear"
            color="secondary"
            expand="block"
        >
            Abbrechen
        </ion-button>
    </template>
</Modal>
</template>

<style lang="scss">
.planning-summary-card {
    .summary {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1em;

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            > .value {
                color: var(--ion-color-dark);
            }

            > .label {
                color: var(--ion-color-medium);
                margin-top: 0.5em;
                margin-bottom: 1rem;
                text-align: center;
            }
        }
    }
}
</style>
