<script lang="ts" setup>
import {IonButton, IonItem} from '@ionic/vue';

const props = defineProps<{
    title?: string
}>()

const emit = defineEmits(['click'])
</script>

<template>
    <ion-button
        expand="block"
        color="primary"
        class="floating"
        @click="emit('click')"
    >
        {{ title }}
    </ion-button>
</template>

<style lang="scss" scoped>
ion-button {
    margin-top: 0.5em;
}
</style>
