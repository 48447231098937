<script setup lang="ts">
type allowedAttributes = 'height' | 'width'
export type SkeletonItem = 'line' | 'title' | 'title-big' | 'margin' | 'button' | 'chip' | 'block' | `block--${allowedAttributes}-${string}` | `line--${allowedAttributes}-${string}`

import {computed, PropType} from 'vue';
import {Jobsite} from "@/graphql/generated/graphql.js";

const props = defineProps({
    items: {
        type: Array as PropType<SkeletonItem[]>,
        required: true,
        default: () => []
    }
})

const partDelimiter = '--'
const attributeValueDelimiter = '-'
const allowedAttributes = ['height', 'width']

const parsedItems = computed(() => props.items.map(parseItem))

const parseItem = item => item.split(partDelimiter).reduce((acc, part, index) => {
    if (index === 0) {
        acc.type = part
        return acc
    }

    allowedAttributes.forEach(attr => {
        const i = part.indexOf(attr)

        if (i === -1) {
            return
        }

        acc[attr] = part.slice(i + attr.length + attributeValueDelimiter.length)
    })

    return acc
}, {})
</script>
<template>
<div
    class="skeleton"
>
    <div
        v-for="(item,index) in parsedItems"
        :class="{[item.type]: true}"
        :style="{'height': item.height, 'width': item.width}"
        :key="index"
    />
</div>
</template>

<style lang="scss">
.skeleton {
    width: 100%;
    // padding-left: var(--custom-spacing-app-content-padding-horizontal);
    // padding-right: var(--custom-spacing-app-content-padding-horizontal);

    > div {
        width: 100%;
        background-color: var(--ion-color-grey-tint-2);
        border-radius: 1rem;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.5rem;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#ccc, 0) 0,
                rgba(#ccc, 0.2) 20%,
                rgba(#ccc, 0.5) 60%,
                rgba(#ccc, 0)
            );
            animation: shimmer 1s infinite;
            content: '';
        }

        @keyframes shimmer {
            100% {
             transform: translateX(100%);
            }
        }

        &.line {
            height: 2rem;
        }

        &.block {
            height: 6rem;
        }

        &.title {
            height: 2rem;
            width: 75%;
        }

        &.title-big {
            width: 50%;
            height: 4rem;
        }

        &.margin {
            height: 2rem;
            visibility: hidden;
        }

        &.button {
            height: 3rem;
        }

        &.chip {
            height: 2rem;
            border-radius: 1rem;
            width: 5rem;
        }
    }

}
</style>
