<script setup lang="ts">
import {AutopilotStatus, Availability, Employee, PlanningStatus, WorktimeMode} from "@/graphql/generated/graphql";
import {CalendarMonth} from "@/helper/calendar/calendarMonth";
import {fetchPlanningStatus, getAutopilotStatusSummary} from "@/helper/autopilotStatus";
import {computed, PropType, Ref, ref} from "vue";
import {
    IonChip, IonIcon, IonLabel
} from '@ionic/vue'
import {
    airplaneOutline,
    checkmarkOutline, homeOutline, logOutOutline,
    pauseCircleOutline,
    removeCircleOutline, scanOutline, stopCircleOutline
} from "ionicons/icons";
import InlineSVG from "@/components/InlineSVG.vue";
import {fetchDaysForDateRange, yieldEffectiveAbsences, yieldEffectiveAvailabilities} from "@/helper/day";
import {DataLoader} from "@/helper/dataLoader";

const props = defineProps({
    calendarMonth: {
        type: Object as PropType<CalendarMonth>,
        required: true,
    },
    employee: {
        type: Object as PropType<Employee>,
        required: true,
    },
    autopilotStatus: {
        type: Object as PropType<AutopilotStatus>,
        required: false
    },
    monthLabelFormat: {
        type: String,
        required: false,
        default: 'MMM'
    },
})

const autopilotStatusSummary = computed(() => props.autopilotStatus ? getAutopilotStatusSummary(props.autopilotStatus) : undefined)

const monthLabel = computed(() => props.monthLabelFormat ? props.calendarMonth.format(props.monthLabelFormat) : '')

const planningStatus : Ref<PlanningStatus | undefined> = ref(undefined)
const loader = new DataLoader(async () => {
    planningStatus.value = await fetchPlanningStatus(props.calendarMonth, props.employee)
})
loader.load()

</script>

<template>
    <IonChip v-if="!autopilotStatusSummary" color="dark">
        <!-- no autopilot status -->
        <IonLabel>{{monthLabel}}</IonLabel>

        <IonLabel>
            <IonIcon :icon="stopCircleOutline" ></IonIcon>
        </IonLabel>

        <IonLabel v-if="planningStatus">
            <IonIcon :icon="scanOutline"></IonIcon>
            {{ planningStatus?.availabilities }}v
        </IonLabel>

        <IonLabel v-if="planningStatus && planningStatus.vacations > 0">
            <IonIcon :icon="airplaneOutline"></IonIcon>
            {{ planningStatus?.vacations }}u
        </IonLabel>

    </IonChip>

    <IonChip v-else-if="autopilotStatusSummary && !autopilotStatusSummary.statusIsRunning" color="error">
        <!-- autopillot paused -->
        <IonLabel>{{monthLabel}}</IonLabel>
        <IonIcon :icon="pauseCircleOutline"></IonIcon>
    </IonChip>

    <IonChip v-else-if="autopilotStatus && autopilotStatus.bookingHoursTargetReached" color="success">
        <!-- autopillot finished -->
        <IonLabel>{{monthLabel}}</IonLabel>
        <IonIcon :icon="checkmarkOutline"></IonIcon>
        <IonLabel>{{autopilotStatus.booked}}</IonLabel>
    </IonChip>

    <IonChip
        v-else-if="autopilotStatus && !autopilotStatus.bookingHoursTargetReached"
        :color="autopilotStatus.bookedWorkingHours / autopilotStatus.bookingTargetHours >= 0.8 ? 'warning' : 'danger'"
    >
        <!-- autopilot running -->

        <IonLabel>{{monthLabel}}</IonLabel>
        <IonLabel>
            <InlineSVG
                :src="autopilotStatusSummary.worktimeIcon"
            />
            -{{autopilotStatus.missingBookings}}d
        </IonLabel>

<!--        <IonLabel>-->
<!--            {{autopilotStatus.bookingTarget}}d-->
<!--        </IonLabel>-->

        <IonLabel v-if="planningStatus">
            <IonIcon :icon="scanOutline"></IonIcon>
            {{ planningStatus?.availabilities }}v
        </IonLabel>

        <IonLabel v-if="planningStatus && planningStatus.vacations > 0">
            <IonIcon :icon="airplaneOutline"></IonIcon>
            {{ planningStatus?.vacations }}u
        </IonLabel>

        <IonLabel>
            <IonIcon :icon="homeOutline"></IonIcon>
            {{ autopilotStatusSummary.jobsiteSummary.automaticallyAddedCount }}+{{autopilotStatusSummary.jobsiteSummary.manuallyAddedCount}}
        </IonLabel>

    </IonChip>
</template>

<style scoped lang="scss">

    :deep(ion-label) {
        margin: 0 0.3em 0 0 ;
    }

    :deep(ion-label) ion-icon {
        display: inline-block;
        vertical-align: bottom;
    }

    :deep(.inline-svg) svg {
        vertical-align: bottom;
    }
    :deep(.inline-svg) {
        width: 1em;
        display: inline-block
    }

</style>
