const isDevelopment = () : boolean => {
    return import.meta.env.DEV
}

const isProduction = () : boolean => {
    return import.meta.env.PROD
}

const getEnvironment = () : string => {
    if (isDevelopment()) {
        return 'development'
    } else if (isProduction()) {
        return 'production'
    } 
    return 'unknown'
}

export const env = {
    isDevelopment,
    isProduction,
    getEnvironment
}
