import { kapitelDateString } from '@/graphql/kapitelTypes';
import date, {
    addDays,
    cmpKapitelDate,
    formatKapitelDate,
    formatMonthFromKapitelDate,
    formatWeekdayFromKapitelDate,
    formatWeekdayShortFromKapitelDate,
    getDayOfMonthFromKapitelDate,
    getISODayOfWeekFromKapitelDate,
    getMonthFromKapitelDate,
    getToday,
    getYearFromKapitelDate,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isMonday,
    isTomorrow,
    isWeekend,
    isYesterday,
} from '../datetime/date';

export type CalendarDateSingleton = ReturnType<typeof getCalendarDateSingleton>

const dates = new Map()

export const getCalendarDateSingleton = (dateString: kapitelDateString) => {
    const id = 'date_' + dateString
    if (dates.has(id)) {
        return dates.get(id)
    } else {
        const date = {
            id,
            type: 'date',
            date: dateString
        }
        dates.set(id, date)
        return date
    }
}

export type CalendarMonthSingleton = ReturnType<typeof getCalendarMonthSingleton>

const months = new Map()

export const getCalendarMonthSingleton = (dateString: kapitelDateString) => {
    const id = 'month_' + dateString
    if (months.has(id)) {
        return months.get(id)
    } else {
        const month = {
            id: 'month_' + dateString,
            type: 'month',
            month: dateString,
        }
        months.set(id, month)
        return month
    }
}

export type CalendarItemSingleton = CalendarMonthSingleton | CalendarDateSingleton

export const getCalendarDatesSingleton = (startDateString: kapitelDateString, endDateString: kapitelDateString, addMonths = false): CalendarItemSingleton[] => {
    const result = []

    let iterator = addDays(startDateString, 0);

    while (cmpKapitelDate(endDateString, iterator)>=0) {
        const calendarDate = getCalendarDateSingleton(iterator)

        if (addMonths && date.isFirstDayOfMonth(iterator)) {
            const month = getCalendarMonthSingleton(iterator)
            result.push(month)
        }

        result.push(calendarDate)
        iterator = addDays(iterator, 1)
    }

    return result
};
