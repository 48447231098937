<script setup lang="ts">
import { IonButton, IonRow, IonCol, IonTextarea, IonChip, IonIcon, IonGrid } from '@ionic/vue';
import Modal from '@/components/Modal.vue'
import InputTime from '@/components/InputTime.vue'
import {ref, watch, computed, PropType} from 'vue'
import time from "@/helper/datetime/time"
import TextWithIcon from '@/components/TextWithIcon.vue'
import datetime from '@/helper/datetime/datetime'
// import type { AvailabilityTimeConstraint } from '@/graphql/generated/graphql';

import { kapitelTimeString } from '@/graphql/kapitelTypes';
import InlineSVG from '@/components/InlineSVG.vue';
import {Availability} from "@/graphql/generated/graphql";
import {availabilityShiftsAndTimeConstraintsToIntervals} from "@/helper/availability";
import {convertDateTime2Time} from "@/helper/datetime/datetime";

type TimeConstraintInterface = {
    begin: kapitelTimeString,
    until: kapitelTimeString,
    comment: string
}

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    timeConstraint: Object as PropType<TimeConstraintInterface | undefined>,
    containingAvailability: {
        type: Object as PropType<Availability | undefined>,
        required: false,
        default: undefined
    },
})

/**
 * Modal v-model pass through
 */
const isOpen = ref(false)
const emit = defineEmits(['update:modelValue', 'add:timeConstraint', 'update:timeConstraint', 'remove:timeConstraint'])
watch(() => props.modelValue, value => {
    isOpen.value = value
})
watch(() => isOpen.value, value => {
    emit('update:modelValue', value)
})

/**
 * default values
 */

const availabilityHullTimes = computed(() => {
    if (!props.containingAvailability) {
        return undefined
    }
    const intervals = availabilityShiftsAndTimeConstraintsToIntervals(props.containingAvailability.shifts, [])
    if (intervals.length === 0) {
        return undefined
    }
    return {
        begin: intervals[0].begin,
        until: intervals[intervals.length-1].until
    }
})


/**
 * regular data
 */
const valueBegin = ref(undefined)
const valueUntil = ref(undefined)
const valueComment = ref(undefined)

const validationBothValuesSet = () => {
  if(!valueBegin.value || !valueUntil.value){
    return false
  }
  return true
}

const validSettings = () => {
  if(validationBothValuesSet()){
    return time.isAfter(valueUntil.value, valueBegin.value)
  }
}

const validationErrorMessage = () => {
    if (validSettings()) {
        return ''
    }
    return validationBothValuesSet()
        ? "Das Ende muss nach dem Begin liegen"
        : "Bitte Start- und Endzeit angeben."
}

const updateValues = () => {
    const defaultDateTimes = availabilityHullTimes.value
    const defaultTimes = {
        begin: defaultDateTimes?.begin ? convertDateTime2Time(defaultDateTimes?.begin) : undefined,
        until: defaultDateTimes?.until ? convertDateTime2Time(defaultDateTimes?.until) : undefined,
    }

    valueBegin.value = props.timeConstraint?.begin || defaultTimes?.begin
    valueUntil.value = props.timeConstraint?.until || defaultTimes?.until
    valueComment.value = props.timeConstraint?.comment
}

watch(() => props.timeConstraint, updateValues, {immediate: true})
watch(() => availabilityHullTimes, updateValues, {immediate: true})

const save = () => {
    const value = props.timeConstraint
        ? props.timeConstraint
        : {
            begin: undefined,
            until: undefined,
            comment: undefined
        }

    if(!props.containingAvailability||!props.containingAvailability.date){
        // template
        value.begin = valueBegin.value
        value.until = valueUntil.value
        value.comment = valueComment.value

        emit('update:timeConstraint', value)
        isOpen.value = false
        return

    }

    value.begin = datetime.convertDateAndTime2DateTime(props.containingAvailability.date, valueBegin.value);
    value.until = datetime.convertDateAndTime2DateTime(props.containingAvailability.date, valueUntil.value);
    if (datetime.isAfter(value.begin, value.until)) {
        value.until = datetime.addDays(value.until, 1);
    }

    value.comment = valueComment.value

    emit('add:timeConstraint', value)
    isOpen.value = false
}

const remove = () => {
    emit('remove:timeConstraint', props.timeConstraint)
    isOpen.value = false
}
</script>

<template>
    <Modal
        v-model="isOpen"
        :title="(props.timeConstraint ? 'Einschränkung bearbeiten' : 'Neue Einschränkung')"
    >
        <ion-grid>
            <ion-row>
                <ion-col>
                    <p class="text-bold">
                        Ich kann nicht zwischen...
                    </p>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <InputTime
                        label="Anfang"
                        v-model="valueBegin"
                        :nullable="false"
                    />
                </ion-col>
                <ion-col>
                    <InputTime
                        label="Ende"
                        v-model="valueUntil"
                        :nullable="false"
                    />
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-textarea
                        label="Grund (optional)"
                        v-model="valueComment"
                        label-placement="stacked"
                        :auto-grow="true"
                    />
                </ion-col>
            </ion-row>
            <ion-row style="height: 4em /*prevent jumping between button & error message*/">
                <ion-col v-if="validSettings() && props.timeConstraint">
                    <ion-button
                        @click="remove"
                        expand="block"
                        fill="clear"
                        color="primary"
                    >
                        Löschen
                    </ion-button>
                </ion-col>
                <ion-col v-if="validSettings()">
                    <ion-button
                        @click="save"
                        expand="block"
                        :disabled="!validSettings()"
                        color="secondary"
                    >
                        {{ props.timeConstraint ? 'Speichern' : 'Hinzufügen' }}
                    </ion-button>
                </ion-col>
                <ion-col v-if="!validSettings()">
                    <TextWithIcon
                        v-if="!validSettings()"
                        :small="true"
                        :warning="!validSettings()"
                        :success="validSettings()"
                    >
                        <template
                            #icons
                        >
                            <InlineSVG
                                v-if="!validSettings()"
                                src="/icons/info.svg"
                            />
                            <InlineSVG
                                v-else
                                src="/icons/check-circle.svg"
                            />
                        </template>
                        <p>
                            {{ validationErrorMessage() }}
                        </p>
                    </TextWithIcon>
                </ion-col>
            </ion-row>
        </ion-grid>
    </Modal>
</template>
