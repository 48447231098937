import type {
    Absence,
    AbsenceType,
    Availability,
    AvailabilityShiftInput,
    AvailabilityTimeConstraintInput,
    Day,
} from "@/graphql/generated/graphql";

import { dayFragment } from "./day";
import { mutation } from "@/graphql/client";
import { gql } from "@urql/vue";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import useEmitter, {useGlobalEmitter} from "@/helper/emitter";

export async function setAvailabilityAbsence(
    availability: Availability | null,
    absence: Absence | null,
    additionalFields:string = ''
): Promise<Day> {

    const MUTATION = gql`
        ${dayFragment}
        mutation setAvailabilityAbsence(
            $dateDings: KapitelDateImmutable!
            $shiftsDings: [AvailabilityShiftInput!]
            $constraintsDings: [AvailabilityTimeConstraintInput!]
            $excludedJobsiteIdsDings: [Int!]
            $typeDings: AbsenceType
            $worktimeValuationDings: Float
            $commentDings: String
        ) {
            setAvailabilityAbsence(
                date: $dateDings
                availabilityShifts: $shiftsDings
                availabilityConstraints: $constraintsDings
                availabilityExcludedJobsiteIds: $excludedJobsiteIdsDings
                absenceType: $typeDings
                absenceWorktimeValuation: $worktimeValuationDings
                absenceComment: $commentDings
                useStuffing: false
            ){
                ...DayFragment
                ${additionalFields}
            }
        }
    `;

    // Availability vars
    let shiftsDings: Array<AvailabilityShiftInput> | null = null;
    let constraintsDings: Array<AvailabilityTimeConstraintInput> | null = null;
    let excludedJobsiteIdsDings: Array<number> | undefined | null = null;

    // Absence vars
    let typeDings: AbsenceType | null = null;
    let worktimeValuationDings: number | undefined | null = null;
    let commentDings: string | undefined | null = null;

    let dateDings: kapitelDateString|undefined = undefined


    if (availability) {
        dateDings = availability.date
        shiftsDings = availability.shifts
        constraintsDings = availability.constraints
        excludedJobsiteIdsDings = availability.excludedJobsites
            ? availability.excludedJobsites.map((j) => j.id)
            : undefined;
    }

    if (absence) {
        dateDings = absence.date
        typeDings = absence.type
        worktimeValuationDings = absence.worktimeValuation
        commentDings = absence.comment
    }

    const result = await mutation(MUTATION, {
        dateDings: dateDings,
        shiftsDings: shiftsDings,
        constraintsDings: constraintsDings,
        excludedJobsiteIdsDings: excludedJobsiteIdsDings,

        typeDings: typeDings,
        worktimeValuationDings: worktimeValuationDings,
        commentDings: commentDings,
    });

    useGlobalEmitter().emit('day:mutated', dateDings as kapitelDateString)

    const success = result?.data?.setAvailabilityAbsence;

    if (!success) {
        throw new Error("error while storing availability and absence");
    }

    return success;
}
