import { PushNotifications, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications'
import useEmitter from "@/helper/emitter";
import {Capacitor} from "@capacitor/core";
import { RouteParamsRaw } from 'vue-router';


export interface NotificationType {
  title: string;
  body: string;
  view: string;
  props: RouteParamsRaw;
}

const emitter = useEmitter()

export const registerToPushNotifications = async () => {
  if (isAvailable()) {
    try {
        await PushNotifications.requestPermissions()
        await PushNotifications.register()
    } catch (e) {
        // console.error('error while registerToPushNotifications', e)
    }
  }
}


const onPushNotificationActionPerformed = (notificationAction: ActionPerformed) => {
    const viewName = JSON.parse(notificationAction.notification.data.view)
    const viewProps = JSON.parse(notificationAction.notification.data.props)
    const title: string = notificationAction.notification.title ? notificationAction.notification.title : ""
    const body: string = notificationAction.notification.body ? notificationAction.notification.body : ""

    const routerParams = {
        dateId: viewProps.dateId
    }
    emitter.emit('pushNotification:actionPerformed', {
        title: title,
        body: body,
        view: viewName,
        props: routerParams
    })
}


const onNotificationReceived = (notification: PushNotificationSchema) => {
    const viewName = JSON.parse(notification.data.view)
    const viewProps = JSON.parse(notification.data.props)
    const title: string = notification.title ? notification.title : ""
    const body: string = notification.body ? notification.body : ""

    const routerParams = {
        dateId: viewProps.dateId
    }
    emitter.emit('pushNotification:received', {
        title: title,
        body: body,
        view: viewName,
        props: routerParams
    })
}

let initialized = false;
const init = () => {
    if (isAvailable()) {
        PushNotifications.addListener('pushNotificationActionPerformed', onPushNotificationActionPerformed);
        PushNotifications.addListener('pushNotificationReceived', onNotificationReceived);
    }
}

const isAvailable = () => {
    return Capacitor.isPluginAvailable('PushNotifications');
}

export default function usePushNotificationHelper() {
    if (!initialized) {
        init()
        initialized = true
    }

    return {
        isAvailable,
        registerToPushNotifications,
        onPushNotificationActionPerformed: (f:(notification:NotificationType)=>void) => emitter.on('pushNotification:actionPerformed', f),
        onNotificationReceived: (f:(notification:NotificationType)=>void) => emitter.on('pushNotification:received', f),
    };
}
