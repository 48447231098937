<template>
    <RichResponseListItem
        v-for="jobsite in jobsites"
        iconType="image"
        :icon="getImageForJobsite(jobsite)"
        :title="jobsite.abbreviation?.toString()"
        :text="jobsite.client?.abbreviation?.toString()"
    />
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import { getImageForJobsite } from '@/helper/jobsite'
import { ref } from "vue";
import {AutopilotJobsite, AutopilotStatus, Jobsite} from "@/graphql/generated/graphql";
import { RichResponseEmits } from './RichResponseComposable'
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {fetchAutopilotStatus} from "@/helper/autopilotStatus";
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import  {useEmployeeStore} from "@/store/employee";

const props = defineProps<{
    initData: kapitelDateString
}>()

const emit = defineEmits(Object.values(RichResponseEmits))
const jobsites = ref<Jobsite[]>([])


const fetchJobsites = (month: kapitelDateString) => {
    const employeeStore = useEmployeeStore()
    return fetchAutopilotStatus(
        generateCalendarMonth(month),
        employeeStore?.storedEmployee
    ).then(
        (res: AutopilotStatus) =>
        {
            jobsites.value = res.autopilotPreferences.autopilotJobsites.map((aj:AutopilotJobsite) => aj.jobsite)
        }
    )
}

fetchJobsites(props.initData)
</script>
