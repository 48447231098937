<template>
  <transition
    name="transition"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:after-enter="afterEnter"
    v-on:enter-cancelled="enterCancelled"
    v-on:before-leave="beforeLeave"
    v-on:leave="leave"
    v-on:after-leave="afterLeave"
    v-on:leave-cancelled="leaveCancelled">
    <slot />
  </transition>
</template>

<script>
  import Animation from '@/views/mixins/Animation'

  import ModuleHeight from './modules/TransitionatorModuleHeight'
  import ModuleMaxHeight from './modules/TransitionatorModuleMaxHeight'
  import ModuleMaxWidth from './modules/TransitionatorModuleMaxWidth'
  import ModuleTranslate from './modules/TransitionatorModuleTranslate'
  import ModuleOpacity from './modules/TransitionatorModuleOpacity'
  import ModuleScale from './modules/TransitionatorModuleScale'
  import ModuleDisplay from './modules/TransitionatorModuleDisplay'
  import ModuleMarginTop from './modules/TransitionatorModuleMarginTop'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Transitionator',

    mixins: [
      Animation
    ],

    props: {
      // See modules for possible options
      types: {
        type: [Array, Function],
        default: () => []
      },

      moduleOptions: {
        type: Object,
        default: () => {}
      },

      preventTypesOnEnter: {
        type: [Array, Function],
        default: () => []
      },

      preventTypesOnLeave: {
        type: [Array, Function],
        default: () => []
      },

      durationEnter: {
        type: Number,
        default: 250
      },

      durationLeave: {
        type: Number,
        default: 250
      },

      delayEnter: {
        type: Number,
        default: 0
      },

      delayLeave: {
        type: Number,
        default: 0
      },

      easing: {
        type: String,
        default: 'cubicBezier'
      },

      easingEnter: {
        type: String,
        default: void 0
      },

      easingLeave: {
        type: String,
        default: void 0
      },

      skip: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        modules: {
          'height': new ModuleHeight(),
          'maxheight': new ModuleMaxHeight(),
          'maxwidth': new ModuleMaxWidth(),
          'translate': new ModuleTranslate(),
          'opacity': new ModuleOpacity(),
          'scale': new ModuleScale(),
          'display': new ModuleDisplay(),
          'margintop': new ModuleMarginTop()
        },

        beforeStyle: void 0,
        animation: void 0
      }
    },

    watch: {
      moduleOptions () {
        this.updateModuleOptions()
      }
    },

    created () {
      this.updateModuleOptions()
    },

    methods: {
      getTypes () {
        return typeof this.types === 'function'
          ? this.types(this.$vnode.key)
          : this.types
      },

      getTypesForEnter () {
        const types = this.getTypes()
        const prevented = typeof this.preventTypesOnEnter === 'function'
          ? this.preventTypesOnEnter()
          : this.preventTypesOnEnter

        return prevented && prevented.length
          ? types.filter(type => !prevented.includes(type))
          : types
      },

      getTypesForLeave () {
        const types = this.getTypes()
        const prevented = typeof this.preventTypesOnLeave === 'function'
          ? this.preventTypesOnLeave()
          : this.preventTypesOnLeave

        return prevented && prevented.length
          ? types.filter(type => !prevented.includes(type))
          : types
      },

      updateModuleOptions () {
        this.moduleOptions
          ? Object.keys(this.moduleOptions).forEach(key => {
            this.modules[key].setOptions(this.moduleOptions[key])
          })
          : void 0
      },

      // //////////////////////////////////////////////////
      // VUE HOOKS
      // //////////////////////////////////////////////////

      beforeEnter () {},

      enter (el, done) {
        if (this.skip) {
          done()
          return
        }

        this.prepareEnter(el)
        this.animateEnter(el, done)
      },

      afterEnter (el) {
        this.cleanUp(el)
        this.$emit('after-enter')
      },

      enterCancelled (el) {
        this.cleanUp(el)
        this.$emit('enter-cancelled')
      },

      beforeLeave () {},

      leave (el, done) {
        if (this.skip) {
          done()
          return
        }

        this.prepareLeave(el)
        this.animateLeave(el, done)
      },

      afterLeave (el) {
        this.cleanUp(el)
        this.$emit('after-leave')
      },

      leaveCancelled (el) {
        this.cleanUp(el)
        this.$emit('leave-cancelled')
      },

      // //////////////////////////////////////////////////
      // PREPARATION
      // //////////////////////////////////////////////////

      prepareEnter (el) {
        this.beforeStyle = el.getAttribute('style')

        this.getTypesForEnter().forEach(type => {
          const module = this.modules[type]

          if (!module) {
            return
          }

          module.prepareEnter(el)
          module.applyEnter(el, 0)
        })
      },

      prepareLeave (el) {
        this.beforeStyle = el.getAttribute('style')

        this.getTypesForLeave().forEach(type => {
          const module = this.modules[type]

          if (!module) {
            return
          }

          module.prepareLeave(el)
          module.applyLeave(el, 0)
        })
      },

      cleanUp (el) {
        this.animation
          ? this.stopEase(this.animation)
          : void 0

        this.beforeStyle
          ? el.setAttribute('style', this.beforeStyle)
          : el.removeAttribute('style')

        this.beforeStyle = {}
        this.animation = void 0

        this.getTypes().forEach(type => {
          const module = this.modules[type]

          module
            ? module.cleanUp()
            : void 0
        })
      },

      // //////////////////////////////////////////////////
      // ANIMATION
      // //////////////////////////////////////////////////

      animateEnter (el, done) {
        const types = this.getTypesForEnter()

        this.animation = this.ease(this.durationEnter, progress => {
          types.forEach(type => {
            const module = this.modules[type]

            module
              ? module.applyEnter(el, progress)
              : void 0
          })
        }, {
          delay: this.delayEnter,
          easing: this.easingEnter || this.easing,
          onEnd: done
        })
      },

      animateLeave (el, done) {
        const types = this.getTypesForLeave()

        this.animation = this.ease(this.durationLeave, progress => {
          types.forEach(type => {
            const module = this.modules[type]

            module
              ? module.applyLeave(el, progress)
              : void 0
          })
        }, {
          delay: this.delayLeave,
          easing: this.easingLeave || this.easing,
          onEnd: done
        })
      }
    }
  }
</script>
