<script setup>
import { computed } from 'vue'

const props = defineProps({
    value: {
        type: Number,
        default: 0.33
    },
    strokeWidth: {
        type: Number,
        default: 12
    },
    strokeColor: {
        type: String,
        default: 'primary'
    },
    glow: {
        type: Boolean,
        default: true
    },
    trackColor: {
        type: String,
        default: 'light'
    },
})

const baseRadius = 50
const radiusWithStroke = computed(() => baseRadius - props.strokeWidth / 2)
const circumference = computed(() => 2 * Math.PI * radiusWithStroke.value)
const offset = computed(() => circumference.value * (1 - props.value))
const trackColor = computed(() => `--ion-color-${props.trackColor}`)
const strokeColor = computed(() => `--ion-color-${props.strokeColor}`)
</script>

<template>
    <div
        class="progress-circle"
        :class="{'glow': props.glow}"
    >
        <div class="content"><slot /></div>
        <svg
            :viewBox="`0 0 ${baseRadius * 2.5} ${baseRadius * 2.5}`"
        >
            <circle
                :style="`--progress-circle-track-color: var(${trackColor})`"
                class="circle-track"
                :stroke-width="props.strokeWidth"
                fill="transparent"
                :r="radiusWithStroke"
                :cx="baseRadius * 1.25"
                :cy="baseRadius * 1.25"
            />
            <circle
                :style="`--progress-circle-stroke-color: var(${strokeColor}); --progress-circle-stroke-color-rgb: var(${strokeColor}-rgb)`"
                class="circle-value"
                :stroke-width="props.strokeWidth"
                :stroke-dasharray="`${circumference} ${circumference}`"
                :stroke-dashoffset="offset"
                fill="transparent"
                :r="radiusWithStroke"
                :cx="baseRadius * 1.25"
                :cy="baseRadius * 1.25"
            />
        </svg>
    </div>
</template>

<style lang="scss">
@use '@/theme/includes/svg';

.progress-circle {
    width: 4rem;
    position: relative;

    &:before{
        content: "";
        display: block;
        padding-top: 100%;
    }

    .content {
        $SIZE: 80%;
        position: absolute;
        border-radius: 50%;
        width: $SIZE;
        height: $SIZE;
        top: calc((100% - $SIZE) / 2);
        left: calc((100% - $SIZE) / 2);
        display: flex;
        align-items: center;
        justify-content: center ;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        circle {
            transition: 0.35s stroke-dashoffset;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;

            &.circle-track {
                stroke: var(--progress-circle-track-color);
            }

            &.circle-value {
                stroke: var(--progress-circle-stroke-color);
            }
        }
    }

    &.glow {
        svg {
            circle {
                &.circle-value {
                    // should be a mixin
                    filter: drop-shadow( 0 0 0.4rem rgba(var(--progress-circle-stroke-color-rgb), 0.8));
                }
            }
        }
    }
}
</style>
