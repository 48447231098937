import { mutation, query } from "@/graphql/client";
import { AvailabilityPlanningDates, AvailabilityPlanningDatesInput } from "@/graphql/generated/graphql";
import { gql } from "@urql/vue";
import useEmitter, {useGlobalEmitter} from "@/helper/emitter";
import {kapitelErrorHandler} from "@/helper/error";
import {kapitelDateString} from "@/graphql/kapitelTypes";

const emitter = useEmitter()

const fragment = gql`fragment Fragment on AvailabilityPlanningDates {
    planningThreshold,
    daysBeforeAutoPlan,
    defaulted,
    firstUnplannedDay
}`

export async function fetchAvailabilityPlanningDates(): Promise<AvailabilityPlanningDates> {
    const result = await query(gql`
        ${fragment}
        query GetAvailabilityPlanningDates{
              availabilityPlanningDates {
                  ...Fragment
              }
        }`
    )

    return result?.data?.availabilityPlanningDates
}

export async function setAvailabilityPlanningDates(planningDates: AvailabilityPlanningDatesInput): Promise<AvailabilityPlanningDates | undefined> {

    const MUTATION = gql`
            mutation SetAvailabilityPlanningDates (
                $planningDatesDings: AvailabilityPlanningDatesInput!
            ) {
                setAvailabilityPlanningDates(
                    newAvailabilityPlanningDates: $planningDatesDings
                    useStuffing: false
                )
                {
                    planningThreshold
                    daysBeforeAutoPlan
                }
            }`

    const result = await mutation(
        MUTATION, {
            planningDatesDings: planningDates,
        }
    )

    const response = result?.data?.setAvailabilityPlanningDates
    if (!response) {
        kapitelErrorHandler('Fehler beim Speichern')
        return
    }

    useGlobalEmitter().emit('availabilityPlanningDates:mutated', response)
    useGlobalEmitter().emit('availabilityPlanningMonth:mutated', response.planningThreshold)

    return response
}

export async function fetchAvailabilityPlanningDate(): Promise<kapitelDateString> {
    return (await fetchAvailabilityPlanningDates()).planningThreshold
}

export async function setAvailabilityPlanningDate(planningThreshold: kapitelDateString): Promise<kapitelDateString | undefined> {

    const MUTATION = gql`
        mutation SetAvailabilityPlanningDate (
            $planningThresholdDings: KapitelDateImmutable!
        ) {
            setAvailabilityPlanningDate(
                planningThreshold: $planningThresholdDings
            ) {
                planningThreshold
            }
        }`

    const result = await mutation(
        MUTATION, {
            planningThresholdDings: planningThreshold,
        }
    )

    const response = result?.data?.setAvailabilityPlanningDate
    if (!response) {
        kapitelErrorHandler('Fehler beim Speichern')
        return
    }

    useGlobalEmitter().emit('availabilityPlanningMonth:mutated', response.planningThreshold)

    return response.planningThreshold
}
