<script setup lang="ts">
import { ref } from 'vue'

const isFlying = ref(false)
const onClick = () => {
    isFlying.value = true
}

const possibleColors = ['primary', 'secondary', 'success', 'warning']
const color = possibleColors[Math.floor(Math.random() * possibleColors.length)]

</script>

<template>
<div class="flower grow spin" :class="{'flying': isFlying, ['color-' + color]: true}" @click="onClick">
    <svg
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none"/>
        <path d="M111.71,100.45C103.81,85.56,96,67.85,96,56a32,32,0,0,1,64,0c0,11.85-7.81,29.56-15.71,44.45" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <path d="M143.71,100.12c8.94-14.29,20.38-29.91,30.64-35.83a32,32,0,1,1,32,55.42c-10.26,5.93-29.5,8-46.35,8.62" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <path d="M160,127.67c16.85.6,36.09,2.69,46.35,8.62a32,32,0,1,1-32,55.42c-10.26-5.92-21.7-21.54-30.64-35.83" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <path d="M144.29,155.55C152.19,170.44,160,188.15,160,200a32,32,0,0,1-64,0c0-11.85,7.81-29.56,15.71-44.45" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <path d="M112.29,155.88c-8.94,14.29-20.38,29.91-30.64,35.83a32,32,0,1,1-32-55.42c10.26-5.93,29.5-8,46.35-8.62" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <path d="M96,128.33c-16.85-.6-36.09-2.69-46.35-8.62a32,32,0,1,1,32-55.42c10.26,5.92,21.7,21.54,30.64,35.83" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
        <circle cx="128" cy="128" r="32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
    </svg>
</div>
</template>

<style lang="scss" scoped>
.flower {
    width: 5em;
    height: 5em;
    position: relative;
    color: var(--ion-color-primary);

    &.color-primary { color: var(--ion-color-primary); }
    &.color-secondary { color: var(--ion-color-secondary); }
    &.color-success { color: var(--ion-color-success); }
    &.color-warning { color: var(--ion-color-warning); }

    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: inherit;

        circle,
        path {
            transform-origin: 50% 50%;
            fill: var(--ion-color-white);
        }
    }

    &.grow {
        > svg {
            $GROW_BASE_DELAY: 0.1s;
            $GROW_LEAF_DELAY: 0.1s;

            circle,
            path {
                transform: scale(0);
                opacity: 0;
                animation-name: flower-grow;
                animation-duration: 0.25s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-delay: $GROW_BASE_DELAY;
            }

            path:nth-child(2) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 1); }
            path:nth-child(3) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 2); }
            path:nth-child(4) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 3); }
            path:nth-child(5) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 4); }
            path:nth-child(6) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 5); }
            path:nth-child(7) { animation-delay: $GROW_BASE_DELAY + ($GROW_LEAF_DELAY * 6); }
        }
    }

    &.spin {
        animation: flower-bounce 0.45s 0.85s forwards;

        > svg {
            animation: flower-spin 4s linear infinite;
        }
    }

    &.flying {
        svg {
            path {
                transition-property: transform;
                transition-duration: 0.55s;
            }

            path:nth-child(2) { transform: translateY(-100vh); }
        }
    }

    @keyframes flower-grow {
        0%   { transform: scale(0.0); opacity: 0; }
        90%  { transform: scale(1.1); opacity: 1; }
        100% { transform: scale(1.0); opacity: 1; }
    }

    @keyframes flower-bounce {
        0% { transform: scale(1.0); }
        50% { transform: scale(1.2); }
        1000% { transform: scale(1.0); }
    }

    @keyframes flower-spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }
}
</style>