import {useAuthStore} from "@/store/auth";
import {kapitelErrorHandler} from "@/helper/error";

export const fetchWithAuth = async (url: string, body: BodyInit | null, method: string = 'GET', headers: object = {}) => {
    const authStore = useAuthStore();

    if (!authStore.hasToken()) {
        throw Error("No employee token found.")
    }

    const authHeaders = {
        "Authorization": "Bearer " + authStore.getToken(),
        "X-Switch-User": authStore.isImpersonating() ? authStore.getImpersonationUsername() : ""
    }

    try {
        return await fetch(url, {
            method,
            headers: {
                ...authHeaders,
                ...headers
            },
            body
        });
    } catch (error: any) {
        kapitelErrorHandler(error)
        throw error;
    }
}
