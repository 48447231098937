import {kapitelTimeString} from "@/graphql/kapitelTypes";

// HH:mm
const timeRE = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
export const validateKapitelTime = (timeString:kapitelTimeString) => {
  if (!timeRE.test(timeString)) {
      throw new Error("malformed kapitelTimeString")
  }
}

const compareComparableObjects = (o1: any, o2: any): number  => {
  if(o1 < o2){
    return -1
  } else if (o2 < o1){
    return 1
  }
  return 0
}

export const isAfter = (string1: kapitelTimeString, string2: kapitelTimeString): boolean =>
{
  return cmpKapitelTime(string1, string2) == 1
}

export const cmpKapitelTime = (string1: kapitelTimeString, string2: kapitelTimeString) => {
  // Returns -1 if string1 is less than string2; 1 if string1 is greater than string2, and 0 if they are equal.
  validateKapitelTime(string1)
  validateKapitelTime(string2)
  const minutes1 = kapitelTimeToMinutes(string1)
  const minutes2 = kapitelTimeToMinutes(string2)

  return compareComparableObjects(minutes1, minutes2)
}


export const kapitelTimeToMinutes = (timeStr: kapitelTimeString): number => {
  validateKapitelTime(timeStr)
  const [hours, minutes] = timeStr.split(":").map(Number)

  return hours*60 + minutes
}

export const parseMinutes = (totalMinutes: number): kapitelTimeString => {

    const hours = Math.floor(totalMinutes / 60) % 24
    const minutes = totalMinutes % 60

    return parseHoursAndMinutes(hours, minutes)

}

export const parseHoursAndMinutes = (hours: number, minutes: number): kapitelTimeString => {
    return (
        hours.toString().padStart(2, '0')
        + ":"
        + minutes.toString().padStart(2, '0')
    );
}


export const extractValuesFromTimeString = (timeString: kapitelTimeString, delimiter = ':') => {
  validateKapitelTime(timeString)
    const values = (timeString || '').split(delimiter)

    return {
        hours: parseInt(values[0]),
        minutes: parseInt(values[1])
    }
}


export default {
  cmpKapitelTime,
  extractValuesFromTimeString,
  isAfter,
  kapitelTimeToMinutes,
  parseMinutes,
  parseHoursAndMinutes,
  validateKapitelTime
}
