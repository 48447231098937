const listener : { (error: Error|string): void; } [] = [];

export function onKapitelError(callback:{(error: any):void;}) {
    listener.push(callback)
}

export function kapitelErrorHandler (error : any) {
    listener.forEach((callback) => callback(error))
}

// internal listener: console.error
onKapitelError((error) => {
    console.error(error)
})

//@ts-ignore
window.ERROR_HANDLER_TEST = (s:any = 'test error for error handler') => { kapitelErrorHandler(s) }



