import {kapitelDateString, kapitelDateTimeString, kapitelTimeString} from "@/graphql/kapitelTypes";
import {DurationObjectUnits, DurationUnits, DateTime as LuxonDateTime, Interval as LuxonInterval} from "luxon";
import date from "./date";
import time from "./time";


export const kapitelDateTimeFormatDefault = "DD t";
export const kapitelDateTimeFormatTime = "HH:mm";
export const kapitelDateTimeFormatDate = "DD t";

// private
const kapitelDateTimeStringToLuxon = (
  dateTimeString: kapitelDateTimeString,
): LuxonDateTime => {
  // use only when really necessary
  return LuxonDateTime.fromISO(dateTimeString);
};


export const getNow = (): kapitelDateTimeString => {
  return dateTimeTokapitelDateTimeString(LuxonDateTime.now().setZone(date.allEmployeeTimezone))
}

export const isBefore = (string1: kapitelDateTimeString, string2: kapitelDateTimeString): boolean =>
{
  return cmpKapitelDateTime(string1, string2) == -1
}

export const isAfter = (string1: kapitelDateTimeString, string2: kapitelDateTimeString): boolean =>
{
  return cmpKapitelDateTime(string1, string2) == 1
}

export const isSame  = (string1: kapitelDateTimeString, string2: kapitelDateTimeString): boolean =>
{
  return cmpKapitelDateTime(string1, string2) == 0
}

export const startOfDay = (dateTimeString: kapitelDateTimeString, overwriteWithEmployeeTimezone=false): kapitelDateTimeString =>
{
  let dateTime = kapitelDateTimeStringToLuxon(dateTimeString)
  if(overwriteWithEmployeeTimezone){
    dateTime = dateTime.setZone(date.allEmployeeTimezone)
  }
  return dateTimeTokapitelDateTimeString(dateTime.startOf("day"))

}
export const endOfDay = (dateTimeString: kapitelDateTimeString, overwriteWithEmployeeTimezone=false): kapitelDateTimeString =>
{
  let dateTime = kapitelDateTimeStringToLuxon(dateTimeString)
  if(overwriteWithEmployeeTimezone){
    dateTime = dateTime.setZone(date.allEmployeeTimezone)
  }
  return dateTimeTokapitelDateTimeString(dateTime.endOf("day"))

}


export const cmpKapitelDateTime = (string1: kapitelDateTimeString, string2: kapitelDateTimeString) => {
  // Returns -1 if string1 is less than string2; 1 if string1 is greater than string2, and 0 if they are equal.

  const datetime1 = LuxonDateTime.fromISO(string1)
  const datetime2 = LuxonDateTime.fromISO(string2)

  if(datetime1>datetime2){
    return 1
  } else if (datetime1 < datetime2){
    return -1
  }
  return 0
}

export const addDays = (
  dateString: kapitelDateTimeString,
  amount: number,
): kapitelDateTimeString => {
  const dateTime = kapitelDateTimeStringToLuxon(dateString);
  const nextDayDateTime = dateTime.plus({ days: amount })
  return dateTimeTokapitelDateTimeString(nextDayDateTime)
};

export const addHours = (
  dateString: kapitelDateTimeString,
  amount: number,
): kapitelDateTimeString => {
  const dateTime = kapitelDateTimeStringToLuxon(dateString);
  const resultDateTime = dateTime.plus({ hours: amount })
  return dateTimeTokapitelDateTimeString(resultDateTime)
};

export const addMinutes = (
    dateString: kapitelDateTimeString,
    amount: number,
): kapitelDateTimeString => {
    const dateTime = kapitelDateTimeStringToLuxon(dateString);
    const resultDateTime = dateTime.plus({ minutes: amount })
    return dateTimeTokapitelDateTimeString(resultDateTime)
};

const dateTimeTokapitelDateTimeString = (dateTime: LuxonDateTime) : kapitelDateTimeString => {
  const isoString = dateTime.startOf('second').toISO({suppressMilliseconds: true})
  if (!isoString) {
    throw new Error("invalid iso string")
  }
  return isoString;
}

export const formatKapitelDateTime = (
  dateTimeString: kapitelDateTimeString,
  format = kapitelDateTimeFormatDefault,
): string => {
  const dateTime = kapitelDateTimeStringToLuxon(dateTimeString);
  return dateTime
    .setLocale(date.allEmployeeLocale)
    .setZone(date.allEmployeeTimezone)
    .toFormat(format);
};


export const getDateTimeDiffInHours = (begin: kapitelDateTimeString, until: kapitelDateTimeString): number=>
{
    const beginDateTime = kapitelDateTimeStringToLuxon(begin)
    const untilDateTime = kapitelDateTimeStringToLuxon(until)

    const result = untilDateTime.diff(beginDateTime).as("hours")
    if(!result){
        return 0
    }
    return result

}

export const getDateTimeDiffInMinutes = (begin: kapitelDateTimeString, until: kapitelDateTimeString): number=>
{
    const beginDateTime = kapitelDateTimeStringToLuxon(begin)
    const untilDateTime = kapitelDateTimeStringToLuxon(until)

    const result = untilDateTime.diff(beginDateTime).as("minutes")
    if(!result){
        return 0
    }
    return result

}


export const convertDateAndTime2DateTime = (dateString: kapitelDateString, timeString: kapitelTimeString): kapitelDateTimeString => {

  date.validateKapitelDate(dateString)
  time.validateKapitelTime(timeString)

  const dateTime =  LuxonDateTime.fromISO(dateString + 'T' + timeString, {zone: date.allEmployeeTimezone})
  if (!dateTime || !dateTime.isValid) {
    throw new Error("invalid dateTime")
  }

  return dateTimeTokapitelDateTimeString(dateTime)
}


export const convertDateTime2Date = (dateTimeString: kapitelDateTimeString) : kapitelDateString =>
{
  const dateTime = kapitelDateTimeStringToLuxon(dateTimeString)
  const isoDate = dateTime.toISODate()
  if(isoDate){
    return isoDate
  }
  throw "conversion impossible"
}


export const convertDateTime2Time = (dateTimeString: kapitelDateTimeString) : kapitelTimeString =>
{
  const dateTimeUTC = kapitelDateTimeStringToLuxon(dateTimeString)
  const dateTime = dateTimeUTC.setZone(date.allEmployeeTimezone)
  const hours = dateTime.hour
  const minutes = dateTime.minute
  return (
    hours.toString().padStart(2, '0')
    + ":"
    + minutes.toString().padStart(2, '0')
  )
}

const mergeIntervals = (inputIntervals: Array<{begin: kapitelDateTimeString, until: kapitelDateTimeString}>): Array<LuxonInterval>=>
{
  return LuxonInterval.merge(
    inputIntervals.map((kapitelDateTimeStringInterval : {begin: kapitelDateTimeString, until: kapitelDateTimeString}) => {

        const beginDateTime = kapitelDateTimeStringToLuxon(kapitelDateTimeStringInterval.begin)
        let untilDateTime = kapitelDateTimeStringToLuxon(kapitelDateTimeStringInterval.until)

        if (untilDateTime<beginDateTime){
            untilDateTime = untilDateTime.plus({days:1})
        }

        return LuxonInterval.fromDateTimes(
            beginDateTime,
            untilDateTime,
        )
    })
  )
}


export const mergeAndDiffIntervals = (
  positiveIntervals: Array<{begin: kapitelDateTimeString, until: kapitelDateTimeString}>,
  negativeIntervals: Array<{begin: kapitelDateTimeString, until: kapitelDateTimeString}>
):Array<{begin:kapitelDateTimeString, until: kapitelDateTimeString}> =>
{

  const mergedPositiveIntervals = mergeIntervals(positiveIntervals)
  const mergedNegativeIntervals = mergeIntervals(negativeIntervals)

  let result : LuxonInterval[] = [];

  mergedPositiveIntervals.forEach((interval: LuxonInterval) => (
      result = result.concat(
          interval.difference(
              ...mergedNegativeIntervals
          )
      )
  ))

  return result.map((interval: LuxonInterval) => {
    if(!interval.start||!interval.end){
      throw new Error("invalid interval")
    }
    return {
      begin: dateTimeTokapitelDateTimeString(interval.start),
      until: dateTimeTokapitelDateTimeString(interval.end)
      }
  } )
}


export default {
  addDays,
  addHours,
  addMinutes,
  cmpKapitelDateTime,
  convertDateAndTime2DateTime,
  convertDateTime2Date,
  convertDateTime2Time,
  endOfDay,
  formatKapitelDateTime,
  getDateTimeDiffInHours,
  getDateTimeDiffInMinutes,
  getNow,
  isAfter,
  isBefore,
  isSame,
  kapitelDateTimeFormatDate,
  kapitelDateTimeFormatDefault,
  kapitelDateTimeFormatTime,
  mergeAndDiffIntervals,
  startOfDay
}
