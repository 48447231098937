
import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Booking, ShiftType, Contact, Availability} from "@/graphql/generated/graphql";
import {Maybe} from "graphql/jsutils/Maybe";
import {addressFragment, clientFragment, contactFragment, houseFragment, jobsiteFragment} from "@/helper/jobsite";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import datetime  from "@/helper/datetime/datetime";
import date from "@/helper/datetime/date";

export const bookingFragment = gql`fragment BookingFragment on Booking {
    id
    begin
    until
    shiftType
}`

export const bookingDetailFragment = gql`
    ${jobsiteFragment}
    ${clientFragment}
    ${houseFragment}
    ${addressFragment}
    ${contactFragment}
    fragment BookingDetailFragment on Booking {
    id
    date
    begin
    until
    workingHours
    jobsite {
        ...JobsiteFragment
        client {
            ...ClientFragment
            address {
                ...AddressFragment
            }
        }
        house {
            ...HouseFragment
            address {
                ...AddressFragment
            }
        }
        contact {
            ...ContactFragment
        }
    }
    qualification {
        label
    }
    shiftInformation{
        comment,
        requestedBy {
            ...ContactFragment
        }
    }
    bookedBy {
        ...ContactFragment
    }
    shiftType
}`

export const isShift = (
    booking: Booking,
    typeNeedle: ShiftType
): boolean => booking.shiftType === typeNeedle

export async function fetchBookings(): Promise<any>
{
    const result = await query(
        gql`${bookingDetailFragment}
            query GetBookings {
            bookings {
                ...BookingDetailFragment
            }
        }`)
    return result?.data?.bookings
}

export async function fetchUpcomingBooking(): Promise<Booking | undefined>
{
    const result = await query(
        gql`${bookingDetailFragment}
            query GetUpcomingBooking {
            upcomingBooking {
                ...BookingDetailFragment
            }
        }`)
    return result?.data?.upcomingBooking
}

export async function fetchBookingsForDateRange(begin: kapitelDateString, until: kapitelDateString): Promise<Array<Booking>>
{
    const result = await query(
        gql`${bookingFragment}
            query GetBookings (
            $min: KapitelDateImmutable!
            $max: KapitelDateImmutable!
        ) {
            bookings(betweenDates: {max: $max, min: $min}) {
                ...BookingFragment
            }
        }`, {
            min: begin,
            max: until
        })

    const bookings = result?.data?.bookings;
    if (!bookings) {
        throw new Error("no bookings in booking response")
    }

    return bookings
}


export const getAllContacts = (booking: Booking): object =>{
    return {
        bookedBy: booking.bookedBy,
        requestedBy: booking.shiftInformation?.requestedBy,
        jobsite: booking.jobsite?.contact
    }
}

export function getBookingDuration(booking: Booking) : number {
    console.warn("needs to be implemented")
    return 0;
}


export function getBookingSummary(bookings:Booking[]) {
    const shifts = bookings.length
    const hours = bookings.reduce((sum, booking) => sum + booking.workingHours, 0)
    const hoursRounded = Math.round(hours * 10) / 10
    const hoursLabel = hoursRounded.toLocaleString('de-DE')

    return {
        shifts,
        hours,
        hoursRounded,
        hoursLabel
    }
}

export function getBookingSummaryString(booking: Booking | undefined) : string {
    if (!booking) {
        return ''
    }
    
    return [
        booking.date
            ? date.formatKapitelDate(booking.date, 'd. L. yy')
            : date.formatKapitelDate(datetime.convertDateTime2Date(booking.begin), 'd. L. yy'),
        booking.shiftType,
        `${datetime.convertDateTime2Time(booking.begin)} - ${datetime.convertDateTime2Time(booking.until)}`
    ].join(' ')
}

export function cmpBookingBeginDateTime(b1: Booking, b2: Booking) {
    return datetime.cmpKapitelDateTime(b1.begin, b2.begin)
}
