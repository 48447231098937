/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleMaxWidth extends ModuleBase {
  prepareEnter (el) {
    this.targetState.maxWidth = el.clientWidth
    el.style.maxWidth = 0
    el.style.overflowX = 'hidden'
  }

  prepareLeave (el) {
    this.initialState.maxWidth = el.clientWidth
    el.style.maxWidth = el.clientWidth
    el.style.overflowX = 'hidden'
  }

  applyEnter (el, progress) {
    el.style.maxWidth = (progress * this.targetState.maxWidth) + 'px'
  }

  applyLeave (el, progress) {
    el.style.maxWidth = ((1 - progress) * this.initialState.maxWidth) + 'px'
  }
}
