import {tts} from "@/helper/chat/assistantAPI";
import {EventEmitter} from "events";


const concatenateUint8Arrays = (chunks: Uint8Array[]) => {
    let totalLength = chunks.reduce((acc, chunk) => acc + chunk.length, 0);
    let result = new Uint8Array(totalLength);
    let offset = 0;
    for (let chunk of chunks) {
        result.set(chunk, offset);
        offset += chunk.length;
    }
    return result;
};


export class TextToSpeech {

    public ttsChunks: Uint8Array[] = []
    public ttsPromise : Promise<Uint8Array>

    constructor(
        public content: string
    ) {
        this.content = content

        this.ttsPromise = new Promise((resolve, reject) => {

            // console.log("starting tts for: " + this.content)
            tts(this.content)
                .then(async (response: Response) => {
                    if (!response.body) {
                        reject()
                        throw Error("TTS Response incomplete.")
                    }

                    const reader: ReadableStreamDefaultReader = response.body.getReader()
                    this.ttsChunks = []

                    // Read the stream in chunks and push them to the array
                    let done = false;
                    while (!done) {
                        const {value, done: readerDone} = await reader.read();
                        if (value) {
                            this.ttsChunks.push(value);
                        }
                        done = readerDone;
                    }
                    // console.log("tts done for: " + this.content)

                    const result = concatenateUint8Arrays(this.ttsChunks);
                    resolve(result)
                })

        })


    }
}
