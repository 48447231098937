import {AssistantStream} from "openai/lib/AssistantStream";
import {Text} from "openai/resources/beta/threads/messages";
import {AInesAssistantType, AInesFeatureSet} from "@/graphql/generated/graphql";

import {getAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {AssistantRun} from "@/helper/chat/assistantRun/assistantRun";
import {consoleLogChat} from "@/helper/console";

import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";


export class AssistantRunChooser extends AssistantRun {

    private choicePromiseResolve: any
    public assistantChoicePromise : Promise<AInesAssistantType|undefined>

    public response : string|object|undefined

    constructor(
        public runConfig: AssistantRunConfig,
        public userMessage: string,
        protected isScriptedContent: boolean
    ) {
        super(runConfig, userMessage, isScriptedContent)

        // prepare choice promise
        this.assistantChoicePromise = new Promise((resolve, reject) => {
            this.choicePromiseResolve = resolve;
            // choicePromiseReject = reject;
        });
    }

    /*
    stream event management: attaches & detaches local stream event listeners

    private event handler bound to this by using =>
    referenced by on & off event management
     */

    protected toggleEventListeners(stream: AssistantStream, toggle: 'on'|'off') {
        super.toggleEventListeners(stream, toggle)

        stream[toggle]('textDone', this.onTextDone)
    }

    private onTextDone = async (content: Text) => {
        // resolve choice promise
        let message
        try {
            message = JSON.parse(content.value)
        } catch {
            message = undefined
        }
        this.response = message || content

        consoleLogChat('%s: response stream completed for userMessage "' + this.userMessage + '" from %O: %O',AInesAssistantType[this.runConfig.assistantType],  this.runConfig, this.response)

        const featureSet = (message && message.chosenFeatureSet && AInesFeatureSet.hasOwnProperty(message.chosenFeatureSet as string)) ? (message.chosenFeatureSet as AInesFeatureSet) : undefined

        const assistant = featureSet ? await getAssistentForFeatureSet(featureSet) : undefined

        this.choicePromiseResolve(assistant)
    }
}
