import {useGlobalEmitter} from "@/helper/emitter";
import {TextToSpeech} from "@/helper/chat/textToSpeech";
import {ref, watch} from "vue";
import {consoleLogChat} from "@/helper/console";
import {audioContextPlay} from "@/helper/audioContext";
import {useChatStore} from "@/helper/chat/chatStore";


// * Voice Player * //

const isPlaying = ref(false);

let processing = false
const processTTSObject = async (ttsObject: TextToSpeech) => {
    if (processing) {
        throw new Error('shouldn\'t happen')
    }

    processing = true

    const chatStore = useChatStore()

    const audioData = await ttsObject.ttsPromise

    consoleLogChat('Speaking: %o', ttsObject.content)
    isPlaying.value = true;
    chatStore.setTtsStatus("speaking");

    await audioContextPlay(audioData.buffer, () => {
        consoleLogChat('Speaking finished')
        isPlaying.value = false;
        processing = false
        chatStore.setTtsStatus("silent");
        checkForQueuedTTSObjects();
    })

};

const checkForQueuedTTSObjects = async () => {
    const chatStore = useChatStore()

    if (processing) {
        // console.log("already processing for playing.");
        return
    }

    const ttsObject = chatStore.pullTtsObject()
    if (!ttsObject) {
        // console.log("No new TTS to play");
        return
    }

    if (!chatStore.isVoiceMode) {
        // drop
        return
    }
    processTTSObject(ttsObject);
};

/** init **/

let initialized = false

const init = () => {
    const chatStore = useChatStore()

    useGlobalEmitter().on('VoiceModeNewTextMessage', (value) => {
        // console.log("Create new TTS object for '" + value + "'");
        const ttsObject = new TextToSpeech(value);
        chatStore.pushTtsObjects(ttsObject);
        checkForQueuedTTSObjects();
    })

    watch(() => chatStore.voiceOrTextMode, (mode) => {
        if (mode !== 'voice') {
            // clear queue on voice mode exit
            chatStore.resetTtsObjects()
        }
    })
}

export const useSpeakingPlayer = () => {
    if (initialized) {
        return
    }

    init()
    initialized = true
}
