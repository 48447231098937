<script setup lang="ts">
import {IonItem, IonLabel, IonList} from '@ionic/vue';
import Modal from '@/components/Modal.vue'
import {ref, watch} from "vue";
import {TimesheetSummary} from "@/helper/timesheet";

const props = defineProps<{
    modelValue: boolean,
    timesheetToDoSummaries: TimesheetSummary[]
}>()

/**
 * Modal v-model pass through
 */
const isOpen = ref(false)
const emit = defineEmits(['update:modelValue'])

watch(() => props.modelValue, value => {
    isOpen.value = value
})
watch(() => isOpen.value, value => {
    emit('update:modelValue', value)
})

</script>

<template>
    <Modal
        v-model="isOpen"
        title="Zu signierende Dienste"
    >
        <ion-list
            class="custom-ion-card-light"
            >

            <ion-item
                v-for="timesheetToDo in props.timesheetToDoSummaries"
                :key="timesheetToDo.date"
            >
                <ion-label >
                    <h3 :style="timesheetToDo.modified?'color: var(--ion-color-danger);':'color: var(--ion-color-success);'">{{ timesheetToDo.date }} {{ timesheetToDo.modified ? "(Angepasst)": "" }}</h3>
                    <p :style="timesheetToDo.modified?'color: var(--ion-color-danger);':'color: var(--ion-color-success);'">
                    {{ timesheetToDo.begin }} - {{ timesheetToDo.until }} --
                    {{timesheetToDo.breakBegin.length>0?"Pause: ":""}}{{ timesheetToDo.breakBegin }}{{timesheetToDo.breakBegin.length>0?" - ":""}}{{ timesheetToDo.breakUntil }}{{timesheetToDo.breakBegin.length>0?"":"Keine Pause"}}
                    </p>
                </ion-label>
            </ion-item>
        </ion-list>
    </Modal>
</template>
