import type { NotificationType } from '@/helper/pushNotifications';
import { defineStore } from 'pinia';


export const useNotificationStore = defineStore('notification', {
  persist: true,

  state: () => ({
    notifications: [] as NotificationType[],
  }),

  actions: {
    pushNotification(notification: NotificationType): void {
      this.notifications.push(notification);
    },

    popNotification(): NotificationType | null {
      const notification = this.notifications.shift() || null;
      return notification;
    },

    clearNotifications(): void {
      this.notifications = [];
    },
  },

  getters: {
    count: (state): number => state.notifications.length,
    readyForDisplay: (state): boolean => state.notifications.length > 0,
    getTop: (state): NotificationType|null => state.notifications.length > 0 ? state.notifications[0] : null
  },
});
