<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonFooter,
} from "@ionic/vue";
import TextWithIcon from "@/components/TextWithIcon.vue";
import InlineSVG from "@/components/InlineSVG.vue";
import {computed, Ref, ref} from "vue";
import {getSummary} from "@/helper/timesheet";
import {useRoute} from "vue-router";
import {pluralize} from "@/helper/amountFormatter";

import {useTimesheetStore} from "@/store/timesheet";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import { getImageForJobsite } from '@/helper/jobsite'

const route = useRoute();

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const signateurNameDefault = computed(()=>
  Number(route.params.jobsiteId) in store.getPreviousTimesheetSignateurNamesLookup
  ? store.getPreviousTimesheetSignateurNamesLookup[Number(route.params.jobsiteId)]
  : ""
)
const signateurName : Ref<string> = ref(signateurNameDefault.value);
const emailWanted = ref(false);

const signateurEMailDefault = computed(()=>
  Number(route.params.jobsiteId) in store.getPreviousTimesheetSignateurEMailsLookup
  ? store.getPreviousTimesheetSignateurEMailsLookup[Number(route.params.jobsiteId)]
  : ""
)
const signateurEmail = ref(signateurEMailDefault.value);

const validName = computed(() => signateurName.value != "");
const validEmail = computed(
    () => !emailWanted.value || signateurEmail.value != "",
);

const valid = computed(() => {
    return validName.value;
});

const jobsite = computed(() => {
    return store.timesheetToDosInSignatureProcess
        ? getSummary(
                store
                    .timesheetToDosInSignatureProcess[0],
                store.timesheetToDos,
            ).jobsite
        : undefined;
});

const setSignateur = () => {
    if (valid.value) {
        store.setSignateur(signateurName.value, signateurEmail.value);
        props.timesheetNavigator.next()
    }
};
</script>

<template>
    <ion-content>
        <ion-card class="no-margin-top">
            <ion-card-header>
                <ion-card-title>Einsätze</ion-card-title>
            </ion-card-header>
            <ion-card-content
                class="no-padding-top no-padding-bottom"
                v-if="store.timesheetToDosInSignatureProcess.length > 0"
            >
                <RichResponseListItem
                    iconType="image"
                    :icon="getImageForJobsite(jobsite)"
                    :title="jobsite.house?.name?.toString()"
                >
                    {{ jobsite?.name?.toString() }}<br>
                    {{
                        pluralize(
                            store.timesheetToDosInSignatureProcess.length,
                            "Dienst",
                            "Dienste"
                        )
                    }}
                </RichResponseListItem>
            </ion-card-content>
        </ion-card>
        <ion-card>
            <ion-card-header>
                <ion-card-title>Wer Signiert?</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-input
                    fill="outline"
                    @ion-input="
                        ({ target }) => {
                            signateurName = target.value?.toString() || '';
                        }
                    "
                    :value="signateurNameDefault"
                    placeholder="Vor- und Nachname"
                ></ion-input>
                <TextWithIcon
                    v-if="!validName"
                    :small="true"
                    :warning="!validName"
                    :success="validName"
                >
                    <template #icons>
                        <InlineSVG
                            v-if="!validName"
                            src="/icons/info.svg"
                        />
                        <InlineSVG v-else src="/icons/check-circle.svg" />
                    </template>
                    <p>
                        {{ !validName ? "Kein Name angegeben" : "" }}
                    </p>
                </TextWithIcon>
            </ion-card-content>
        </ion-card>
        <ion-card>
            <ion-card-header>
                <ion-card-title> E-Mail Bestätigung (optional) </ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-button
                    v-if="false"
                    color="light"
                    v-on:click="emailWanted = true"
                    expand="block"
                >
                    Der Kunde wünscht eine Bestätigung
                </ion-button>
                <ion-input
                    v-if="true"
                    fill="outline"
                    @ion-input="
                        ({ target }) => {
                            signateurEmail = target.value?.toString() || '';
                        }
                    "
                    type="email"
                    :value="signateurEMailDefault"
                    placeholder="email@domain.com"
                ></ion-input>

                <TextWithIcon
                    v-if="false"
                    :small="true"
                    :warning="!validEmail"
                    :success="validEmail"
                >
                    <template #icons>
                        <InlineSVG
                            v-if="!validEmail"
                            src="/icons/info.svg"
                        />
                        <InlineSVG v-else src="/icons/check-circle.svg" />
                    </template>
                    <p>
                        {{
                            !validEmail
                                ? "Keine E-Mail Adresse angegeben"
                                : ""
                        }}
                    </p>
                </TextWithIcon>
            </ion-card-content>
        </ion-card>
    </ion-content>
    <ion-footer>
        <ion-button
            :disabled="!valid"
            expand="block"
            color="primary"
            v-on:click="setSignateur()"
            >Dienste Signieren</ion-button
        >
    </ion-footer>
</template>
