// missing openai status: cancelling, incomplete, expired
export enum AssistantRunStatus {
    INIT,

    REQUESTED,

    RUNNING, // in_progress,
    PENDING_TOOL_CALL, // requires_action,

    COMPLETED, // completed
    FAILED, // failed
    CANCELLED, // cancelled
}

export namespace AssistantRunStatus {
    export function isTerminal(state: AssistantRunStatus) {
        return state === AssistantRunStatus.COMPLETED || state === AssistantRunStatus.FAILED || state === AssistantRunStatus.CANCELLED;
    }

    export function transitionTo(nextState: AssistantRunStatus, previousState: AssistantRunStatus): AssistantRunStatus {
        if (
            previousState < nextState ||
            (previousState === AssistantRunStatus.PENDING_TOOL_CALL && nextState === AssistantRunStatus.RUNNING)
        ) {
            return nextState;
        }

        console.error("dropping assistant run state transition from " + AssistantRunStatus[previousState] + " to " + AssistantRunStatus[nextState])
        return previousState
    }
}
