<template>
    <ion-list lines="none" :class="displayModeClass">
        <ion-item v-if="contact.name"> 
            <ion-icon item-start :icon="personCircleOutline"></ion-icon>           
            {{ contact.name }}
        </ion-item>
        <ion-item v-if="contact.phone">
            <ion-icon item-start :icon="callOutline"></ion-icon>           
            <a target="_blank" :href="'tel:' + contact.phone">{{ contact.phone }}</a>
        </ion-item>
        <ion-item v-if="contact.mobile">
            <ion-icon item-start :icon="phonePortraitOutline"></ion-icon>                       
            <a target="_blank" :href="'tel:' + contact.mobile">{{ contact.mobile }}</a>
        </ion-item>
        <ion-item v-if="contact.mail">
            <ion-icon item-start :icon="mailOpenOutline"></ion-icon>           
            <a target="_blank" :href="'mailto:' + contact.mail">{{ contact.mail }}</a>
        </ion-item>
        <ion-item v-if="website">
            <ion-icon item-start :icon="globeOutline"></ion-icon>           
            <a target="_blank" :href="website">{{ website }}</a>
        </ion-item>
    </ion-list>
</template>


<script setup lang="ts">
import {
    IonIcon,
    IonList,
    IonItem
} from '@ionic/vue';
import {Contact} from "@/graphql/generated/graphql";
import { callOutline, globeOutline, mailOpenOutline, personCircleOutline, phonePortraitOutline } from 'ionicons/icons';
import { computed } from 'vue';

const props = defineProps<{
        contact: Contact
        website?: string | undefined
        displayMode?: 'standalone' | 'inline' | undefined
    }>()

// this could be a prop default - if i'd be able to figure out how they work
const displayModeClass = computed(() =>  props.displayMode ||  'inline')
</script>

<style scoped>
.standalone ion-item {
    
}

.inline ion-item {
    --min-height: var(--custom-font-size-regular);
    font-size: var(--custom-font-size-regular);
    color: var(--ion-color-medium);
}
</style>
