import {createClient, mapExchange} from "@urql/core";
import {fetchExchange, Operation} from "@urql/vue";
import {authExchange} from "@urql/exchange-auth";

import {useAuthStore} from "@/store/auth";
import {RequestQueueHelper, useRequestQueueHelper,} from "@/helper/requestQueue";
import {kapitelErrorHandler} from "@/helper/error";
import {useAppState} from "@/helper/appState";
import useOfflineHelper from "@/helper/offline";
import {Promise} from "cypress/types/cy-bluebird";
import {Kind} from "graphql/language";

const auth = authExchange(async (utilities) => {
  const authStore = useAuthStore();

  return {
    addAuthToOperation(operation) {
      if (authStore.hasToken()) {
        operation = utilities.appendHeaders(operation, {
          Authorization: "Bearer " + authStore.getToken(),
        });
      }

      if (authStore.isImpersonating()) {
        operation = utilities.appendHeaders(operation, {
          "X-Switch-User":  authStore.getImpersonationUsername(),
        });
      }

      return operation
    },
    didAuthError(error) {
      return (
        error.graphQLErrors.some(
          (e) => e.extensions?.code === "UNAUTHORIZED",
        ) || +error.response?.status === 401
      );
    },
    async refreshAuth() {
      // logout
      useAppState().logout()      
    },
  };
});

const client = createClient({
  url: import.meta.env.VITE_GRAPHQL_ENDPOINT as string,
  exchanges: [
    mapExchange({
      onError: (e, operation) => {
        console.log(e, operation);
        if (e.networkError) {
            useOfflineHelper().checkNow()
        }
        if (operation.kind === 'mutation') {
            kapitelErrorHandler('Fehler beim Speichern')
        }
      },
      onOperation(operation: Operation): Promise<Operation> | Operation | void {
        const operationNode = operation.query.definitions[0]
        const operationName = operationNode.kind === Kind.OPERATION_DEFINITION ? operationNode.name?.value : undefined
        if (operationName) {
            operation.context.url = operation.context.url + '?' + operationName
        }

        return operation
      }
    }),
    auth,
    fetchExchange,
  ],
  fetchOptions: {
    headers: {
      "content-type": "application/json",
    },
  },
  requestPolicy: "cache-and-network", // prevent stale cache entries
});

const requestQueue: RequestQueueHelper = useRequestQueueHelper();

export const query = async (query: any, params = {}) => {
  return requestQueue.query(() => client.query(query, params).toPromise());
};
export const mutation = async (query: any, params = {}, silent = false) => {
    const mutate = () => client.mutation(query, params).toPromise()
    // execute mutation directly without using requestQueue on silent:true thus skipping "Saving..." indicator
    return silent
        ? mutate()
        : requestQueue.mutation(mutate)
};
