export const parseURL = (url: string) : URL | undefined => {
    try {
        return new URL(url);
    } catch (e) {
        return undefined
    }
}

export const isValidURL = (url: string) : boolean => {
    return !!parseURL(url)
}
