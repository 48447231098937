import { kapitelDateString } from '@/graphql/kapitelTypes';
import {
    addDays,
    cmpKapitelDate,
    formatKapitelDate,
    formatMonthFromKapitelDate,
    formatWeekdayFromKapitelDate,
    formatWeekdayShortFromKapitelDate,
    getDayOfMonthFromKapitelDate,
    getISOWeekNumberFromKapitelDate,
    getMonthFromKapitelDate,
    getToday,
    getYearFromKapitelDate,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isMonday,
    isTomorrow,
    isWeekend,
    isYesterday,
} from '../datetime/date';

const today = getToday()

export type CalendarDate = ReturnType<typeof generateCalendarDate>

export const generateCalendarDate = (dateString: kapitelDateString) => {

    const day = getDayOfMonthFromKapitelDate(dateString)
    const week = getISOWeekNumberFromKapitelDate(dateString)
    const month = getMonthFromKapitelDate(dateString)
    const year = getYearFromKapitelDate(dateString)

    const cmpToday = cmpKapitelDate(dateString, today)

    const isPast = cmpToday < 0
    const isToday = cmpToday == 0
    const isFuture = cmpToday > 0

    const isFirstOfMonth = isFirstDayOfMonth(dateString)
    const isLastOfMonth = isLastDayOfMonth(dateString)
    const isFirstOfWeek = isMonday(dateString)

    return {
        id: dateString,
        type: 'date',

        day: day,
        week: week,
        month: month,
        year: year,

        labelWeekdayShort: formatWeekdayShortFromKapitelDate(dateString),
        labelWeekday: formatWeekdayFromKapitelDate(dateString),
        labelMonth: formatMonthFromKapitelDate(dateString),

        isToday: isToday,
        isYesterday: isYesterday(dateString),
        isTomorrow: isTomorrow(dateString),
        isPast: isPast,
        isFuture: isFuture,
        isWeekend: isWeekend(dateString),
        isFirstOfWeek: isFirstOfWeek,
        isFirstOfMonth: isFirstOfMonth,
        isLastOfMonth: isLastOfMonth,
    }
}

type __CalendarMonth = ReturnType<typeof __generateCalendarMonth>

const __generateCalendarMonth = (dateString: kapitelDateString) => {
    return {
        id: dateString,
        type: 'month',
        label: formatKapitelDate(dateString, 'MMMM'),
        labelShort: formatKapitelDate(dateString, 'MM'),
        labelFull: formatKapitelDate(dateString, 'MMMM yyyy'),
    }
}

export const generateCalendarDates = (startDateString: kapitelDateString, endDateString: kapitelDateString, addMonths = false): Array<CalendarDate|__CalendarMonth> => {
    const result = []

    let iterator = addDays(startDateString, 0);

    while (cmpKapitelDate(endDateString, iterator)>=0) {
        const calendarDate = generateCalendarDate(iterator)

        if (addMonths && calendarDate.isFirstOfMonth) {
            const month = __generateCalendarMonth(iterator)
            result.push(month)
        }

        result.push(calendarDate)
        iterator = addDays(iterator, 1)
    }

    return result
};


export const generateCalendarDateFromKapitelDateString = (dateStr: kapitelDateString) => {
    return generateCalendarDate(dateStr)
}
