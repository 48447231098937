<script setup>
    import { IonAvatar } from '@ionic/vue'
    import { computed } from 'vue'
    import InlineSVG from '@/components/InlineSVG.vue'

    const props = defineProps({
        src: undefined,
        type: {
            type: String,
            default: 'facility' // or 'human'
        }
    })

    const fallback = computed(() => {
        return props.type === 'facility'
            ? '/icons/first-aid.svg'
            : '/icons/user.svg'
    })
</script>

<template>
    <div class="avatar">
        <img v-if="src" :src="src" />
        <InlineSVG v-else class="fallback" :src="fallback" />
    </div>
</template>

<style lang="scss">
.avatar {
    width: 3rem;
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    background-color: var(--ion-color-dark-tint);
    color: var(--ion-color-light);

    &:before{
        content: "";
        display: block;
        padding-top: 100%;
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > .fallback {
        transform: scale(0.7);
    }
}
</style>