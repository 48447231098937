import {useAuthStore} from "@/store/auth";
import Auth from "@/views/Auth/Auth.vue";
import EmployeeSelector from '@/views/Employees/EmployeeSelector.vue';
import {createRouter, createWebHashHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {isLoggedIn} from "@/helper/auth";
import Offline from "@/views/Offline.vue";
import Chat from "@/views/Chat/Chat.vue";
import useOfflineHelper from "@/helper/offline";
import usePushNotificationHelper, {NotificationType} from "@/helper/pushNotifications"
import Debug from "@/views/Debug.vue";
import {sendUserMessage} from "@/helper/chat/chatBL";
import {AInesAssistantType} from "@/graphql/generated/graphql";

const loginStateEntrypoint = () => isLoggedIn() ? '/chat' : '/auth'

const nestedRouteRawData: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: () => loginStateEntrypoint(),
        meta: { allowAccessWhenLoggedOut: true, hideToolbar: true }
    },
    {
        path: '/auth',
        component: Auth,
        meta: { allowAccessWhenLoggedOut: true, prohibitAccessWhenLoggedIn: true, hideToolbar: true, exitOnHardwareBack: true }
    },
    // {
    //     path: '/timesheet/:jobsiteId?',
    //     name: 'TimesheetProcess',
    //     component: TimesheetProcess,
    //     props: true,
    //     meta: { hideToolbar: true, offlineSupport: true },
    // },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat,
        meta: { exitOnHardwareBack: true, hideToolbar: true }
    },
    {
        path: '/offline',
        name: 'Offline',
        component: Offline,
        meta: { exitOnHardwareBack: true, offlineSupport: true, hideToolbar: true }
    },
    {
        path: '/employees',
        name: 'EmployeeSelector',
        component: EmployeeSelector,
        meta: {
            allowAccessWhenNotImpersonating: true,
            offlineSupport: true
        }
    },
    {
        path: '/debug',
        name: 'Debug',
        component: Debug,        
    },
]

const parseAndFlattenRoutes = (routes:Array<RouteRecordRaw>, parentRoute: RouteRecordRaw | undefined = undefined, nestingLevel = 0) => {
    let result : Array<RouteRecordRaw> = [];
    routes.forEach(route => {
        if (route.children) {
            result = result.concat(parseAndFlattenRoutes(route.children, route, nestingLevel + 1))
        }

        const clone = Object.assign({}, route)
        clone.children = undefined
        clone.meta = clone.meta ?? {}
        clone.meta.nestedRouteParent = parentRoute
        clone.meta.nestedRouteChildren = route.children
        clone.meta.nestedRouteLevel = nestingLevel
        result.push(clone)
    })
    return result;
}

const routes = parseAndFlattenRoutes(nestedRouteRawData)

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes
})

const offlineHelper = useOfflineHelper()
const pushNotificationHelper = usePushNotificationHelper()

router.beforeEach((to, from) => {
    /**
     * routes accessible while logged out explicitly need to allow this {allowAccessWhenLoggedOut:true}
     * routes un-accessible if logged in can prohibit access {prohibitAccessWhenLoggedIn:true}
     *
     * in both cases we redirect to the corresponding login-state-entrypoint
     */
    const loggedInState = isLoggedIn()
    if (
        (!loggedInState && !to.meta.allowAccessWhenLoggedOut) ||
        (loggedInState && to.meta.prohibitAccessWhenLoggedIn)
    ) {
        return loginStateEntrypoint()
    }

    /**
     * if we are offline and the target route doesn't have offline support: redirect to the offline landing page
     */
    if (offlineHelper.isOffline.value && !to.meta.offlineSupport) {
        return '/offline'
    }

    const authStore = useAuthStore()
    if(authStore.canImpersonate() && !authStore.isImpersonating() && !to.meta.allowAccessWhenNotImpersonating){
        return '/employees'
    }
});

pushNotificationHelper.onPushNotificationActionPerformed(({view, props}:NotificationType)=>{
    switch (view) {
        case 'CalendarDetail':
            sendUserMessage("Zeig mir meine Tagesdetails für den "+ props.dateId + ".", {messageExpertAssistant:AInesAssistantType.Roster, isScriptedContent: true}).then(()=>{
                router.push('/chat')
            })
            break
        default:
            throw Error(view + " not a valid notification target.")
    }
})

export default router
