<script setup>
import { computed } from 'vue'
import { availabilityShiftsAndTimeConstraintsToIntervals } from '@/helper/availability'
import datetime from '@/helper/datetime/datetime'
import { IonIcon } from '@ionic/vue'
import { pluralize } from '@/helper/amountFormatter';

const props = defineProps({
    shifts: Object,
    constraints: Object,
    label: String,
    jobsiteCount: {
        type: Number,
        default: undefined,
        required: false
    }
})

const timeIntervals = computed(() => {
    return availabilityShiftsAndTimeConstraintsToIntervals(props.shifts, props.constraints)
})

const effectiveAvailabilityHumanReadableHTML = computed(() => {
    if (timeIntervals.value.length === 0) {
        return `Du bist nicht verfügbar.`
    }

    // long format: ' bis ' || short format: Gedankenstrich
    const vonBisDivider = timeIntervals.value.length === 1 ? ' bis ' : '&ndash;'

    const formatIntervalPart = (intervalPart) => datetime.formatKapitelDateTime(intervalPart, datetime.kapitelDateTimeFormatTime)
    const timespans = timeIntervals.value.map((interval) => formatIntervalPart(interval.begin) + vonBisDivider + formatIntervalPart(interval.until))

    // append " Uhr" to last item
    timespans[timespans.length-1] += ' Uhr'

    // use localized formatter to get "a, b und c"
    const formatter = new Intl.ListFormat('de', { style: 'long', type: 'conjunction' });
    
    const wrappedTimespans = timespans.map(ts => `<span>${ts}</span>`)

    let str =  `Du bist verfügbar von  ${formatter.format(wrappedTimespans)}`

    if (props.jobsiteCount !== undefined) {
        str += ` für ${pluralize(props.jobsiteCount, 'Station', 'Stationen')}`
    }
                
    return `${str}.`
})

const graphChunks = computed(() => {
    if (!timeIntervals.value.length) {
        return []
    }

    const mostLeftDateTime = timeIntervals.value[0].begin
    const mostRightDateTime = timeIntervals.value[timeIntervals.value.length - 1].until

    const overwriteWithEmployeeTimezone = true
    const startOfBeginDay = datetime.startOfDay(mostLeftDateTime, overwriteWithEmployeeTimezone)
    const endOfBeginDay = datetime.endOfDay(mostLeftDateTime, overwriteWithEmployeeTimezone)

    const rangeBegin = startOfBeginDay;
    const rangeUntil = datetime.isAfter(mostRightDateTime, endOfBeginDay) ? mostRightDateTime : endOfBeginDay;

    const duration = datetime.getDateTimeDiffInMinutes(rangeBegin, rangeUntil)

    return timeIntervals.value.map(interval => {
        const left = datetime.getDateTimeDiffInMinutes(rangeBegin, interval.begin) / duration * 100
        const right = datetime.getDateTimeDiffInMinutes(rangeBegin, interval.until) / duration * 100

        return {
            style: {
                left: left + '%',
                width: (right - left) + '%'
            },
            begin: datetime.formatKapitelDateTime(interval.begin, datetime.kapitelDateTimeFormatTime),
            end: datetime.formatKapitelDateTime(interval.until, datetime.kapitelDateTimeFormatTime)
        }
    })
})
</script>

<template>
    <div
        class="availability-effective-times-info"
    >
        <div class="timeline-wrapper">
            <ion-icon
                src="/icons/sun-horizon.svg"
                color="primary"
            />
            <div class="availability-timeline">
                <div v-for="chunk in graphChunks" :key="chunk.begin" class="chunk" :style="chunk.style"></div>
            </div>
            <ion-icon
                src="/icons/moon-stars.svg"
                color="primary"
            />
        </div>

        <p class="text-medium" id="time-summary" v-html="effectiveAvailabilityHumanReadableHTML">            
        </p>
    </div>
</template>

<style lang="scss">
.availability-effective-times-info {
    margin-top: -0.75rem;

    .timeline-wrapper {
        display: flex;
        align-items: center;

        ion-icon {
            font-size: 1.5rem;
        }
    }
    #time-summary {
        $LINE_HEIGHT: 1.125em;
        line-height: $LINE_HEIGHT;
        min-height: 2 * $LINE_HEIGHT;
        margin-bottom: 1em;
        
        span {
            font-weight: bold;
        }
    }

    .availability-timeline {
        position: relative;
        width: 100%;
        height: 0.5em;
        border-radius: 0.25em;
        overflow: visible;
        margin: 0 0.5em;
        background-color: var(--ion-color-grey-tint-2);

        > .chunk {
            position: absolute;
            height: 100%;
            top: 0;
            background-color: var(--ion-color-primary);
            border-radius: inherit;
            box-shadow: 0 0 1rem 0 rgba(var(--ion-color-primary-rgb), 0.5);
        }
    }
}
</style>
