import {kapitelErrorHandler} from "@/helper/error";
import {Ref, ref} from "vue";

type DataLoaderCallable = ((...args : any[]) => Promise<any>) | DataLoader

export const InstantDataLoader = (dataLoaderCallable: DataLoaderCallable | DataLoaderCallable[]) : DataLoader => {
    const dataLoader = new DataLoader(dataLoaderCallable)
    dataLoader.load()
    return dataLoader
}

export class DataLoader {
    public pending : Ref<boolean>;
    public started : Ref<boolean>;
    public loading : Ref<boolean>;

    private loadingPromise : Promise<any> | undefined;

    private functions : Array<any>;
    private parameterPlural : boolean;

    public constructor(dataLoaderCallable: DataLoaderCallable | DataLoaderCallable[]) {
        this.pending = ref(true);
        this.started = ref(false);
        this.loading = ref(false);

        this.parameterPlural = Array.isArray(dataLoaderCallable)

        this.functions = Array.isArray(dataLoaderCallable) ? dataLoaderCallable : [dataLoaderCallable];
    }

    private async _load(...args : any[]) : Promise<any> {
        this.started.value = true

        if (!this.loadingPromise) {
            const executedFunctions = this.functions.map(f => f instanceof DataLoader ? f.load(...args) : f(...args))

            this.loading.value = true
            this.loadingPromise = Promise
                .all(executedFunctions)
                .catch(kapitelErrorHandler)
                .finally(() => {
                    this.pending.value = false

                    this.loading.value = false
                    this.loadingPromise = undefined
                })
        }

        const result = await this.loadingPromise;

        return this.parameterPlural ? result : result[0]
    }

    public load = this._load.bind(this)

    public reload = async () : Promise<any> => {
        this.reset()
        return this.load()
    }

    public reset = () => {
        this.pending.value = true
    }
}
