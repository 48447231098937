<script setup lang="ts">
import { IonContent, IonButton } from "@ionic/vue";
import Flower from '@/components/Flower.vue'

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})
</script>

<template>
<ion-content
    class="no-padding-top">
    <Flower />
    <h2>Vielen Dank!</h2>
    <p>Wir haben die Stunden erfolgreich erfasst.</p>
    <ion-button color="secondary" @click="props.timesheetNavigator.done()">Fertig</ion-button>
</ion-content>
</template>

<style lang="scss" scoped>
ion-content::part(scroll) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

p {
    margin-bottom: 2em;
}
</style>
