import BezierEasing from '@/libs/animation/bezierEasing'

export default {
  props: {
    suppressAnimation: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.__easeStates = {
      idle: 'idle',
      running: 'running',
      stopped: 'stopped',
      cancelled: 'cancelled',
      resetted: 'resetted'
    }
  },

  mounted () {
    this.suppressAnimation
      ? this.$el.classList.add('suppress-animation')
      : void 0
  },

  methods: {
    easingFn (name) {
      switch (name) {

        // Sine

        case 'easeInSin':
          return (t) => 1 + Math.sin(Math.PI / 2 * t - Math.PI / 2)

        case 'easeOutSin':
          return (t) => Math.sin(Math.PI / 2 * t)

        case 'easeInOutSin':
          return (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2

        // Quad

        case 'easeInQuad':
          return (t) => t * t

        case 'easeOutQuad':
          return (t) => t * (2 - t)

        // Cubic

        case 'easeInCubic':
          return (t) => t * t * t

        case 'easeOutCubic':
          return (t) => (--t) * t * t + 1

        // Quint

        case 'easeInQuint':
          return (t) => t * t * t * t

        case 'easeOutQuint':
          return (t) => 1 + (--t) * t * t * t * t

        // Expo

        case 'easeOutExpo':
          return (t) => t === 1
            ? t
            : -Math.pow(2, -10 * t) + 1

        // Bezier

        case 'cubicBezier':
          return (t) => BezierEasing(0.25, 0.8, 0.25, 1)(t)

        // Bounce

        case 'easeOutBounce':
          return (t) => {
            const c1 = 1.70158;
            const c3 = c1 + 1;

            return 1 + c3 * Math.pow(t - 1, 3) + c1 * Math.pow(t - 1, 2);
          }

        case 'easeInBounce':
          return (t) => {
            const c1 = 1.70158;
            const c3 = c1 + 1;

            return t === 1 ? 1 : c3 * t * t * t - c1 * t * t;
          }

        // Linear

        case 'linear':
        default:
          return (t) => t
      }
    },

    /**
     * Returns an ID with which the ease can be cancelled
     * by invoking stopEase(id).
     * If an ease is stopped, a last step with progress=1
     * and the complete handler will be called.
     *
     * delay, duration: ms
     */

    ease (duration, step, options) {
      const ease = this.__generateEase(duration, step, options)
      this.__addEase(ease)

      if (this.suppressAnimation) {
        ease.onBegin()
        ease.progress = 1
        this.__performEaseStep(ease)
        ease.onComplete()
        ease.onEnd()
        this.__removeEase(ease)
        return
      }

      this.__tickEase(ease)
      return ease.id
    },

    // Will run the ease if it's not running (yet). No need
    // to call this manually.

    runEase (id) {
      const ease = this.__activeEases[id]

      if (!ease) {
        return
      }

      ease.state === this.__easeStates.idle
        ? ease.onBegin()
        : void 0

      ease.state = this.__easeStates.running

      return this.__activeEases[id]
    },

    // Will stop the ease at the final progress (1).

    stopEase (id) {
      const ease = this.__activeEases[id]

      if (!ease) {
        return
      }

      ease.state = this.__easeStates.stopped
      this.__tickEase(ease)

      return id
    },

    stopAllEases () {
      this.__activeEases
        ? Object.keys(this.__activeEases).forEach(this.stopEase.bind(this))
        : void 0
    },

    // Will stop the ease at its current progress.

    cancelEase (id) {
      const ease = this.__activeEases[id]

      if (!ease) {
        return
      }

      ease.state = this.__easeStates.cancelled
      this.__tickEase(ease)

      return id
    },

    cancelAllEases () {
      this.__activeEases
        ? Object.keys(this.__activeEases).forEach(this.cancelEase.bind(this))
        : void 0
    },

    // Will stop the ease at its initial progress (0).

    resetEase (id) {
      const ease = this.__activeEases[id]

      if (!ease) {
        return
      }

      ease.state = this.__easeStates.resetted
      this.__tickEase(ease)

      return id
    },

    resetAllEases () {
      this.__activeEases
        ? Object.keys(this.__activeEases).forEach(this.resetEase.bind(this))
        : void 0
    },

    isEasing () {
      return this.__activeEases
        ? Object.keys(this.__activeEases).length > 0
        : false
    },

    /**
     * Private
     */

    __onEaseEnd (id, callback) {
      this.$emit('ease-end', id)
      callback(id)
    },

    __onEaseComplete (id, callback) {
      this.$emit('ease-complete', id)
      callback(id)
    },

    __onEaseCancel (id, callback) {
      this.$emit('ease-cancel', id)
      callback(id)
    },

    __onEaseReset (id, callback) {
      this.$emit('ease-reset', id)
      callback(id)
    },

    __generateEase (duration, step, options) {
      const ease = Object.assign({
        delay: 0,
        onBegin: () => void 0,
        onEnd: () => void 0,
        onComplete: () => void 0,
        onCancel: () => void 0,
        onReset: () => void 0,
        easing: void 0
      }, options)

      ease.duration = duration
      ease.created = window.performance.now()
      ease.elapsed = 0
      ease.progress = 0
      ease.step = step
      ease.id = this.__generateEaseUID()
      ease.state = this.__easeStates.idle

      return ease
    },

    __tickEase (ease) {
      ease.elapsed = window.performance.now() - ease.created - ease.delay

      if (ease.elapsed >= 0 && ease.state === this.__easeStates.idle) {
        this.runEase(ease.id)
      }

      const progress = this.easingFn(ease.easing)(Math.min(ease.elapsed / ease.duration, 1))

      ease.state = progress === 1 && ease.state === this.__easeStates.running
        ? this.__easeStates.stopped
        : ease.state

      switch (ease.state) {
        case this.__easeStates.idle:
          requestAnimationFrame(this.__tickEase.bind(this, ease))
          break

        case this.__easeStates.running:
          ease.progress = progress
          this.__performEaseStep(ease)
          requestAnimationFrame(this.__tickEase.bind(this, ease))
          break

        case this.__easeStates.stopped:
          ease.progress = 1
          this.__performEaseStep(ease)
          this.__onEaseComplete(ease.id, ease.onComplete)
          this.__onEaseEnd(ease.id, ease.onEnd)
          this.__removeEase(ease)
          break

        case this.__easeStates.cancelled:
          ease.progress = progress
          this.__performEaseStep(ease)
          this.__onEaseCancel(ease.id, ease.onCancel)
          this.__onEaseEnd(ease.id, ease.onEnd)
          this.__removeEase(ease)
          break

        case this.__easeStates.resetted:
          ease.progress = 0
          this.__performEaseStep(ease)
          this.__onEaseReset(ease.id, ease.onReset)
          this.__onEaseEnd(ease.id, ease.onEnd)
          this.__removeEase(ease)
          break
      }
    },

    __addEase (ease) {
      this.__activeEases = this.__activeEases || {}
      this.__activeEases[ease.id] = ease
    },

    __removeEase (ease) {
      delete this.__activeEases[ease.id]
    },

    __performEaseStep (ease) {
      ease.step(ease.progress, ease.id, ease.state)
    },

    __generateEaseUID () {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },

    __removeAllEases () {
      this.__activeEases = {}
    },

    __easeIsRunning (id) {
      return this.__activeEases[id] === this.__easeStates.running
    },

    __easeIsStopped (id) {
      return this.__activeEases[id] === this.__easeStates.stopped
    },

    __easeIsCancelled (id) {
      return this.__activeEases[id] === this.__easeStates.cancelled
    },

    __easeIsResetted (id) {
      return this.__activeEases[id] === this.__easeStates.resetted
    }
  }
}
