/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleMarginTop extends ModuleBase {
  prepareEnter (el) {
    const topMargin = parseFloat(window.getComputedStyle(el)['margin-top'])
    const elHeight = el.clientHeight

    this.targetState.start = -elHeight
    this.targetState.distance = elHeight + topMargin
    el.style.marginTop = (-elHeight) + 'px'
  }

  prepareLeave (el) {
    const topMargin = parseFloat(window.getComputedStyle(el)['margin-top'])
    const elHeight = el.clientHeight

    this.initialState.start = topMargin
    this.initialState.distance = -elHeight - topMargin
  }

  applyEnter (el, progress) {
    el.style.marginTop = (progress * this.targetState.distance + this.targetState.start) + 'px'
  }

  applyLeave (el, progress) {
    el.style.marginTop = (progress * this.initialState.distance + this.initialState.start) + 'px'
  }
}
