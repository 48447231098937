import {onIonViewDidEnter, onIonViewWillLeave} from "@ionic/vue";
import useEmitter from "@/helper/emitter";

export const onIonViewEnteredAndCapicatorAppActivate = (cb:any) => {

    let active = true

    const setActive = (state: boolean) => {
        if (active === state) {
            return
        }

        active = state
    }

    const emitter = useEmitter()
    emitter.on('CapacitorAppStateChange:active', () => {
        if (!active) {
            return
        }

        cb()
    })

    onIonViewDidEnter(() => setActive(true))
    onIonViewWillLeave( () => setActive(false))
}
