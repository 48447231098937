/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleOpacity extends ModuleBase {
  prepareEnter (el) {
    el.style.opacity = 0
  }

  prepareLeave (el) {
    this.initialState.opacity = parseFloat(getComputedStyle(el).opacity)
  }

  applyEnter (el, progress) {
    el.style.opacity = progress
  }

  applyLeave (el, progress) {
    el.style.opacity = (1 - progress) * this.initialState.opacity
  }
}
