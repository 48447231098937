import {convertRichResponseToRichContent, ResponseMessageRichResponse, RichContent} from "@/helper/chat/richResponses";

export type ThreadMessageStatus = 'ready' | 'streaming' | 'textDone' | 'messageDone'

export class ThreadMessage {
    constructor(
        public text: string,
        public role: 'user' | 'assistant',
        public richResponses: undefined | ResponseMessageRichResponse[] = undefined,
    ) {
    }

    public getRichContents(): RichContent[] | undefined {
        if (!this.richResponses) {
            return undefined
        }
        return this.richResponses
            .filter((richResponse: ResponseMessageRichResponse) => richResponse.type)//&& richResponse.reference
            .map(convertRichResponseToRichContent)
    }
}

export class StreamingThreadMessage extends ThreadMessage {

    public status: ThreadMessageStatus = 'ready'

    public isTextComplete() : Boolean { return this.status === "textDone" || this.status === "messageDone" }
    public isRichResponseComplete() : Boolean { return this.status === "messageDone" }
}

export interface StreamingThreadMessageChanges {
    text?: string
    richResponses?: ResponseMessageRichResponse[]
    status?: ThreadMessageStatus
}
