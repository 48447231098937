<template>
    <RichResponseListItem
        :title="renderSet.title"
        :icon="renderSet.icon"
        icon-type="date"
        v-model="model"
        :class="renderSet.className"
        :image="renderSet.image"
        :selectable="selectable"
        @click="onClick"
    >
        <ion-chip
            class="small"
            v-if="renderSet.tags"
            v-for="tag in renderSet.tags"
        >
            {{ tag }}
        </ion-chip>
    
        {{ renderSet.text }}
    </RichResponseListItem>
</template>

<script setup lang="ts">
import RichResponseListItem from "./RichResponseListItem.vue"
import datetime from "@/helper/datetime/datetime"
import { IonChip } from '@ionic/vue'
import { computed } from "vue"
import { Day } from "@/graphql/generated/graphql"
import { kapitelDateString } from "@/graphql/kapitelTypes"
import {
    getAvailabilitySummary,
    availabilityEffectiveTimesHumanReadable,
    isEmpty,
    hasShifts
} from "@/helper/availability"
import { getLabelForAbsenceType } from "@/helper/absence"
import { getImageForJobsite } from '@/helper/jobsite'

const model = defineModel()

const props = defineProps<{
    date: kapitelDateString,
    dayData?: Day,
    routing?: boolean
    selectable?: boolean
}>()

const emit = defineEmits(['details'])
const onClick = () => {
    emit("details", {
        type: "CalendarDetail",
        initData: {
            date: props.date,
        }
    })
}


const renderSet = computed(() => {
    const result = {
        title: ' ',
        text: ' ',
        tags: [] as string[],
        className: '',
        icon: props.date,
        image: undefined as string | undefined
    }
    
    if (props.dayData?.booking) {
        const begin = datetime.formatKapitelDateTime(props.dayData.booking.begin, datetime.kapitelDateTimeFormatTime)
        const until = datetime.formatKapitelDateTime(props.dayData.booking.until, datetime.kapitelDateTimeFormatTime)

        result.className = 'booking'
        result.title = 'Dienst: ' + props.dayData.booking.jobsite.abbreviation
        result.text = `${begin} - ${until} Uhr`
        result.tags = [props.dayData.booking.shiftType]
        result.image = getImageForJobsite(props.dayData.booking.jobsite)
    }

    if (props.dayData?.availability && hasShifts(props.dayData?.availability)) {
        const summary = getAvailabilitySummary([props.dayData.availability])
        const shiftTimes = availabilityEffectiveTimesHumanReadable(props.dayData.availability)

        result.className = 'availability'
        result.title = 'verfügbar'
        result.text = shiftTimes || result.text
        result.tags = summary.shiftTypesList as string[]
    }

    if (props.dayData?.absence) {
        const absenceTypeLabel = getLabelForAbsenceType(props.dayData.absence.type)

        result.className = 'absence'
        result.title = 'nicht verfügbar'
        result.tags = [absenceTypeLabel]
    }

    return result
})
</script>

<style scoped lang="scss">

ion-chip {
    transform: translateY(0.15em);
}

.booking {
    ion-chip {
        background-color:var(--ion-color-black-tint);
        color: var(--ion-color-white);
    }
}

.availability {
    ion-chip {
        background-color:var(--ion-color-green);
        color: var(--ion-color-white);
    }
}

.absence {
    ion-chip {
        background-color:var(--ion-color-red);
        color: var(--ion-color-white);
    }
}
</style>
