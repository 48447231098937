import {useNotificationStore} from "@/store/notification"
import usePushNotificationHelper, {NotificationType} from "./pushNotifications"

let initialized = false

export const init = ()=>{

    const notificationStore = useNotificationStore()
    const pushNotificationHelper = usePushNotificationHelper()

    // so kommen neue notifications rein...
    pushNotificationHelper.onNotificationReceived(({view, props, title, body}:NotificationType)=>{
        notificationStore.pushNotification({
            title:title,
            body:body,
            view:view,
            props:props
        })
    })

    if (initialized) {
        console.warn('duplicate init of notification helper: multiple event listeners attached')
    }
    initialized = true
}
