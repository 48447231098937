/* eslint-disable */
export default class ModuleBase {
  constructor () {
    this.init()
    this.cleanUp()
  }

  // overridables

  init () {}
  prepareEnter () {}
  prepareLeave () {}
  applyEnter () {}
  applyLeave () {}

  // useables

  setOptions (options) {
    this.options = options
    this.init()
  }

  cleanUp () {
    this.initialState = {}
    this.targetState = {}
  }

  applyTransform (el, key, expression) {
    const expressions = el.style.transform.split(/\s(?=\S+\(.*?\))(?!rotate(?:Y|Z))/)
    const expIndex = expressions.findIndex(exp => exp.indexOf(key) === 0)

    if (expIndex > -1) {
      expressions.splice(expIndex, 1)
    }

    expressions.push(expression)
    el.style.transform = expressions.join(' ')
  }
}
