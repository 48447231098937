import {defineStore} from 'pinia'

import {Employee} from "@/graphql/generated/graphql";
import {fetchEmployee} from "@/helper/employee";

export const useEmployeeStore = defineStore("employee", {
    persist: true,
    state: (): { employee: Employee | undefined } => ({
        employee: undefined
    }),
    getters: {
        storedEmployee: state => state.employee
    },
    actions: {
        storeEmployee(employee: Employee) {
            this.employee = employee
        },
        async ensureEmployee(): Promise<Employee> {
            if (this.employee) {
                return this.employee
            }
            return await this.reloadEmployee()
        },
        async reloadEmployee(purge=false): Promise<Employee> {
            if(purge){
                this.employee = undefined
            }
            const employee = await fetchEmployee()
            this.storeEmployee(employee)
            return employee
        },
        async getEmployee(): Promise<Employee> {
            return this.employee || await this.reloadEmployee()
        },
    },
});
