<template>
    <Modal v-model="model" :title="'Zieldienste für ' + date.formatKapitelDate(props.month, 'MMMM')">
        <template v-slot:default>
            <Skeleton v-if="initialDataLoader.pending.value" :items="['line--height-3.5em', 'block--height-10em']"/>
            <template v-else>
                <PlusMinusInput v-model="targetBookingInput" @update:modelValue="onUpdateTargetBookings" :min="minBookings" :max="maxBookings" :unit="pluralizeUnitOnly(targetBookingInput, 'Dienst', 'Dienste')"></PlusMinusInput>
                <RichResponseListItem
                    v-for="item in getWorktimeModes()"
                    :title="getWorktimeModeLabel(item)"
                    v-on:selected="(value:boolean)=>{onClickWorktimeModeItem(item, value)}"
                    icon-type="icon"
                    :icon-color="worktimeModeInput === item ? 'primary' : 'grey'"
                    :icon="getIconForWorktimeMode(item)"
                    :selected="worktimeModeInput === item"
                    :selectable="isSelectable(item)"
                    v-bind:key="item"
                />
            </template>
        </template>
        <template v-slot:actions>
            <ion-button
                color="primary"
                expand="block"
                @click="model = false"
            >
                Ok
            </ion-button>
        </template>
</Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue'
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import {IonButton, IonContent, IonFooter} from '@ionic/vue';
import {computed, defineExpose, defineProps, PropType, ref, Ref, watch} from 'vue';
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import date from "@/helper/datetime/date";

import {
    AutopilotPreferenceObject,
    fetchAutopilotPreferenceObject,
    fetchPlanningTemplate,
    getIconForWorktimeMode,
    getWorktimeModeLabel,
    getWorktimeModes,
    updateAutopilotPreference
} from "@/helper/autopilotPreference";
import {InstantDataLoader} from "@/helper/dataLoader";

import RadioGroup from '@/components/RadioGroup.vue';
import InlineSVG from '@/components/InlineSVG.vue';

import Skeleton from "@/components/Skeleton.vue";
import {pluralizeUnitOnly} from '@/helper/amountFormatter';
import {PlanningStatus, PlanningTemplate, WorktimeMode} from "@/graphql/generated/graphql";
import {fetchPlanningStatus} from "@/helper/autopilotStatus";

import {kapitelDateString} from '@/graphql/kapitelTypes';
import PlusMinusInput from "@/components/PlusMinusInput.vue";
import {getRandomArrayElement} from "@/helper/array";

const model = defineModel()

const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    migrationMode: {
        type: Boolean,
        required: false,
        default: false
    }
});
const calendarMonth =  computed(() => generateCalendarMonth(props.month));


const planningTemplate : Ref<undefined|PlanningTemplate> = ref(undefined)
const autopilotPreference: Ref<AutopilotPreferenceObject | undefined> = ref();
const planningStatus : Ref<PlanningStatus|undefined> = ref()
const initialDataLoader = InstantDataLoader([
    async () => {autopilotPreference.value = await fetchAutopilotPreferenceObject(calendarMonth.value)},
    async () => planningStatus.value = await fetchPlanningStatus(calendarMonth.value),
    async () => planningTemplate.value = await fetchPlanningTemplate(calendarMonth.value)
]);

const minBookings = computed(() => planningTemplate.value?.bookingTargetMin || undefined)
const maxBookings = computed(() => planningTemplate.value?.bookingTargetMax || undefined)


/**
 * internal values & sync
 */

const isSelectable = (mode: WorktimeMode) => {
    if(
        mode === WorktimeMode.More
        && planningTemplate.value?.bookingTargetSpotOn == planningTemplate.value?.bookingTargetMax
    ){
        return false
    } else if(
        mode === WorktimeMode.Less
        && planningTemplate.value?.bookingTargetSpotOn == planningTemplate.value?.bookingTargetMin
    ){
        return false
    }

    return true

}

const targetBookingInput = ref(0)
const worktimeModeInput : Ref<undefined | WorktimeMode> = ref(undefined);

const syncTargetBookingsFromWorktimeMode = () => {
    const t = planningTemplate.value
    if (!t) {
        return;
    }

    const factor = 0.1
    const diff = Math.ceil(t.bookingTargetSpotOn * factor)

    switch (worktimeModeInput.value) {
        case WorktimeMode.Less: targetBookingInput.value = t.bookingTargetSpotOn - diff; break;
        case WorktimeMode.SpotOn: targetBookingInput.value = t.bookingTargetSpotOn; break;
        case WorktimeMode.More: targetBookingInput.value = maxBookings.value ? Math.min(t.bookingTargetSpotOn + diff, maxBookings.value) : t.bookingTargetSpotOn; break;
    }
}
const syncWorktimeModeFromTargetBookings = () => {
    const t = planningTemplate.value
    if (!t) {
        return;
    }

    switch (true) {
        case targetBookingInput.value === t.bookingTargetSpotOn: worktimeModeInput.value = WorktimeMode.SpotOn; break;
        case targetBookingInput.value > t.bookingTargetSpotOn: worktimeModeInput.value = WorktimeMode.More; break;
        case targetBookingInput.value < t.bookingTargetSpotOn: worktimeModeInput.value = WorktimeMode.Less; break;
    }
}


/**
 initial value
 */
const targetBookingInitialValue = computed(() => {
    if (autopilotPreference.value?.bookingTarget) {
        // already saved bookingTarget
        return autopilotPreference.value?.bookingTarget
    } else {
        return props.migrationMode
            ? planningStatus.value?.autopilotStatus?.bookingTarget // take infered from worktimemode setting
            : planningTemplate.value?.bookingTargetSuggestion // take suggestion based on last month
    }
})
watch(targetBookingInitialValue, (v) => {
    targetBookingInput.value = v || 0
    syncWorktimeModeFromTargetBookings()
})


const onClickWorktimeModeItem = (item: WorktimeMode, value: boolean) =>{
    worktimeModeInput.value = item; onUpdateWorktimeMode()
}

/**
 * interaction handling
 */
const onUpdateTargetBookings = () => {
    syncWorktimeModeFromTargetBookings()
    saveBookingTarget()
}
const onUpdateWorktimeMode = () => {
    syncTargetBookingsFromWorktimeMode()
    saveBookingTarget()
};
const saveBookingTarget = () => {
    const bookingTarget = targetBookingInput.value;
    updateAutopilotPreference(calendarMonth.value, { bookingTarget });

    if (autopilotPreference.value) {
        autopilotPreference.value.bookingTarget = bookingTarget;
    }
}


/**
 * Lifecycle methods
 */
const reload = () => {
    initialDataLoader.load();
};

reload(); // Load data initially

defineExpose({
    reload
});
</script>

<style lang="scss" scoped>
.worktime-mode-radio-group {
    ion-radio {
        .inline-svg {
            display: inline-block;
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            color: var(--ion-color-primary);
        }
    }
}
</style>
