import {useAuthStore} from "@/store/auth";
import router from "@/router";
import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Employee} from "@/graphql/generated/graphql";
import {isValidURL, parseURL} from "@/helper/url";
import {App as CapacitorApp, URLOpenListenerEvent} from "@capacitor/app";
import {alertController} from "@ionic/vue";
import useEmitter from "./emitter";
import { useAppState } from "./appState";

export function initAuth() {
    const emitter = useEmitter()

    // attach universal link listener
    CapacitorApp.addListener('appUrlOpen', async function (event: URLOpenListenerEvent) {
        //https://capacitorjs.com/docs/guides/deep-links#vue
        console.log('[CapacitorApp.addListener] App opened with URL', event);

        // get magic link
        const universalLink = event.url

        // try to login with it (exception & alert otherwise)
        try {
            await loginWithUniversalLink(universalLink, false)
        } catch (e:any) {
            const alert = await alertController.create({
                header: 'Fehler',
                message: e?.message || 'Unbekannter Fehler aufgetreten',
                buttons: ['Ok'],
            });

            await alert.present();
        }
    });

    // setup login & logout events
    // emitter.on('auth:token:update', ({newToken, oldToken}) => {
    //     if (newToken && !oldToken) {
    //         emitter.emit('auth:login')
    //     } else if (!newToken && oldToken) {
    //         emitter.emit('auth:logout')
    //     }
    // })

    // attach logout listener to redirect
    // emitter.on('auth:logout', () => {
    //     resetRouterBasedOnAuth()
    // })
}

export function isLoggedIn() {
    const authStore = useAuthStore()
    return authStore.hasToken()
}

function parseUniversalLink(universalLink: string) {
    const url = parseURL(universalLink)
    return url?.search
}

const isValidUniversalLink = (universalLink: string) => isValidURL(universalLink)

async function universalLinkToJWT(universalLink: string, tryFuzzyLinkParsing:boolean) {

    // try to find the link in a bunch of text
    if (tryFuzzyLinkParsing) {
        const start = universalLink.indexOf(import.meta.env.VITE_IBFG_URL)
        universalLink = universalLink.substring(start === -1 ? 0 : start)
        const end = universalLink.indexOf(' ')
        if (end != -1) {
            universalLink = universalLink.substring(0, end)
        }
    }

    if (!isValidUniversalLink(universalLink)) {
        throw new Error("Ungültiger Einladungslink")
    }

    const universalLinkComponents = parseUniversalLink(universalLink)
    if (!universalLinkComponents) {
        throw new Error('Ungültiger Einladungslink')
    }

    // replace universalLink baseURL with backend API
    const baseURL = import.meta.env.VITE_LOGIN_ENDPOINT as string
    const loginURL = baseURL + universalLinkComponents

    const loginResponse = await fetch(loginURL);
    if (!loginResponse.ok) {
        if (loginResponse.status === 410) {
            throw new Error('Der Einladungslink ist bereits abgelaufen.')
        } else {
            throw new Error('Anmeldung fehlgeschlagen: ' + loginResponse.status)
        }
    }

    const loginResponseJson = await loginResponse.json();

    return loginResponseJson.token;
}

export async function loginWithUniversalLink(
    universalLink: string,
    tryFuzzyLinkParsing: boolean
) {

    const token = await universalLinkToJWT(universalLink, tryFuzzyLinkParsing)

    await useAppState().login(token)
}

export const fetchRoles = async () => {
    const authStore = useAuthStore();

    const result = await query(gql`
        query GetMe {
            me {
                roles
                userName
            }
        }
    `);

    const user = result?.data?.me;

    if (!user) {
        return;
    }

    authStore.setRoles(user.roles);
    authStore.setCurrentUserIdentifier(user.userName);
};

export const resetRouterBasedOnAuth = () => {
    const authStore = useAuthStore();

    if (!isLoggedIn()) {
        router.push("/");
    } else if (authStore.isConsultant()) {
        router.push("/employees");
    } else if (authStore.isEmployee()) {
        router.push("/chat");
    } else {
        console.error('unknown auth config - redirecting to /')
        router.push("/");
    }
};
