<script setup lang="ts">
import {PropType, Ref, ref, watch} from "vue";
import Calendar from "@/views/Calendar/Calendar.vue";
import Modal from "@/components/Modal.vue";
import {IonButton} from "@ionic/vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";

const props = defineProps({
    initialDate: {
        type: String as PropType<kapitelDateString>,
        required: false
    },
})

const isOpen = defineModel({type: Boolean})

const calendar : Ref<typeof Calendar|undefined>= ref(undefined)
const initCalendar = () => {calendar.value?.reinit()}

</script>

<template>
    <Modal fullscreen no-toolbar v-model="isOpen" title="Kalender" class="no-padding-left no-padding-right no-padding-top no-padding-bottom" @view:didPresent="initCalendar">
        <Calendar
            ref="calendar"
            :initial-date="initialDate || undefined"
            :defer-init="true"
        ></Calendar>
        <template #actions="{dismiss}">
            <ion-button
                color="secondary"
                expand="block"
                @click="dismiss"
            >
                Zurück
            </ion-button>
        </template>
    </Modal>
</template>
