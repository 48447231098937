import {defineStore} from "pinia";

import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AInesRequest,
    AInesRequestAction,
    AInesRequestReaction,
    AInesRequestReactionInput,
    AInesRequestReactionType
} from "@/graphql/generated/graphql";
import {actionSheetController, alertController} from "@ionic/vue";
import {convertRichResponseToRichContent} from "@/helper/chat/richResponses";
import {PreprocessedAssistantMessage} from "@/helper/chat/chatStore";
import {computed, ComputedRef} from "vue";


export enum AInesRequestAssistantUIState {
    MINIMIZED = 'MINIMIZED', // nur avatar & badge
    COMPACT = 'COMPACT', // kurzbeschreibung der requests
}

export const useAInesRequestStore = defineStore("AInesRequests", {
    state: (): {
        requests: AInesRequest[],
        selection: AInesRequest | undefined,
        uiState: AInesRequestAssistantUIState | undefined,
    } => ({
        requests: [] as AInesRequest[],
        selection: undefined,
        uiState: undefined
    }),
    getters: {
        unhandledRequests: state => state.requests.filter(r => r !== state.selection),
        selectedPreprocessedAssistantMessage: (state) : PreprocessedAssistantMessage | undefined => {
            const requestMessage = state.selection?.message
            if (requestMessage) {
                let richContents = undefined;
                if (requestMessage.richResponses?.responses) {
                    richContents = requestMessage.richResponses.responses.map((richResponse) =>
                        convertRichResponseToRichContent({
                            type: richResponse.type as string,
                            reference: richResponse.reference as string
                        })
                    )
                }

                return {
                    textContent: requestMessage.text || '',
                    richContents: richContents
                }
            }
        }
    },
    actions: {
        async refetch() {
            this.requests = await fetchAInesRequests()
        },
        toggleUIState() {
            this.setUIState(this.uiState !== AInesRequestAssistantUIState.MINIMIZED ? AInesRequestAssistantUIState.MINIMIZED : AInesRequestAssistantUIState.COMPACT)
        },
        setUIState(uiState:AInesRequestAssistantUIState) {
            if (this.selection && uiState !== AInesRequestAssistantUIState.MINIMIZED) {
                //console.info('reset selection')
                // this.selection = undefined
            }
            this.uiState = uiState
        },

        /**
         * request selection
         */
        selectRequest(request: AInesRequest | undefined) {
            this.selection = request
        },
        respondToSelectedRequest(reactionType: AInesRequestReactionType) {
            if (!this.selection) {
                throw new Error('no selection')
            }

            // track response in backend
            const response : AInesRequestReactionInput = {
                requestSubject: this.selection.subject,
                requestScope: this.selection.scope,
                reactionType
            }
            respondToAInesRequest(response, true)

            // remove specific request from list of open requests
            this.requests = this.requests.filter(r => r !== this.selection)

            // reset request selection
            this.selectRequest(undefined)
        }
    },
});

// private - only via store
async function fetchAInesRequests(): Promise<AInesRequest[]> {
    const result = await query(
        gql`query getAInesRequests {
            currentAInesRequests {
                scope
                subject
                subjectLabel
                shortLabel
                featureSet
                message {
                    text
                    richResponses {
                        responses {
                            type
                            reference
                        }
                    }
                }
                suggestions {
                    shortLabel
                    prompt
                    reactionType
                }
                urgent
            }
        }`
    )

    return result.data.currentAInesRequests
}

// private - only via store
async function respondToAInesRequest(response: AInesRequestReactionInput, silent: boolean): Promise<AInesRequestReaction[]> {
    const MUTATION = gql`
        mutation respondToAInesRequest(
            $reactionType: AInesRequestReactionType!,
            $requestScope: String!,
            $requestSubject: AInesRequestSubject!
        ) {
            respondToAInesRequest(
                reactionType: $reactionType,
                requestScope: $requestScope,
                requestSubject: $requestSubject,
            ) {
                reactionType
                requestScope
                requestSubject
            }
        }`

    const result = await mutation(MUTATION, response, silent)

    if (!result?.data?.respondToAInesRequest) {
        throw new Error("error while storing response")
    }

    return result?.data?.respondToAInesRequest
}

export interface ResponseQuickChat {
    label: string,
    type: 'suggestedReaction' | 'cancel' | 'snoozeButtons',
    action?: AInesRequestAction
}

const abortQuickChat : ResponseQuickChat = {label: 'Abbrechen', type: 'cancel'}
const snoozeQuickChat : ResponseQuickChat = {label: 'Später erinnern', type: 'snoozeButtons'}

export const getRequestResponseQuickChats = (request: AInesRequest) =>  {
    const suggestedQuickChats : ResponseQuickChat[] = request.suggestions
        .map(action => ({label: action.shortLabel, type: 'suggestedReaction', action: action}))

    return suggestedQuickChats.concat([abortQuickChat, snoozeQuickChat])
}

export const snoozeQuickChatHandler = async () => {
    const aInesRequestStore = useAInesRequestStore()

    const actionSheet = await actionSheetController.create({
        buttons: [
            {
                text: 'Nochmal erinnern: Später',
                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Later)
            },
            {
                text: `Nochmal erinnern: Nächstes mal`,
                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Dismissrequest)
            },
            {
                text: `Nicht an ${aInesRequestStore.selection?.subjectLabel} erinnern`,
                role: 'destructive',
                handler: async () => {
                    const alert = await alertController.create({
                        header: 'Nie wieder nachfragen',
                        message: `Wirklich keinerlei Nachfragen mehr zu ${aInesRequestStore.selection?.subjectLabel} stellen?`,
                        buttons: [
                            {
                                text: 'Abbrechen',
                                role: 'cancel'
                            },
                            {
                                text: 'Nie wieder',
                                role: 'confirm',
                                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Ignorerequestsubject)
                            }
                        ],
                    });
                    await alert.present();

                    // defer action sheet dismiss (return false) after alert dismiss (only on confirm)
                    alert.onWillDismiss().then((e) => { if (e.role !== 'cancel') { actionSheet.dismiss() }})
                    return false
                }
            }
        ]
    })
    await actionSheet.present();
}

