<script setup>
const props = defineProps({
    warning: false,
    error: false,
    success: false,
    small: false,
    alignTop: false,
    noMargin: false,
    noIconPadding: false
})
</script>

<template>
<div
    class="text-with-icon"
    :class="{'warning': props.warning, 'error': props.error, 'success': props.success, 'small': props.small, 'align-top': props.alignTop, 'no-margin': props.noMargin, 'no-icon-padding': noIconPadding}"
>
    <div
        class="icon"
    >
        <slot name="icons"></slot>
    </div>
    <div
        class="text"
    >
        <slot />
    </div>
</div>
</template>

<style lang="scss">
.text-with-icon {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    width: 100%;
    border-radius: 1rem;
    padding: 0.5em 0;
    color: var(--ion-color-medium);

    > .icon {
        flex: 0 0 5em;
        display: flex;
        align-items: center;
        justify-content: center;

        > .inline-svg,
        > svg {
            width: 40%;
        }

        svg {
            color: var(--ion-color-primary);
            filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.4));
            // weird space. idk where this does come from.
            margin-bottom: -5px;
        }
    }

    > .text {
        font-size: inherit;
        color: inherit;

        h1 {
            color: var(--custom-h1-color);
            font-size: var(--custom-h1-font-size);
            line-height: var(--custom-h1-line-height);
            font-weight: var(--custom-h1-font-weight);
        }

        h2 {
            color: var(--custom-h2-color);
            font-size: var(--custom-h2-font-size);
            line-height: var(--custom-h2-line-height);
            font-weight: var(--custom-h2-font-weight);
        }

        h3 {
            color: var(--custom-h3-color);
            font-size: var(--custom-h3-font-size);
            line-height: var(--custom-h3-line-height);
            font-weight: var(--custom-h3-font-weight);
        }

        p, span, div {
            font-size: inherit;
            color: inherit;
        }
    }

    &.align-top {
        align-items: flex-start;

        > .icon {
            margin-right: 0.75em;
            margin-top: 0.75em;
        }
    }

    &.no-margin {
        margin-bottom: 0;
    }

    &.no-icon-padding {
        > .icon {
            flex: 0 0 2em;
            justify-content: flex-start;
            padding-left: 0.5em;
            margin-right: 0.5em;

            > .inline-svg,
            > svg {
                width: 100%;
            }
        }
    }

    &.small {
        font-size: var(--custom-font-size-small);
    }

    &.warning {
        background-color: rgba(var(--ion-color-warning-rgb), 0.1);
        color: var(--ion-color-warning);

        svg {
            color: var(--ion-color-warning);
            filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-warning-rgb), 0.4));
        }
    }

    &.success {
        background-color: rgba(var(--ion-color-success-rgb), 0.1);
        color: var(--ion-color-success);

        svg {
            color: var(--ion-color-success);
            filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-success-rgb), 0.4));
        }
    }

    &.error {
        background-color: rgba(var(--ion-color-danger-rgb), 0.1);
        color: var(--ion-color-danger);

        svg {
            color: var(--ion-color-danger);
            filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-danger-rgb), 0.4));
        }
    }
}
</style>
