<script setup lang="ts">
import { ref, computed } from "vue";
import { IonAlert } from "@ionic/vue";
import { App, AppInfo } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import {getAppInfo, getVersion} from "@/helper/version";
import {getDevicePlatform} from "@/helper/deviceProfile";

const isOpen = ref(false);
const platform = ref("");

const updateURL = computed(() => {
    return import.meta.env.VITE_IBFG_UNIVERSAL_LINK as string // use universal link as redirect target
});
const buttons = [
    {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
    },
    {
        text: "Update",
        role: "confirm",
        handler: () => {
            Browser.open({ url: updateURL.value });
        },
    },
];

const _getAppInfo = async (): Promise<any> => {
    const platform = getDevicePlatform()
    if (!platform) {
        return {
            platform: 'web',
            version: 'unknown',
            build: 'unknown',
            id: 'unknown',
        }
    }
    const info = await getAppInfo()

    return {
        platform,
        version: info?.version,
        build: info?.build,
        id: info?.id,
    }
};

const validateClientVersion = async () => {
    const appInfo = await _getAppInfo();

    platform.value = appInfo.platform;

    const baseURL = import.meta.env.VITE_VERSION_ENDPOINT as string;
    const versionURL = baseURL + appInfo.id + "/" + appInfo.build  + "/" + appInfo.version;

    const versionResponse = await fetch(versionURL);
    if (!versionResponse.ok) {
        isOpen.value = true;
    }
};

validateClientVersion();
</script>

<template>
    <ion-alert
        :is-open="isOpen"
        header="Update App"
        message="Die Appversion wird nicht mehr unterstützt."
        :buttons="buttons"
    />
</template>
