/* eslint-disable */
import ModuleBase from './TransitionatorModuleBase'

export default class ModuleDisplay extends ModuleBase {
  init () {
    this.options = Object.assign({
      instantEnter: false
    }, this.options)
  }

  prepareEnter (el) {
    if (!this.options.instantEnter) {
      this.initialState.display = getComputedStyle(el).display
      el.style.display = 'none'
    }
  }

  prepareLeave () {}

  applyEnter (el, value) {
    if (this.options.instantEnter) {
      return
    }

    el.style.display = value === 1
      ? this.initialState.display
      : el.style.display
  }

  applyLeave (el, value) {
    el.style.display = value === 1
      ? 'none'
      : el.style.display
  }
}
